input {
    &[type="checkbox"] {
        &:not(.open-popup-checkbox) {
            cursor: pointer;
            display: none;
            height: initial;
            margin-bottom: 0;
            padding: 0;
            width: initial;

            + label {
                cursor: pointer;
                display: inline-block;
                font-weight: $defaultFontWeight;
                line-height: $defaultLineHeight;
                position: relative;

                &::before {
                    -webkit-appearance: none;
                    appearance: none;
                    background-color: $defaultBackgroundColor;
                    border: 1px solid $formInputBorderColor;
                    border-radius: $formInputBorderRadius;
                    content: "";
                    display: inline-block;
                    margin-right: 6px;
                    margin-top: -0.3rem;
                    padding: 8px;
                    position: relative;
                    vertical-align: middle;
                }
            }

            &:checked {
                + label {
                    color: $brandColor;

                    &::after {
                        border: solid $inverseTextColor;
                        border-width: 0 2px 2px 0;
                        content: "";
                        display: block;
                        height: 12px;
                        left: 6px;
                        margin-top: -0.2rem;
                        position: absolute;
                        top: 4px;
                        transform: rotate(45deg);
                        width: 6px;
                    }

                    &::before {
                        background-color: $brandColor;
                        border-color: $brandColor;
                    }

                    &.green {
                        &::before {
                            background-color: $yesColor;
                            border-color: $yesColor;
                        }
                    }
                }
            }

            &:disabled {
                + label {
                    cursor: auto;

                    &::before {
                        opacity: 0.4;
                    }
                }
            }
        }
    }

    &.green {
        &[type="checkbox"] {
            &:not(.open-popup-checkbox) {
                &:checked {
                    + label {
                        color: $darkTextColor;

                        &::before {
                            background-color: $yesColor;
                            border-color: $yesColor;
                        }
                    }
                }
            }
        }
    }
}
