@use "sass:math";

.container {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 $containerPadding;
    position: relative;
    width: 100%;

    &::after {
        @include utility-clearfix;
    }

    &--tight {
        max-width: $desktopBreakpoint;
    }
}

.container-content-body {
    margin-bottom: $smallScreenMargin;

    @include desktop {
        margin-bottom: $largeScreenMargin;
    }
}

// mixin to use for grid wrapper element
@mixin flex-grid {
    display: flex;
    flex-wrap: wrap;
}

@mixin flex($flex-basis: auto, $flex-grow: 1, $flex-shrink: 1) {
    flex-basis: $flex-basis;
    flex-grow: $flex-grow;
    flex-shrink: $flex-shrink;
}

// returns width of single box in grid
@mixin grid-box-width($boxes-per-row, $margin: 2%) {
    $grid-box-width: math.div(100% - (($boxes-per-row - 1) * ($margin)), $boxes-per-row);
}

@function grid-box-width($boxes-per-row, $margin: 2%) {
    @return math.div(100% - (($boxes-per-row - 1) * ($margin)), $boxes-per-row);
}

// calculates grid for boxes by parent child
@mixin boxes-per-row($boxes-per-row, $margin: $gridMargin) {
    margin-left: $margin;
    width: grid-box-width($boxes-per-row, $margin);

    &:nth-child(#{$boxes-per-row}n+1) {
        margin-left: 0;
    }
}

// calculates grid for boxes by element type
@mixin boxes-per-row-by-type($boxes-per-row, $margin: $gridMargin) {
    margin-left: $margin;
    width: grid-box-width($boxes-per-row, $margin);

    &:nth-of-type(#{$boxes-per-row}n+1) {
        margin-left: 0;
    }
}

// body type boxes per row
@mixin body-boxes-per-row($boxes-per-row) {
    @include boxes-per-row($boxes-per-row, 0);

    &:nth-child(#{$boxes-per-row}n + 1) {
        border-left: 1px solid $borderLightColor;
    }

    &:nth-child(-n + #{$boxes-per-row}) {
        border-top: 1px solid $borderLightColor;
    }
}

// For devices larger than 400px
@include tablet-desktop {
    .container {
        padding: 0;
        width: 90%;
    }
}

.grid-flex-left-sidebar {
    display: flex;
    min-height: 100vh;

    @media (min-width: 1920px) {
        max-width: 1920px;
    }
}

.grid-flex {
    margin-bottom: $defaultMargin;

    &.listing {
        margin-bottom: $smallScreenMargin;
    }
}

@include desktop {
    .container {
        &--tight {
            max-width: $desktopBreakpoint;
        }
    }

    .grid-flex {
        display: flex;

        .content-main {
            width: 68%;
        }

        &.listing {
            margin-bottom: $largeScreenMargin;
        }

        .content-aside-left {
            margin-right: 2%;
            position: relative;
            width: 30%;
        }

        .content-main-listing {
            margin-left: 1%;
            width: 76%;
        }

        .content-aside-listing {
            min-width: 270px;
            position: relative;
            width: 23%;
        }
    }

    .grid-flex-left-sidebar {
        flex-direction: row;

        .content-main {
            margin-left: 2%;
            margin-right: 2%;
            width: 68%;
        }

        .content-aside {
            min-width: 330px;
            width: 30%;
        }
    }
}
