ul {
    list-style: circle inside;
}

ol {
    list-style: decimal inside;
}

ol,
ul {
    line-height: 1.7;
    margin-top: 0;
    padding-left: 0;
}

ul,
ol {
    ul,
    ol {
        margin: 1.5rem 0 1.5rem 3rem;
    }
}

li {
    margin-bottom: 1rem;
}
