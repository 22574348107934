.subscription-banner-small-container {
    margin-bottom: $defaultMargin;
    overflow: hidden;
    padding: $smallScreenMargin $defaultMargin * 0.5;
    position: relative;

    picture {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        img {
            object-position: center 65%;
        }
    }

    &::before {
        background:
        linear-gradient(0deg, $bannerBackgroundColor, $bannerBackgroundColor 0%, transparent 50%, transparent 100%);
        bottom: 0;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 2;
    }

    .banner-promo-text {
        align-items: center;
        display: flex;
        flex-direction: column;

        span {
            background-color: $brandColor;
            color: $inverseTextColor;
            display: block;
            font-size: $h1LargeScreenFontSize;
            font-weight: $boldFontWeight;
            padding: $defaultMargin * 0.125 $defaultMargin * 0.75;
            text-transform: uppercase;
            width: fit-content;

            &:first-child {
                transform: rotate(-5deg) translateX(-10px) translateY(-5px);
            }

            &:nth-child(2) {
                transform: rotate(3deg);
            }
        }
    }

    .banner-container-column {
        position: relative;
        z-index: 3;

        &:first-child {
            min-height: 140px;
            text-align: center;
        }
    }
}

@include tablet-desktop {
    .subscription-banner-small-container {
        border-radius: $largeBorderRadius;
        margin-top: $defaultMargin;
        padding: $defaultMargin $smallScreenMargin;

        &::before {
            background: linear-gradient(
                270deg,
                $bannerBackgroundColor,
                $bannerBackgroundColor 0%,
                transparent 50%,
                transparent 100%
            );
        }
    }
}

@include desktop {
    .subscription-banner-small-container {
        .banner-promo-text {
            align-items: flex-start;
            transform: translateY(25px);

            span {
                font-size: $h1LargeScreenFontSize;

                &:first-child {
                    transform: rotate(-5deg) translateX(-10px) translateY(-5px);
                }

                &:nth-child(2) {
                    transform: rotate(3deg);
                }
            }
        }
    }
}

@media (min-width: 1220px) {
    .subscription-banner-small-container {
        .banner-container-columns {
            display: flex;

            .banner-container-column {
                @include boxes-per-row(2, 2%);

                &:first-child {
                    text-align: left;
                }

                &:nth-child(2) {
                    align-items: center;
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }
}
