.alert {
    border: 1px solid transparent;
    border-radius: $defaultBorderRadius;
    margin: $defaultMargin * 0.5 0;
    padding: $defaultMargin * 0.5;

    &.alert-warning {
        background-color: $alertWarningBackgroundColor;
        border-color: $alertWarningBorder;
        color: $alertWarningColor;
    }

    &.alert-danger {
        background-color: $alertDangerBackgroundColor;
        border-color: $alertDangerBorder;
        color: $alertDangerColor;
    }

    &.alert-info {
        background-color: $alertInfoBackgroundColor;
        border-color: $alertInfoBorder;
        color: $alertInfoColor;
    }

    &.alert-success {
        background-color: $alertSuccessBackgroundColor;
        border-color: $alertSuccessBorder;
        color: $alertSuccessColor;
    }

    .list-unstyled {
        list-style: none;
        margin: 0;

        li {
            margin: 0;
        }
    }
}
