@mixin mobile {
    @media (max-width: $tabletBreakpoint - 1) {
        @content;
    }
}

@mixin mobile-tablet {
    @media (max-width: $desktopBreakpoint - 1) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: $tabletBreakpoint) and (max-width: $desktopBreakpoint - 1) {
        @content;
    }
}

@mixin tablet-desktop {
    @media (min-width: $tabletBreakpoint) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktopBreakpoint) {
        @content;
    }
}

@mixin desktop-large {
    @media (min-width: $desktopLargeBreakpoint) {
        @content;
    }
}

body {
    &::after {
        content: "small-screen";
        display: none;
    }
}

@include desktop {
    body {
        &::after {
            content: "medium-screen";
        }
    }
}

@include desktop-large {
    body {
        &::after {
            content: "large-screen";
        }
    }
}
