picture {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    &.fallback {
        background-color: $accentedBackgroundColor;
        background-image: url("/assets/icon/img-placeholder.svg");
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

img {
    &.fallback {
        height: auto;
        max-width: 80%;
    }

    &.img-inner {
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        object-position: center center;
        width: 100%;
        @include own-transition(transform);
    }
}
