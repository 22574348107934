@font-face {
    font-display: swap;
    font-family: DMSans;
    font-style: normal;
    font-weight: 400;
    src:
    local("DM-Sans-Regular"),
    local("DM Sans Regular"),
    url("/assets/font/dmsans-regular.woff2") format("woff2"),
    url("/assets/font/dmsans-regular.woff") format("woff"),
    url("/assets/font/dmsans-regular.ttf") format("truetype");
}

@font-face {
    font-display: swap;
    font-family: DMSans;
    font-style: normal;
    font-weight: 500;
    src:
    local("DM-Sans-Medium"),
    local("DM Sans Medium"),
    url("/assets/font/dmsans-medium.woff2") format("woff2"),
    url("/assets/font/dmsans-medium.woff") format("woff"),
    url("/assets/font/dmsans-medium.ttf") format("truetype");
}

@font-face {
    font-display: swap;
    font-family: DMSans;
    font-style: normal;
    font-weight: 700;
    src:
    local("DM-Sans"),
    local("DM Sans"),
    local("DM-Sans-Bold"),
    local("DM Sans Bold"),
    url("/assets/font/dmsans-bold.woff2") format("woff2"),
    url("/assets/font/dmsans-bold.woff") format("woff"),
    url("/assets/font/dmsans-bold.ttf") format("truetype");
}

@font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    src:
    local("Poppins-SemiBold"),
    local("Poppins SemiBold"),
    url("/assets/font/poppins-semibold.woff2") format("woff2"),
    url("/assets/font/poppins-semibold.woff") format("woff"),
    url("/assets/font/poppins-semibold.ttf") format("truetype");
}
