.top-makes-list-container {
    margin-block: $smallScreenMargin;

    .buttons-container {
        margin-top: $smallScreenMargin;
        text-align: center;
    }

    .section-title {
        @include bigger-default-headline-font-size;
    }

    @include desktop {
        margin: $largeScreenMargin auto;
        max-width: $desktopBreakpoint;
    }
}

.subscription-make-filter {
    display: grid;
    grid-gap: $defaultMargin * 0.25;
    grid-template-columns: repeat(4, 1fr);
    margin: $defaultMargin * 0.5 0;

    .filter {
        align-items: center;
        border-radius: $defaultBorderRadius;
        color: $defaultTextColor;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $defaultMargin * 0.5;
        @include hover-box-border(1px);

        &.filter-active {
            border-color: $brandColor;
            color: $brandColor;
        }

        &.filter-disabled {
            color: $lightTextColor;
            cursor: default;
            opacity: 0.5;
            pointer-events: none;
        }

        .filter-label {
            font-size: $smallScreenFontSize;
            margin-top: $defaultMargin * 0.25;
            text-align: center;
        }
    }
}

@include desktop-large {
    .subscription-make-filter {
        grid-template-columns: repeat(8, 1fr);
    }
}
