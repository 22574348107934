.driveto-news-container {
    margin-bottom: $smallScreenMargin;

    .driveto-news-title {
        color: $headlineTextColor;
        font-size: $h3SmallScreenFontSize;
        margin-bottom: $defaultMargin * 0.5;
        text-transform: uppercase;
    }

    .driveto-news-slider-container {
        .driveto-news-content {
            align-items: center;
            background-color: $accentedBackgroundColor;
            border-radius: $defaultBorderRadius;
            color: $darkTextColor;
            display: flex;
            flex: none;
            flex-direction: row-reverse;
            height: 100%;
            justify-content: space-between;
            padding: $defaultMargin * 0.5 $defaultMargin * 0.75 $defaultMargin * 0.75;

            .image {
                border-radius: 50%;
                height: 70px;
                image-rendering: -webkit-optimize-contrast;
                width: 70px;
            }

            .title {
                line-height: $defaultLineHeight;
            }

            .subtitle {
                color: $brandColor;
                font-size: $h3SmallScreenFontSize;
                line-height: $defaultLineHeight;
                margin-bottom: $defaultMargin * 0.25;
            }

            .content {
                margin-right: $defaultMargin * 0.5;

                .button-light {
                    display: block;
                    margin-top: $defaultMargin * 0.25;
                    width: fit-content;
                }
            }
        }
    }
}

@include tablet-desktop {
    .driveto-news-container {
        .driveto-news-slider-container {
            .driveto-news-content {
                padding: $defaultMargin * 0.5 $defaultMargin;
            }
        }
    }
}

@include desktop {
    .driveto-news-container {
        margin: 0 auto $largeScreenMargin;
        max-width: $desktopBreakpoint;

        .embla {
            .embla-container {
                &.driveto-news-slider-container {
                    box-sizing: border-box;
                    display: block;

                    .driveto-news-content {
                        border-radius: 0;
                        flex-direction: row;
                        justify-content: flex-start;
                        margin-bottom: 3px;

                        &:first-of-type {
                            border-radius: $defaultBorderRadius $defaultBorderRadius 0 0;
                        }

                        &:last-of-type {
                            border-radius: 0 0 $defaultBorderRadius $defaultBorderRadius;
                        }

                        &:only-of-type {
                            border-radius: $defaultBorderRadius;
                        }

                        .content {
                            margin-left: $defaultMargin;

                            .subtitle {
                                margin-bottom: 0;
                            }

                            .title {
                                font-size: $slightlyBiggerFontSizePx;
                            }

                            .button-light {
                                display: inline-block;
                                margin-left: $defaultMargin * 0.5;
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
