.subscription-landing-page-how-it-works {
    margin-bottom: $smallScreenMargin;

    .section-title {
        align-items: flex-end;
        background: linear-gradient(180deg, $brandColor 320px, $defaultBackgroundColor 0);
        background-position: 50% 0;
        color: $inverseTextColor;
        display: flex;
        height: 320px;
        padding: 0 $defaultMargin * 0.5 $defaultMargin;
        @include bigger-default-headline-font-size;

        &::before {
            background-image: url("/assets/img/subscription/how-it-works-mobile.png");
            background-position: 50% -48px;
            background-repeat: no-repeat;
            content: "";
            display: inline-block;
            height: 350px;
            left: 0;
            position: absolute;
            right: 0;
            top: -48px;
            width: 100%;
            z-index: 1;
        }
    }

    .container-content {
        margin-top: $defaultMargin;

        .green {
            color: $yesColor;
            font-weight: $mediumFontWeight;
        }
    }

    .content {
        background-color: linear-gradient(to right, transparent 10%, $defaultBackgroundColor, transparent 10%);
        margin: 0 auto;
        max-width: 630px;
    }

    .button-container {
        text-align: center;
    }

    .list {
        counter-reset: boxes-counter;
        list-style: none;

        .item {
            align-items: center;
            color: $darkTextColor;
            display: flex;
            font-size: $biggerFontSize;
            font-weight: $boldFontWeight;
            line-height: $defaultLineHeight;
            margin-bottom: $defaultMargin * 0.75;
            padding-bottom: $defaultMargin * 0.75;

            &:not(:last-of-type) {
                border-bottom: 1px solid $borderLightColor;
            }

            &::before {
                color: $lighterTextColor;
                content: counter(boxes-counter) "";
                counter-increment: boxes-counter;
                margin-right: $defaultMargin;
                @include headline-font-family;
                @include big-headline-font-size;
            }
        }
    }

    .buttons-container {
        text-align: center;

        .button {
            &:first-of-type {
                margin-right: $defaultMargin * 0.25;
            }
        }
    }
}

.webp {
    .subscription-landing-page-how-it-works {
        .section-title {
            &::before {
                background-image: url("/assets/img/subscription/how-it-works-mobile.webp");
            }
        }
    }
}


@include mobile {
    .subscription-landing-page-how-it-works {
        margin-top: $largeScreenMargin;
    }
}

@include tablet-desktop {
    .subscription-landing-page-how-it-works {
        .section-title {
            background: $defaultBackgroundColor;
            border-radius: $largeBorderRadius;
            color: $headlineTextColor;
            display: block;
            height: auto;
            padding-bottom: 0;
            text-align: center;

            &::before {
                display: none;
            }
        }
    }
}

@include desktop-large {
    .subscription-landing-page-how-it-works {
        margin-bottom: $largeScreenMargin;

        .container-content {
            background-image:
            url("/assets/img/subscription/how-it-works-left.png"),
            url("/assets/img/subscription/how-it-works-right.png");
            background-position: 0 10%, 100% 90%;
            background-repeat: no-repeat, no-repeat;
        }
    }

    .webp {
        .subscription-landing-page-how-it-works {
            .container-content {
                background-image:
                url("/assets/img/subscription/how-it-works-left.webp"),
                url("/assets/img/subscription/how-it-works-right.webp");
            }
        }
    }
}
