@use "sass:math";

.newsletter-subscription-container {
    background-color: $accentedBackgroundColor;
    margin-top: $smallScreenMargin;

    &:not(.discount) {
        border-radius: $largeBorderRadius;
        display: block;
        padding: $defaultMargin;
        text-align: center;
        width: 100%;

        &:not(.active) {
            pointer-events: none;
            position: relative;

            &::before {
                background-color: $defaultBackgroundColor;
                bottom: 0;
                content: "";
                display: block;
                left: 0;
                opacity: 0.5;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
            }
        }

        .icon {
            align-items: center;
            background-color: $newsletterSubscriptionIconBackgroundColor;
            border-radius: 50%;
            display: flex;
            flex: none;
            height: 130px;
            justify-content: center;
            margin: 0 auto $defaultMargin * 0.5;
            position: relative;
            width: 130px;

            &::before {
                background-color: transparent;
                background-image: url("/assets/icon/newsletter-paper-plane-icon.svg");
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: 100%;
                content: " ";
                display: inline-block;
                height: 4rem;
                position: absolute;
                right: -$defaultMargin * 0.25;
                top: $defaultMargin;
                width: 4rem;
            }
        }

        &.subscription-listing {
            margin-bottom: $smallScreenMargin;
            margin-top: 0;
        }

        .form {
            .form-group {
                margin-bottom: 0;
                position: relative;

                &:nth-child(1) {
                    width: 100%;
                }

                .button-primary {
                    width: 100%;
                }
            }

            .send-button-label {
                color: $offersListPriceColor;
                margin-bottom: $defaultMargin * 0.25;
                margin-top: $defaultMargin;
                min-width: 245px;
                text-align: center;

                &::before {
                    background-color: transparent;
                    background-image: url("/assets/icon/check-ok.svg");
                    background-position: 50%;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    content: " ";
                    display: inline-block;
                    height: 1.8rem;
                    margin-right: math.div($defaultMargin, 6);
                    margin-top: -0.3rem;
                    vertical-align: middle;
                    width: 1.8rem;
                }
            }
        }

        input {
            padding: $defaultMargin * 0.5;
        }

        .response-icon {
            display: block;
            margin-right: $defaultMargin * 0.25;
            width: 50px;

            .circle {
                animation: dash 1s ease-in-out;
                stroke-dasharray: 1000;
                stroke-dashoffset: 0;
            }

            .symbol {
                animation: dash-check 1s 0.5s ease-in-out forwards;
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
            }
        }
    }

    &.discount {
        border-radius: $defaultBorderRadius;
        bottom: $defaultMargin * 0.5;
        display: none;
        left: $defaultMargin * 0.5;
        max-width: 420px;
        padding: $defaultMargin;
        position: fixed;
        right: $defaultMargin * 0.5;
        text-align: center;
        z-index: $popupZIndex;
        @include hover-box-border(1px, $accentedBackgroundColor);
        @include own-box-shadow(0, 10px);

        .close-popup {
            cursor: pointer;
            position: absolute;
            right: $defaultMargin * 0.5;
            top: $defaultMargin * 0.5;

            .close-icon {
                height: auto;
                width: 2.5rem;
            }
        }

        .form {
            .form-group {
                margin-bottom: 0;
                position: relative;

                label {
                    line-height: $defaultLineHeight;
                    margin-bottom: $defaultMargin * 0.5;
                }

                .button-primary {
                    margin-top: $defaultMargin * 0.5;
                }
            }
        }

        input {
            padding: $defaultMargin * 0.5;
        }

        .headline {
            padding: 0 $defaultMargin;
        }

        .response-icon {
            display: block;
            margin: 0 auto $defaultMargin * 0.25;
            width: 50px;

            .circle {
                animation: dash 1s ease-in-out;
                stroke-dasharray: 1000;
                stroke-dashoffset: 0;
            }

            .symbol {
                animation: dash-check 1s 0.5s ease-in-out forwards;
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
            }
        }
    }

    &.complete {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .error-message {
        color: $noColor;
        margin-top: $defaultMargin * 0.5;

        &::before {
            background: url("/assets/icon/cross-no.svg") 0 50% no-repeat transparent;
            background-size: 100%;
            content: " ";
            display: inline-block;
            height: 2rem;
            margin-right: $defaultMargin * 0.25;
            margin-top: -0.3rem;
            text-decoration: none;
            vertical-align: middle;
            width: 2rem;
        }
    }
}

@include tablet-desktop {
    .newsletter-subscription-container {
        &.discount {
            bottom: $defaultMargin;
            left: auto;
            right: $defaultMargin;
        }
    }
}

@include desktop {
    .newsletter-subscription-container {
        margin-top: $largeScreenMargin;

        &:not(.discount) {
            align-items: center;
            border-radius: $largeBorderRadius $largeBorderRadius $largeBorderRadius * 3;
            display: flex;
            padding: $largeScreenMargin;
            text-align: left;

            .icon {
                margin-bottom: 0;
            }

            .content {
                margin-left: $defaultMargin * 1.5;
                width: 100%;
            }

            .form {
                align-items: flex-end;
                display: flex;

                .send-button-label {
                    margin-top: 0;
                }

                .form-group {
                    &:nth-child(2) {
                        margin-left: $defaultMargin * 0.5;
                    }
                }
            }

            .response-icon {
                display: inline-block;
                margin-bottom: 0;
                margin-right: $defaultMargin * 0.5;
                width: 60px;
            }

            &.subscription-listing {
                margin-bottom: $largeScreenMargin;
                margin-top: 0;
            }
        }
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }

    100% {
        stroke-dashoffset: 900;
    }
}
