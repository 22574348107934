@use "sass:math";

.labels {
    left: $defaultMargin * 0.5;
    position: absolute;
    top: $defaultMargin * 0.5;
    z-index: 2;

    .label {
        background-color: $labelBackgroundColor;
        border: 1px solid $borderLightColor;
        border-radius: $buttonBorderRadius * 0.5;
        clear: left;
        color: $defaultLabelTextColor;
        display: inline-block;
        float: left;
        font-size: $smallScreenFontSize;
        font-weight: $defaultFontWeight;
        line-height: 1.2;
        margin-bottom: $defaultMargin * 0.125;
        padding: $defaultMargin * 0.125 $defaultMargin * 0.25;
        white-space: nowrap;

        &--winter-tires {
            align-items: center;
            color: $brandColor;
            display: flex;

            &::before {
                background-image: url("/assets/icon/tyre.svg");
                background-repeat: no-repeat;
                background-size: 100%;
                content: " ";
                display: inline-block;
                height: 13px;
                margin-right: $defaultMargin * 0.125;
                width: 13px;
            }
        }

        &--on-stock {
            background-color: $greenLabelBackgroundColor;
            border-color: $greenLabelBackgroundColor;
            color: $inverseLabelTextColor;
        }

        &--available-sooner {
            background-color: $yellowLabelBackgroundColor;
            border-color: $yellowLabelBackgroundColor;
            color: $defaultTextColor;
        }
    }
}

.extra-labels {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: $defaultMargin * 0.5;

    .label {
        align-items: center;
        background-color: $greenLabelBackgroundColor;
        border: 1px solid $greenLabelBackgroundColor;
        border-radius: $buttonBorderRadius * 0.5;
        color: $inverseLabelTextColor;
        display: flex;
        font-size: $smallScreenFontSize;
        line-height: 1.2;
        margin-bottom: $defaultMargin * 0.25;
        margin-right: 0;
        padding: $defaultMargin * 0.125 $defaultMargin * 0.25;
        white-space: nowrap;

        &::before {
            background-image: url("/assets/icon/badge.svg");
            background-repeat: no-repeat;
            background-size: 100%;
            content: " ";
            display: inline-block;
            height: 13px;
            margin-right: $defaultMargin * 0.125;
            width: 13px;
        }
    }

    &--row {
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: $defaultMargin * 0.25;

        .label {
            margin-right: $defaultMargin * 0.125;
        }
    }
}
