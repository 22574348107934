.tooltip {
    &.tooltip-active {
        cursor: pointer;
        position: relative;

        &::after {
            background: url("/assets/icon/question-mark-gray.svg") 50% 50% no-repeat;
            background-size: 100%;
            content: " ";
            display: inline-block;
            height: 1.6rem;
            margin-top: -0.3rem;
            vertical-align: middle;
            width: 1.6rem;
        }

        &::before {
            background-image: url("/assets/icon/close.svg");
            background-position: right $defaultMargin * 0.5 top $defaultMargin * 0.5;
            background-repeat: no-repeat;
            background-size: 2rem;
            bottom: 0;
            color: $darkTextColor;
            content: attr(data-title);
            cursor: pointer;
            display: block;
            font-weight: $defaultFontWeight;
            left: 0;
            line-height: $defaultLineHeight;
            padding: $defaultMargin * 2 $defaultMargin;
            position: fixed;
            text-decoration: none;
            text-transform: none;
            transform: translateY(100%);
            white-space: pre-wrap;
            width: 100%;
            z-index: $modalZIndex;
            @include own-box-shadow(0, 10px);
            @include box-border(1px);
            @include own-transition(transform);
        }

        &.tooltip-open {
            &::before {
                transform: translateY(0);
            }
        }
    }
}

@include tablet-desktop {
    .tooltip {
        &.tooltip-active {
            &::before {
                border-radius: $defaultBorderRadius;
                bottom: auto;
                display: none;
                left: 50%;
                max-width: 400px;
                min-width: 200px;
                padding: $defaultMargin * 1.5 $defaultMargin;
                position: absolute;
                right: auto;
                top: $defaultMargin;
                transform: translateX(-50%) translateY(0);
                transition: none;
                width: max-content;
            }

            &.tooltip-open {
                &::before {
                    display: block;
                    transform: translateX(-50%);
                }

                &.right {
                    &::before {
                        left: auto;
                        right: 0;
                        transform: translateX(15%);
                    }
                }

                &.left {
                    &::before {
                        left: 0;
                        right: auto;
                        transform: translateX(-85%);
                    }
                }
            }
        }
    }
}

@include mobile {
    body {
        &.tooltip-open {
            position: relative;

            &::before {
                background-color: $videoBackgroundHoverColor;
                bottom: 0;
                content: "";
                display: block;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                z-index: $mainMenuZIndex;
            }

            #main-menu {
                &::before {
                    background-color: $videoBackgroundHoverColor;
                    bottom: 0;
                    content: "";
                    display: block;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: $mainMenuZIndex;
                }
            }
        }
    }
}

@include desktop {
    .tooltip {
        &.tooltip-active {
            &::before {
                z-index: $headerZIndex - 2;
            }
        }
    }
}
