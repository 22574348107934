@use "sass:math";

#header {
    background-color: $defaultBackgroundColor;
    border-bottom: 1px solid $borderLightColor;
    position: relative;
    z-index: $headerZIndex;

    #menubar {
        align-items: center;
        display: flex;
        justify-content: space-between;
        min-height: $mainMenuMinHeight;
    }

    .customer-menu-back-link {
        border-right: 2px dashed $borderLightColor;
        color: $brandColor;
        display: inline-block;
        flex: none;
        margin-left: $defaultMargin * 2;
        padding: $defaultMargin $defaultMargin * 0.5;
        text-decoration: underline;
    }

    .menu-toggle {
        background-color: $brandColor;
        border-radius: $defaultBorderRadius;
        cursor: pointer;
        display: block;
        line-height: $defaultLineHeight;
        margin-left: auto;
        padding: math.div($defaultMargin, 2.4) math.div($defaultMargin, 3);
        text-transform: uppercase;

        .bar {
            background-color: $defaultBackgroundColor;
            display: block;
            height: 0.3rem;
            margin-bottom: 0.5rem;
            position: relative;
            top: 0;
            width: 2.5rem;
            @include own-transition(transform);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .open-popup-checkbox {
        &:checked + .menu-toggle {
            .bar {
                &:nth-child(2) {
                    opacity: 0;
                }

                &:first-child {
                    top: 0.8rem;
                    transform: rotate(45deg);
                }

                &:last-child {
                    top: -0.8rem;
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .logo {
        display: block;
        flex: none;
        text-decoration: none;
        width: $smallScreenLogoWidth;

        &.large-screen {
            display: none;
        }

        .logo-image {
            height: auto;
            max-width: $smallScreenLogoWidth;
            width: 100%;
        }
    }

    .customer-profile-label {
        background-color: $darkBackgroundColor;
        border-radius: $defaultMargin;
        color: $inverseTextColor;
        font-size: $smallFontSize;
        font-weight: $boldFontWeight;
        letter-spacing: 2px;
        line-height: 1;
        margin-top: math.div($defaultMargin, 6);
        padding: math.div($defaultMargin, 12) $defaultMargin * 0.5;
        text-align: center;
        text-transform: uppercase;
    }

    .compare-offers-link,
    .favorite-offers-link {
        line-height: 1;
        position: relative;

        .items-count {
            align-items: center;
            background-color: $brandColor;
            border: 2px solid $defaultBackgroundColor;
            border-radius: 50%;
            color: $inverseTextColor;
            content: "";
            display: flex;
            font-size: 1rem;
            font-weight: $boldFontWeight;
            height: 2rem;
            justify-content: center;
            line-height: 1;
            position: absolute;
            right: -$defaultMargin * 0.5;
            top: -$defaultMargin * 0.25;
            width: 2rem;
            z-index: 5;
        }

        .tooltip-label {
            color: $defaultTextColor;
            margin-bottom: $defaultMargin * 0.5;
            margin-right: $defaultMargin * 0.25;
        }

        .compare-link,
        .favorite-link {
            display: inline-block;
            position: relative;
            text-decoration: none;

            &.hidden {
                display: none;
            }

            &::after {
                border-radius: 50%;
                content: " ";
                display: inline-block;
                height: 3rem;
                margin-top: -0.3rem;
                vertical-align: middle;
                width: 3rem;
            }
        }
    }

    .favorite-offers-link {
        margin-right: $defaultMargin * 0.25;

        .favorite-link {
            &::after {
                background: url("/assets/icon/favorite-add.svg") 50% 50% no-repeat $defaultBackgroundColor;
                background-size: 65%;
            }
        }
    }

    .compare-offers-link {
        .compare-link {
            &::after {
                background: url("/assets/icon/compare-add.svg") 50% 50% no-repeat $defaultBackgroundColor;
                background-size: 65%;
            }
        }
    }
}

#contacts-menu-container {
    display: none;

    .contacts-menu {
        align-items: center;
        display: flex;
        margin-left: auto;
    }

    .email-contact,
    .phone-contact {
        margin-right: $defaultMargin * 0.5;

        a {
            font-size: $smallScreenFontSize;
            text-decoration: none;
        }
    }

    .phone-contact {
        .link {
            color: $defaultTextColor;

            &::before {
                background: url("/assets/icon/headset.svg") 50% 50% no-repeat transparent;
                content: " ";
                display: inline-block;
                height: 2rem;
                vertical-align: middle;
                width: 2rem;
            }
        }
    }

    .email-contact {
        .link {
            color: $defaultTextColor;

            &::before {
                background: url("/assets/icon/envelope.svg") 50% 50% no-repeat transparent;
                content: " ";
                display: inline-block;
                height: 2.3rem;
                vertical-align: middle;
                width: 2.3rem;
            }
        }
    }
}

#main-menu {
    bottom: 0;
    display: none;
    left: 0;
    padding-bottom: $defaultMargin;
    position: fixed;
    right: 0;
    top: 65px;
    z-index: $mainMenuZIndex;

    .homepage-icon {
        display: none;

        img {
            filter: brightness(0%);
            @include own-transition(filter);
        }
    }

    .homepage-text {
        display: inline-block;
    }

    .main-menu {
        background-color: $defaultBackgroundColor;
        clear: both;
        list-style: none;
        text-align: left;
        @include own-box-shadow(2px, 5px, 0.1);

        & > li {
            display: block;
            margin: 0;

            & > a {
                color: $mobileMenuLinksColor;
                display: block;
                line-height: $mainMenuLineHeight;
                padding: $defaultMargin * 0.5 $defaultMargin;
                text-decoration: none;
            }

            &.current-menu-item {
                > a {
                    color: $linkColor;

                    .homepage-icon {
                        img {
                            filter: none;
                        }
                    }
                }
            }

            &:first-of-type {
                &:hover {
                    .homepage-icon {
                        img {
                            filter: none;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: ($mainMenuBreakpoint - 1)) {
    #header {
        .main-menu-container {
            align-items: center;
            display: flex;
        }

        .customer-menu-back-link {
            &.large-screen {
                display: none;
            }

            &.small-screen {
                border-right: 0;
                display: inline-block;
                margin-left: $defaultMargin * 0.5;
            }
        }

        #main-menu {
            background-color: $mobileMenuBackgroundColor;
            text-align: center;
            transition: visibility, opacity $defaultTransitionTime;

            .user-menu-container {
                padding: $defaultMargin * 0.5 $defaultMargin;
            }
        }

        .menu-toggle {
            &.open {
                border-right: 1px solid $borderLightColor;
                padding: math.div($defaultMargin, 3);
            }
        }

        #open-menu {
            &:checked ~ #main-menu {
                display: block;
                overflow: auto;
            }
        }

        .compare-offers-link,
        .favorite-offers-link {
            margin: $defaultMargin * 0.25 0;
        }
    }
}

@include tablet-desktop {
    #main-menu {
        .main-menu {
            & > li {
                & > a {
                    padding: $defaultMargin * 0.5 $defaultMargin * 2;
                }
            }
        }
    }
}

@media (min-width: $mainMenuBreakpoint) {
    #header {
        background: linear-gradient(to bottom, $accentedBackgroundColor 0%, $accentedBackgroundColor 50%,
            $defaultBackgroundColor 50%, $defaultBackgroundColor 100%);

        .customer-menu-back-link {
            padding: $defaultMargin;

            &.small-screen {
                display: none;
            }
        }

        .logo {
            &.small-screen {
                display: none;
            }

            &.large-screen {
                display: block;
            }

            .logo-image {
                max-width: $largeScreenLogoWidth;
            }
        }

        .menu-toggle {
            display: none;
        }

        .compare-offers-link,
        .favorite-offers-link {
            .tooltip,
            .compare-link,
            .favorite-link {
                background-color: $defaultBackgroundColor;
                border-radius: $defaultBorderRadius * 1.5;
                height: 40px;
                line-height: $defaultLineHeight;
                padding: 8px 5px;
                width: 40px;
            }

            .tooltip-label {
                display: none;
            }

            .items-count {
                right: -6px;
                top: -4px;
            }
        }

        .compare-offers-link {
            border-left: 0;
        }
    }

    #main-menu {
        align-items: center;
        border: 0;
        display: flex;
        flex-wrap: wrap;
        min-height: $mainMenuMinHeight;
        padding: 0;
        position: relative;
        top: 0;
        width: 100%;
        z-index: 500;

        .break {
            flex-basis: 100%;
            height: 0;
            order: 3;
            width: 100%;
        }

        #contacts-menu-container {
            align-items: center;
            display: flex;
            flex: 1;
            min-height: 71px;
            order: 1;
        }

        .logo {
            order: 4;
        }

        .homepage-icon {
            display: inline-block;
            height: 2rem;
            width: 2rem;
        }

        .homepage-text {
            display: none;
        }

        .customer-menu-back-link {
            order: 5;
        }

        #price-switch-container {
            margin-left: auto;
            min-width: 225px;
            order: 7;
        }

        .user-menu-container {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-left: auto;
            order: 2;
        }

        .main-menu {
            align-items: flex-end;
            box-shadow: none;
            display: flex;
            flex: 1;
            margin: 0;
            min-width: 400px;
            order: 6;
            padding-left: $defaultMargin;

            & > li {
                position: relative;
                white-space: nowrap;

                & > a {
                    display: inline-block;
                    margin-bottom: 0;
                    padding: $defaultMargin $defaultMargin * 0.5;
                    text-decoration: none;
                    transition: color $defaultTransitionTime ease, background $defaultTransitionTime ease;

                    &:hover {
                        color: $brandColor;
                    }
                }

                &.current-menu-item {
                    & > a {
                        position: relative;

                        &::after {
                            background-color: $brandColor;
                            border-radius: 2px;
                            bottom: -2px;
                            content: "";
                            display: inline-block;
                            height: 3px;
                            left: $defaultMargin * 0.5;
                            position: absolute;
                            right: $defaultMargin * 0.5;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1180px) {
    #main-menu {
        .main-menu {
            padding-left: $defaultMargin * 2;

            & > li {
                position: relative;
                white-space: nowrap;

                & > a {
                    padding: $defaultMargin;
                }

                &.current-menu-item {
                    & > a {
                        &::after {
                            left: $defaultMargin;
                            right: $defaultMargin;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $smallestDeviceBreakpoint) {
    #header {
        .customer-menu-back-link {
            &::before {
                background: url("/assets/icon/how-it-works-right-arrow.svg") 0 50% transparent no-repeat;
                background-size: 100%;
                content: " ";
                display: inline-block;
                height: 1.4rem;
                margin-right: $defaultMargin * 0.25;
                margin-top: -0.3rem;
                transform: rotate(180deg);
                vertical-align: middle;
                width: 1.4rem;
            }
        }
    }
}
