@use "sass:math";

@mixin input {
    background-color: $formInputBackgroundColor;
    border: 1px solid $formInputBorderColor;
    border-radius: $formInputBorderRadius * 2;
    color: $formTextColor;
    font-weight: $mediumFontWeight;
    line-height: $defaultLineHeight;
    margin: 0;
    padding: math.div($defaultMargin, 3) $defaultMargin * 0.5;
    width: 100%;
    @include own-transition(border-color);

    &:disabled {
        background-color: $accentedBackgroundColor;
        pointer-events: none;
    }

    &:active,
    &:focus {
        border-color: $formInputFocusBorderColor;
        outline: none;
    }

    &::placeholder {
        color: $formPlaceholderTextColor;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px $formInputBackgroundColor inset;
        -webkit-text-fill-color: $formTextColor;
    }

    &.error {
        border: 1px solid $formRequiredFieldColor;
    }

    ~ .error {
        color: $formRequiredFieldColor;
        font-size: $smallFontSize;
        line-height: $defaultLineHeight;
        padding-top: $defaultMargin * 0.25;
    }
}
