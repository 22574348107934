:root {
    @include root-prop(--slide-spacing, $defaultMargin * 0.5);
    @include root-prop(--slide-gap, 2%);
    @include root-prop(--slide-small-screen-size, 95%);
    @include root-prop(--slide-middle-screen-size, 49%);
    @include root-prop(--slide-large-screen-size, 23.5%);
}

.embla {
    position: relative;

    .embla-viewport {
        overflow: hidden;

        &:not(&.embla-active) {
            .embla-container {
                .embla-slide {
                    overflow: visible;
                }
            }
        }

        &.embla-active {
            .embla-container {
                .embla-slide {
                    overflow: hidden;
                }
            }
        }
    }

    .embla-container {
        backface-visibility: hidden;
        display: flex;
        gap: var(--slide-gap);
        touch-action: pan-y;

        .embla-slide {
            flex: 0 0 var(--slide-small-screen-size);
            overflow: hidden;
            position: relative;
        }
    }

    .embla-buttons {
        .embla-button {
            cursor: pointer;
            display: inline-block;

            &.disabled {
                opacity: 0.5;
            }

            &::after {
                background: url("/assets/icon/right-arrow-orange.svg") 50% 50% no-repeat $defaultBackgroundColor;
                background-size: 35%;
                border: 2px solid $borderLightColor;
                border-radius: $defaultBorderRadius * 1.5;
                content: " ";
                display: inline-block;
                height: 4rem;
                width: 4rem;
                @include own-transition(transform);
            }

            &.embla-button-prev {
                &::after {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .embla-dots {
        margin: $defaultMargin * 0.5 auto 0;
        min-height: 21px;
        overflow: hidden;
        text-align: center;
        white-space: nowrap;
        width: 80px;

        &:empty {
            display: none;
        }

        .embla-dot {
            background-color: $accentedBackgroundColor;
            border-radius: 50%;
            cursor: pointer;
            display: inline-block;
            height: 10px;
            margin: 0 $defaultMargin * 0.125;
            position: relative;
            width: 10px;
            @include own-transition(left);

            &.embla-dot-selected {
                background-color: $brandColor;
            }
        }
    }
}

@include mobile {
    .embla {
        .embla-container {
            .embla-slide {
                flex: 0 0 var(--slide-small-screen-size);
            }
        }
    }
}

@include tablet {
    .embla {
        .embla-container {
            .embla-slide {
                flex: 0 0 var(--slide-middle-screen-size);
            }
        }
    }
}

@include desktop {
    .embla {
        .embla-container {
            .embla-slide {
                flex: 0 0 var(--slide-large-screen-size);
            }
        }
    }
}
