a {
    background-color: transparent;
    color: $linkColor;
    outline: none;
    transition: color $defaultTransitionTime * 0.5 ease;

    &:hover {
        color: $linkHoverColor;
    }

    &.inverse {
        color: $inverseTextColor;

        &:hover {
            color: $lighterTextColor;
        }
    }
}
