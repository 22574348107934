@use "sass:math";

form {
    .help-block {
        background-color: $alertDangerBackgroundColor;
        border-color: $alertDangerBorder;
        border-radius: $defaultBorderRadius;
        color: $alertDangerColor;
        display: block;
        font-size: $smallFontSize;
        line-height: $defaultLineHeight;
        margin: math.div($defaultMargin, 3) 0;
        padding: $defaultMargin * 0.25 $defaultMargin * 0.5;

        .list-unstyled {
            list-style: none;
            margin: 0;

            li {
                margin: 0;
            }
        }
    }
}
