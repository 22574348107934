@use "sass:math";

input,
textarea,
select,
button {
    font-family: inherit;
    font-size: $defaultFontSize;

    @include mobile-tablet {
        // prevent iOS form element zooming
        // https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
        font-size: 16.1px;
    }
}

button {
    @include button;
}

input {
    &[type="submit"],
    &[type="reset"],
    &[type="button"] {
        @include button;
    }

    &[type="email"],
    &[type="tel"],
    &[type="text"],
    &[type="number"],
    &[type="password"] {
        @include input;
    }
}

select,
textarea {
    @include input;
}

select {
    @include select;
}

label {
    display: block;
    font-weight: $mediumFontWeight;
    line-height: 2;

    &.required {
        &::after {
            color: $formRequiredFieldColor;
            content: "*";
            display: inline-block;
            padding-left: 0.2rem;
        }
    }

    &.help-title {
        &::after {
            background-color: $formHelpSignColor;
            border-radius: 50%;
            color: $inverseTextColor;
            content: "?";
            cursor: help;
            display: inline-block;
            font-size: $smallFontSize;
            font-weight: $boldFontWeight;
            line-height: 1.25;
            margin-left: 0.3rem;
            padding: 0 0.4rem;
        }
    }
}

.form-row,
.form-group {
    margin-bottom: math.div($defaultMargin, 3);
}

.form-row--alternative {
    display: none;
}

.form-submit-row,
.form-agreement {
    text-align: center;
}

.form-flex-row {
    @include flex-grid;
}

.form-submit-row {
    .button-primary {
        margin-bottom: $defaultMargin * 0.5;
    }
}

.form-half {
    width: 100%;

    @include mobile {
        &:first-child {
            margin-bottom: $defaultMargin * 0.5;
        }
    }

    @include tablet-desktop {
        @include boxes-per-row(2);

        margin-bottom: 0;
    }
}
