.subscription-contacts-container {
    background-color: $accentedBackgroundColor;
    margin-top: $smallScreenMargin;
    padding: $smallScreenMargin $defaultMargin;
    position: relative;

    .response-message {
        font-size: 2rem;
        font-weight: $mediumFontWeight;
        margin: $smallScreenMargin 0;
        text-align: center;

        &.success {
            color: $yesColor;
        }

        &.error {
            color: $noColor;
        }
    }

    .section-title {
        text-align: center;
        @include bigger-default-headline-font-size;
    }

    .content {
        text-align: center;
    }

    .row {
        &.opening-hours {
            font-weight: $mediumFontWeight;
            margin-bottom: $defaultMargin;
        }

        &.contacts {
            margin-bottom: $defaultMargin;

            .button-light {
                &:first-of-type {
                    margin-right: $defaultMargin * 0.5;
                }
            }
        }

        &.form-container {
            margin-top: $defaultMargin;

            .form-flex-row {
                align-items: flex-start;
                justify-content: center;
                margin: 0 auto $defaultMargin;
                width: fit-content;
            }

            .form-row {
                &.button-row {
                    text-align: center;
                }
            }

            .inner-addon {
                display: flex;
                flex-direction: column;
                position: relative;

                &.left-addon {
                    input {
                        border-radius: $largeBorderRadius 0 0 $largeBorderRadius;
                        letter-spacing: 1px;
                        padding: $defaultMargin * 0.5 $defaultMargin * 0.5 $defaultMargin * 0.5 7.5rem;
                        width: 150px;
                    }
                }

                .phone-prefix {
                    align-items: center;
                    border-right: 1px solid $formInputBorderColor;
                    color: $formPlaceholderTextColor;
                    display: flex;
                    font-weight: $boldFontWeight;
                    height: 44px;
                    padding: 0 $defaultMargin * 0.5;
                    position: absolute;
                    top: 1px;
                }
            }

            .button-primary {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                min-width: 232px;
                padding: 9.5px $defaultMargin;

                &::before {
                    background: url("/assets/icon/callback.svg") 0 50% no-repeat transparent;
                    background-size: 100%;
                    content: " ";
                    display: inline-block;
                    height: 2rem;
                    margin-right: $defaultMargin * 0.25;
                    margin-top: -0.3rem;
                    vertical-align: middle;
                    width: 2rem;
                }
            }
        }
    }

    #main-menu-phone-button {
        .button-primary {
            &::before {
                background: url("/assets/icon/callback.svg") 0 50% no-repeat transparent;
                background-size: 100%;
                content: " ";
                display: inline-block;
                height: 2rem;
                margin-right: $defaultMargin * 0.25;
                margin-top: -0.3rem;
                vertical-align: middle;
                width: 2rem;
            }
        }
    }

    #main-menu-phone-form {
        display: none;
    }
}

@include tablet-desktop {
    .subscription-contacts-container {
        border-radius: $largeBorderRadius * 3 $largeBorderRadius $largeBorderRadius;
        margin-bottom: $smallScreenMargin;
    }
}

@include desktop {
    .subscription-contacts-container {
        margin-bottom: $largeScreenMargin;
        margin-top: $largeScreenMargin;
        padding: $smallScreenMargin $largeScreenMargin;

        #main-menu-phone-form {
            display: block;
        }

        #main-menu-phone-button {
            display: none;
        }

        .row {
            &.form-container {
                .inner-addon {
                    &.left-addon {
                        input {
                            width: 270px;
                        }
                    }
                }
            }
        }
    }
}
