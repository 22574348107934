@use "sass:math";

.header-link-only {
    margin-top: $defaultMargin * 0.5;

    & > a {
        display: none;
        font-size: $defaultFontSize;
    }
}

ol {
    &.breadcrumb {
        color: $defaultTextColor;
        font-size: $smallFontSize;
        list-style: none;
        margin-bottom: 0;
        overflow: auto;
        padding-bottom: $defaultMargin * 0.5;
        position: relative;
        white-space: nowrap;

        li {
            display: inline-block;
            margin-bottom: 0;

            .separator {
                display: inline-block;
                padding: 0 math.div($defaultMargin, 6);
            }

            a {
                color: $defaultTextColor;
            }

            &:last-child {
                a {
                    pointer-events: none;
                    text-decoration: none;
                }
            }

            @media (max-width: 470px) {
                &:not(:last-child) {
                    a {
                        display: inline-block;
                        max-width: 120px;
                        vertical-align: bottom;
                        @include text-overflow-ellipsis;
                    }
                }
            }
        }
    }
}

.header-breadcrumbs-only {
    padding-bottom: $defaultMargin * 0.5;
    padding-top: $defaultMargin * 0.5;
}

@include desktop {
    .header-link-only {
        & > a {
            display: block;
        }
    }

    ol {
        &.breadcrumb {
            padding-bottom: 0;
        }
    }
}
