@use "sass:math";

@mixin button-border($borderColor: $buttonBorderColor, $borderWidth: 2px) {
    border: $borderWidth solid $borderColor;
}

@mixin button-with-right-arrow($icon: white-color) {
    align-items: center;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    width: fit-content;

    &::after {
        background: 50% 50% no-repeat transparent;
        background-size: 1.3rem auto;

        @if ($icon == white-color) {
            background-image: url("/assets/icon/right-arrow-white.svg");
        }
        @if ($icon == orange-color) {
            background-image: url("/assets/icon/right-arrow-orange.svg");
        }
        @if ($icon == gray-color) {
            background-image: url("/assets/icon/right-arrow-gray.svg");
        }
        @if ($icon == green-color) {
            background-image: url("/assets/icon/right-arrow-green.svg");
        }

        content: " ";
        display: inline-block;
        height: 1.3rem;
        margin-left: $defaultMargin * 0.5;
        width: 1.3rem;
    }
}

@mixin button-background($backgroundColor, $backgroundHoverColor, $textColor, $textHoverColor) {
    background: $backgroundColor;
    color: $textColor;

    &:active,
    &:hover,
    &:focus {
        background-color: $backgroundHoverColor;
        border-color: $backgroundHoverColor;
        color: $textHoverColor;
    }
}

@mixin button {
    border-radius: $buttonBorderRadius;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-size: $buttonFonSize;
    font-weight: $mediumFontWeight;
    line-height: $defaultLineHeight;
    padding: $defaultMargin * 0.25 $defaultMargin * 0.75;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition:
    color $defaultTransitionTime ease,
    border $defaultTransitionTime ease,
    box-shadow $defaultTransitionTime ease,
    background $defaultTransitionTime ease,
    transform $defaultTransitionTime ease;
    white-space: nowrap;
    @include button-border($borderLightColor);
    @include button-background(
        $buttonColor,
        $buttonPrimaryColor,
        $buttonTextColor,
        $buttonHoverTextColor
    );
    @include own-box-shadow(2px, 1px, 0.15);

    @include tablet-desktop {
        padding: math.div($defaultMargin, 3) $defaultMargin;
    }

    &:active,
    &:hover,
    &:focus {
        outline: 0;
        @include own-box-shadow(5px, 1px, 0.15);
    }

    &:not(.mfp-close) {
        &:active {
            transform: scale(0.98);
        }
    }

    .icon {
        fill: $brandColor;
        height: 20px;
        margin-right: $defaultMargin * 0.25;
        width: 20px;
    }

    &:active,
    &:hover,
    &:focus {
        .icon {
            filter: brightness(0) invert(1);
        }
    }

    &.button-primary {
        @include button-background(
            $buttonPrimaryColor,
            $buttonPrimaryHoverColor,
            $buttonPrimaryTextColor,
            $buttonPrimaryHoverTextColor
        );
        @include button-border;
    }

    &.button-big {
        padding: $defaultMargin * 0.5;
        width: 100%;

        @include tablet-desktop {
            border-radius: $buttonBorderRadius * 1.5;
            font-size: $buttonBigFonSize;
            padding: $defaultMargin * 0.5 $defaultMargin * 2;
            width: auto;
        }
    }

    &.button-disabled {
        opacity: 0.8;
        pointer-events: none;
    }

    &.header-button {
        font-size: $smallFontSize;
        @include button-background(
            $defaultBackgroundColor,
            $accentedBackgroundColor,
            $defaultTextColor,
            $darkTextColor
        );
        @include button-border($borderLightColor, 1px);
    }
}

.button {
    @include button;
}

.facebook-button {
    @include button-background(
        $facebookColor,
        $facebookHoverColor,
        $buttonHoverTextColor,
        $buttonHoverTextColor
    );
    @include button-border($facebookColor);
}

.button-light {
    border-bottom: 1px solid $brandColor;
    box-shadow: none;
    color: $linkColor;
    cursor: pointer;
    display: inline-block;
    font-size: $defaultFontSize;
    font-weight: $mediumFontWeight;
    line-height: $defaultLineHeight;
    text-decoration: none;
    transition:
    color $defaultTransitionTime, border-bottom-color $defaultTransitionTime, padding $defaultTransitionTime;

    &:focus,
    &:hover {
        border-color: $brandDarkerColor;
        color: $brandDarkerColor;
    }

    @include desktop {
        font-size: $buttonFonSize;
    }
}
