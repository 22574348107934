html {
    &.displayWithoutVat {
        .withVat {
            display: none;
        }
    }

    &.displayWithVat {
        .withoutVat {
            display: none;
        }
    }
}
