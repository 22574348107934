@use "sass:math";

@mixin listing-car-color {
    .color {
        border: 1px solid $borderLightColor;
        border-radius: 50%;
        display: inline-block;
        flex: none;
        height: 25px;
        position: relative;
        width: 25px;
        @include car-basic-colors;

        &:not(:first-of-type) {
            margin-left: -6px;
        }
    }
}

#listing-item-offers-list {
    scroll-margin-top: $defaultMargin * 0.5;

    & + .listing-boxes-list-container {
        margin-bottom: math.div(-$defaultMargin, 3);
    }
}

.listing-item-offers-list {
    &.linear-listing {
        .listing-item-offer-box {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: $defaultMargin;
            width: 100%;

            &:hover {
                .img-inner {
                    transform: scale(1.05);
                    transform-origin: center;
                }
            }

            &:not(:last-of-type) {
                border-bottom: 1px solid $borderLightColor;
                margin-bottom: $defaultMargin;
            }

            .offer-mileage {
                color: $darkTextColor;

                .value {
                    font-weight: $mediumFontWeight;
                }

                &::before {
                    margin-top: -4px;
                    vertical-align: middle;
                }
            }

            .offer-box-info {
                display: flex;
                flex: 1;
                flex-direction: column;
                margin-top: $defaultMargin * 0.5;

                .offer-box-specifications-box {
                    color: $darkTextColor;
                    margin-top: $defaultMargin * 0.5;
                    @include flex-grid;

                    .specification {
                        &:not(:last-of-type) {
                            border-right: 1px solid $borderLightColor;
                            margin-bottom: $defaultMargin * 0.5;
                            margin-right: math.div($defaultMargin, 3);
                            padding-right: math.div($defaultMargin, 3);
                        }

                        .label {
                            display: block;
                            font-size: $smallScreenFontSize;
                        }

                        .value {
                            font-weight: $mediumFontWeight;
                        }
                    }
                }

                .top-equipment-labels {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: math.div($defaultMargin, 3);

                    .label {
                        background-color: $topLabelBackgroundColor;
                        border-radius: $defaultMargin;
                        color: $topLabelTextColor;
                        font-size: $smallScreenFontSize;
                        line-height: 1.2;
                        margin-bottom: $defaultMargin * 0.125;
                        margin-right: $defaultMargin * 0.125;
                        padding: $defaultMargin * 0.125 $defaultMargin * 0.25;

                        &.hidden {
                            display: none;
                        }

                        &.show {
                            background-color: $defaultBackgroundColor;
                            cursor: pointer;
                            text-decoration: underline;

                            &::before {
                                display: none;
                            }
                        }
                    }
                }

                .colors {
                    align-items: center;
                    display: flex;
                    @include listing-car-color;

                    .label {
                        color: $darkTextColor;
                        margin-right: math.div($defaultMargin, 3);
                    }
                }

                .offer-box-car-title {
                    font-size: $h3LargeScreenFontSize;
                    margin-bottom: $defaultMargin * 0.25;

                    > a {
                        color: $headlineTextColor;
                        text-decoration: none;
                    }
                }

                .trim-list-container {
                    color: $darkTextColor;
                    margin-top: math.div($defaultMargin, 3);

                    .trim {
                        font-weight: $mediumFontWeight;

                        &:not(:last-of-type) {
                            &::after {
                                content: "\00B7";
                                display: inline-block;
                                padding: 0 math.div($defaultMargin, 6);
                            }
                        }
                    }
                }
            }

            .offer-box-price-box {
                align-items: center;
                border-radius: $defaultBorderRadius;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: $defaultMargin;
                margin-top: $defaultMargin * 0.5;
                min-width: 200px;
                overflow: visible;
                padding: $defaultMargin * 0.5;
                @include hover-box-border(1px);

                .button-primary {
                    min-width: 200px;
                }

                .price-box {
                    line-height: 1.2;
                    text-align: center;
                    text-decoration: none;

                    .price {
                        color: $offersListPriceColor;
                        font-size: $priceFromFontSize;
                        font-weight: $boldFontWeight;
                        line-height: 1.15;
                        white-space: nowrap;
                    }

                    .price-from-label {
                        font-size: $slightlyBiggerFontSizePx;
                    }

                    .price-note {
                        clear: left;
                        color: $accentedTextColor;
                        display: block;
                        font-size: $priceNoteFontSize;
                        font-weight: $defaultFontWeight;
                        margin-bottom: $defaultMargin * 0.25;
                        text-align: center;
                    }
                }
            }

            .offer-box-image-content {
                display: flex;
                flex: none;
                flex-flow: column wrap;
                justify-content: flex-start;
                min-width: 295px;
                overflow: hidden;
                position: relative;
                width: 100%;

                .offer-box-image {
                    border-radius: $defaultBorderRadius;
                    display: block;
                    height: 200px;
                    overflow: hidden;
                    @include hover-box-border(1px);

                    .favorite-offer-label {
                        z-index: 2;
                    }
                }
            }
        }
    }

    &:not(.linear-listing) {
        @include flex-grid;

        .listing-item-offer-box {
            align-items: stretch;
            border: 1px solid $borderLightColor;
            border-radius: $largeBorderRadius;
            flex-direction: column;
            margin-bottom: $defaultMargin;
            overflow: hidden;
            position: relative;
            text-decoration: none;
            transition: border-color $defaultTransitionTime ease, box-shadow $defaultTransitionTime ease;
            width: 100%;
            @include flex-grid;
            @include own-box-shadow(5px, 10px);
            @include own-transition(transform);

            &:hover {
                border-color: $borderHoverColor;
                @include own-box-shadow(5px, 15px, 0.15);

                .img-inner {
                    transform: scale(1.05);
                    transform-origin: center;
                }
            }

            .listing-item-offer-box-link {
                bottom: 0;
                display: block;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
            }

            .offer-mileage {
                color: $darkTextColor;
                font-size: $smallScreenFontSize;
                margin-right: $defaultMargin * 0.5;

                &:first-of-type {
                    margin-bottom: $defaultMargin * 0.125;
                }

                .value {
                    display: block;
                    font-size: $defaultFontSize;
                    font-weight: $mediumFontWeight;
                }
            }

            .offer-box-image-content {
                border-top-left-radius: $largeBorderRadius;
                border-top-right-radius: $largeBorderRadius;
                display: flex;
                flex-flow: column wrap;
                justify-content: flex-end;
                overflow: hidden;
                position: relative;
                width: 100%;
            }

            .offer-box-image {
                border-top-left-radius: $largeBorderRadius;
                border-top-right-radius: $largeBorderRadius;
                display: block;
                height: 200px;
                overflow: hidden;

                .favorite-offer-label {
                    z-index: 2;
                }
            }

            .offer-box-footer {
                background-color: $defaultBackgroundColor;
                border-bottom-left-radius: $largeBorderRadius;
                border-bottom-right-radius: $largeBorderRadius;
                border-top: 1px solid $borderLightColor;
                display: flex;
                flex-direction: column;
                padding: $defaultMargin * 0.5 $defaultMargin math.div($defaultMargin, 3);
                position: relative;
                @include flex;

                .colors {
                    align-items: center;
                    border-top: 1px dashed $borderLightColor;
                    display: flex;
                    font-size: $smallScreenFontSize;
                    justify-content: center;
                    margin-top: math.div($defaultMargin, 2.4);
                    padding-top: $defaultMargin * 0.25;
                    width: 100%;
                    @include listing-car-color;

                    .color {
                        height: 20px;
                        width: 20px;
                    }

                    .label {
                        color: $defaultTextColor;
                        margin-right: math.div($defaultMargin, 3);
                    }
                }

                .price-container {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                }

                .top-equipment-labels {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: $defaultMargin * 0.5;
                    width: 100%;

                    .label {
                        align-items: center;
                        background-color: $topLabelBackgroundColor;
                        border-radius: $defaultMargin;
                        color: $topLabelTextColor;
                        display: flex;
                        font-size: 1rem;
                        font-weight: $mediumFontWeight;
                        margin-bottom: math.div($defaultMargin, 6);
                        margin-right: math.div($defaultMargin, 12);
                        padding: math.div($defaultMargin, 12)
                            math.div($defaultMargin, 3)
                                math.div($defaultMargin, 12)
                                    $defaultMargin * 0.25;

                        &.hidden {
                            display: none;
                        }

                        &.show {
                            background-color: $defaultBackgroundColor;
                            cursor: pointer;
                            text-decoration: underline;

                            &::before {
                                display: none;
                            }
                        }
                    }
                }

                .button-primary {
                    display: none;
                    margin-bottom: $defaultMargin * 0.25;
                    margin-top: $defaultMargin * 0.5;
                    width: 100%;
                }

                .offer-box-price-box {
                    align-items: flex-start;
                    border-top: 1px solid $borderLightColor;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: flex-end;
                    padding-top: $defaultMargin * 0.5;
                    @include flex;
                }

                .price-box {
                    flex: none;
                    line-height: 1.2;
                    text-align: right;
                    text-decoration: none;

                    .price {
                        color: $offersListPriceColor;
                        font-size: $h2SmallScreenFontSize;
                        font-weight: $boldFontWeight;
                        line-height: 1.15;
                        white-space: nowrap;
                    }

                    .price-from-label {
                        font-size: $slightlyBiggerFontSizePx;
                    }

                    .price-note {
                        clear: left;
                        color: $accentedTextColor;
                        font-size: $priceNoteFontSize;
                        font-weight: $defaultFontWeight;
                    }
                }

                .offer-box-specifications-box {
                    color: $darkTextColor;
                    font-size: $smallScreenFontSize;
                    margin-bottom: $defaultMargin * 0.5;
                    @include flex-grid;

                    .specification {
                        &:not(:last-of-type) {
                            &::after {
                                content: "\2022";
                                display: inline-block;
                                padding: 0 math.div($defaultMargin, 6);
                            }
                        }
                    }
                }

                .not-available {
                    color: $darkTextColor;
                    font-weight: $boldFontWeight;
                    text-align: center;
                    width: 100%;
                }

                .offer-box-car-title {
                    font-size: $offersListTitleFontSize;

                    > a {
                        color: $headlineTextColor;
                        text-decoration: none;
                    }
                }

                .car-offer-target-lessee {
                    border-top: 1px dashed $borderLightColor;
                    color: $darkTextColor;
                    font-size: $smallFontSize;
                    margin-top: $defaultMargin * 0.25;
                    padding-top: $defaultMargin * 0.25;
                    text-align: center;
                }
            }

            .dislike-offer {
                color: $brandColor;
                cursor: pointer;
                padding-bottom: $defaultMargin * 0.5;
                padding-top: $defaultMargin * 0.5;
                text-align: center;
                text-decoration: underline;
                vertical-align: middle;

                &::before {
                    background: url("/assets/icon/cross-no.svg") 50% 50% no-repeat transparent;
                    background-size: 100%;
                    content: " ";
                    display: inline-block;
                    height: 1.5rem;
                    margin-right: math.div($defaultMargin, 6);
                    text-decoration: none;
                    width: 1.5rem;
                }
            }
        }
    }
}

@include tablet {
    .listing-item-offers-list {
        &:not(.linear-listing) {
            .listing-item-offer-box {
                @include boxes-per-row-by-type(2);
            }
        }
    }
}

@include desktop {
    #listing-item-offers-list {
        & + .listing-boxes-list-container {
            margin-top: $defaultMargin;
        }
    }

    .listing-item-offers-list {
        &:not(.linear-listing) {
            .listing-item-offer-box {
                @include boxes-per-row-by-type(3);

                .listing-item-offer-box-link {
                    display: none;
                }

                .offer-box-footer {
                    .price-box {
                        .price {
                            font-size: $priceFromFontSize;
                        }
                    }

                    .button-primary {
                        display: inline-block;
                    }
                }
            }
        }

        &.linear-listing {
            .listing-item-offer-box {
                &:not(:last-of-type) {
                    margin-bottom: $smallScreenMargin;
                    padding-bottom: $smallScreenMargin;
                }
            }
        }
    }
}

@media (min-width: 520px) and (max-width: 1199px) {
    .listing-item-offers-list {
        &.linear-listing {
            .listing-item-offer-box {
                .offer-box-image-content {
                    .offer-box-image {
                        height: 300px;
                    }
                }
            }
        }
    }
}

@include desktop-large {
    .listing-item-offers-list {
        &:not(.linear-listing) {
            .listing-item-offer-box {
                .offer-box-image-content {
                    .offer-box-image {
                        height: 250px;
                    }
                }
            }
        }

        &.linear-listing {
            .listing-item-offer-box {
                .offer-box-price-box {
                    margin-top: 0;
                }

                .offer-box-info {
                    margin-top: 0;
                }

                .offer-box-image-content {
                    margin-right: 2%;
                    width: 30%;
                }
            }
        }
    }
}
