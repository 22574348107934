#price-switch-container {
    padding: $defaultMargin 0;

    .price-switch-label {
        align-items: center;
        color: $defaultTextColor;
        cursor: pointer;
        display: flex;
        font-weight: $defaultFontWeight;
        justify-content: center;
        padding: math.div($defaultMargin, 8) 0;
    }

    .switch {
        cursor: pointer;
        display: inline-block;
        height: 30px;
        margin-right: $defaultMargin * 0.25;
        position: relative;
        width: 55px;

        .slider {
            background-color: $borderHoverColor;
            border-radius: 30px;
            bottom: 0;
            cursor: pointer;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: $defaultTransitionTime;

            &::before {
                background-color: $defaultBackgroundColor;
                border-radius: 50%;
                bottom: 3px;
                content: "";
                height: 24px;
                left: 5px;
                position: absolute;
                transition: $defaultTransitionTime;
                width: 24px;
            }

            &::after {
                background: url("/assets/icon/check-ok-orange.svg") 0 50% no-repeat transparent;
                background-size: 100%;
                bottom: 3px;
                content: "";
                filter: grayscale(1);
                height: 24px;
                left: 5px;
                opacity: 0.3;
                position: absolute;
                transition: $defaultTransitionTime;
                width: 24px;
            }
        }

        input {
            display: none;

            &:checked {
                & + .slider {
                    background-color: $brandColor;

                    &::before {
                        transform: translateX(22px);
                    }

                    &::after {
                        filter: none;
                        opacity: 1;
                        transform: translateX(22px);
                    }
                }
            }
        }
    }
}

@media (min-width: $mainMenuBreakpoint) {
    #price-switch-container {
        flex: none;
        padding: 0;

        .switch {
            .slider {
                background-color: $accentedBackgroundColor;
            }
        }
    }
}
