.subscription-landing-page-references {
    margin-bottom: $smallScreenMargin;

    &.authentication {
        margin-bottom: $defaultMargin;
        margin-top: $smallScreenMargin;
    }

    .title {
        color: $headlineTextColor;
        font-size: $h3SmallScreenFontSize;
        margin-bottom: $defaultMargin;
        text-transform: uppercase;
    }

    .reference-box {
        align-items: center;
        border-radius: $largeBorderRadius;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: $defaultMargin;
        text-align: center;
        text-decoration: none;
        @include hover-box-border(1px);
        @include own-transition(box-shadow);

        .text {
            color: $defaultTextColor;
        }

        &:hover {
            @include own-box-shadow(5px, 15px);

            .logo {
                scale: 1.05;
            }
        }

        .button-light {
            margin-top: $defaultMargin * 0.5;
        }

        .logo {
            height: auto;
            margin-bottom: $defaultMargin;
            max-height: 35px;
            max-width: 230px;
            width: auto;
            @include own-transition(scale);
        }
    }
}

@include desktop {
    .subscription-landing-page-references {
        margin-bottom: $largeScreenMargin;

        &.authentication {
            margin-top: $largeScreenMargin;
        }

        .embla {
            .embla-container {
                flex-wrap: wrap;

                .embla-slide {
                    flex: 0 0 32%;
                    margin-bottom: $defaultMargin;
                }
            }
        }

        .reference-box {
            margin-bottom: $defaultMargin;
        }

        .section-title {
            text-align: center;
        }
    }
}
