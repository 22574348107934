@use "sass:math";

#login-info-box {
    margin: $defaultMargin 0;
    text-align: center;

    .login-link {
        box-shadow: none;
        display: inline-block;
        margin-top: $defaultMargin * 0.5;
        max-width: 370px;
        text-decoration: none;
        width: 100%;

        &::before {
            background-color: transparent;
            background-image: url("/assets/icon/user-white.svg");
            background-position: 50% 50%;
            background-repeat: no-repeat;
            content: " ";
            display: inline-block;
            height: 2rem;
            margin-right: $defaultMargin * 0.25;
            vertical-align: middle;
            width: 2rem;
        }

        .user-login-label {
            line-height: 2;
        }
    }

    > .user-menu-dropdown {
        height: 100%;
        margin: 0 auto;
        max-width: 600px;
        position: relative;

        #user-menu {
            display: none;
        }

        #user-menu-dropdown-label-container {
            display: none;

            .logged-in-label {
                display: none;

                &::before {
                    background-color: transparent;
                    background-image: url("/assets/icon/user-white.svg");
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    background-size: 80%;
                    content: " ";
                    display: inline-block;
                    height: 2rem;
                    margin-right: $defaultMargin * 0.25;
                    margin-top: -0.3rem;
                    vertical-align: middle;
                    width: 2rem;
                }
            }
        }

        .user-menu {
            border-radius: $defaultBorderRadius;
            display: block;
            left: 0;
            list-style: none;
            margin-bottom: 0;
            margin-top: $defaultMargin * 0.5;
            min-width: 270px;
            padding: $defaultMargin * 0.5 $defaultMargin * 0.5 math.div($defaultMargin, 6);
            position: relative;
            right: -1px;
            text-align: left;
            top: 100%;
            z-index: $headerZIndex + 1;
            @include box-border(1px);

            .title {
                color: $headlineTextColor;
                font-size: $slightlyBiggerFontSizePx;
                font-weight: $mediumFontWeight;
                margin-bottom: math.div($defaultMargin, 3);
            }

            .username-info {
                color: $darkTextColor;

                .username {
                    display: block;
                    margin-bottom: math.div($defaultMargin, 3);
                    text-align: center;
                    white-space: nowrap;
                }

                .button-primary {
                    display: block;
                }
            }

            .logout {
                border-top: 1px solid $borderLightColor;
                margin-top: $defaultMargin * 0.5;
                padding-top: $defaultMargin * 0.5;

                .button {
                    width: 100%;
                }
            }

            .deal-list {
                margin-top: $defaultMargin;
            }
        }

        input {
            &[type="checkbox"] {
                + #user-menu-dropdown-label-container {
                    &::after,
                    &::before {
                        display: none;
                    }
                }

                &:checked {
                    ~ .user-menu {
                        display: block;
                        margin-top: 10px;
                        @include fade-in-from-none;
                    }

                    + #user-menu-dropdown-label-container {
                        color: $defaultTextColor;
                    }
                }
            }
        }
    }
}

@media (min-width: $mainMenuBreakpoint) {
    #login-info-box {
        background-color: $brandColor;
        border-radius: $defaultBorderRadius * 1.5;
        margin-bottom: 0;
        margin-left: $defaultMargin * 0.5;
        margin-top: 0;

        #user-menu-dropdown-label-container {
            cursor: pointer;
        }

        .login-link {
            background: none;
            border: 0;
            color: $brandColor;
            font-size: $defaultFontSize;
            font-weight: $boldFontWeight;
            letter-spacing: 0;
            margin-top: 0;
            min-height: 40px;
            text-transform: none;
            vertical-align: middle;

            .large-screen-hidden {
                display: none;
            }

            &::before {
                margin-right: 0;
            }
        }

        > .user-menu-dropdown {
            .user-menu {
                display: none;
                left: auto;
                margin-top: 0;
                padding: $defaultMargin $defaultMargin $defaultMargin * 0.5;
                position: absolute;
                @include own-box-shadow(5px, 10px);

                .username-info {
                    align-items: center;
                    display: flex;

                    .username {
                        margin-bottom: 0;
                        width: 100%;
                    }

                    .button-primary {
                        margin-left: $defaultMargin * 0.5;
                        margin-top: 0;
                    }
                }
            }

            #user-menu-dropdown-label-container {
                display: block;

                .logged-in-label {
                    color: $inverseTextColor;
                    display: inline-block;
                    padding: math.div($defaultMargin, 2.4) $defaultMargin * 0.5;
                    white-space: nowrap;
                }

                .label {
                    display: inline-block;
                    width: 100%;
                    @include text-overflow-ellipsis;
                }
            }
        }
    }
}
