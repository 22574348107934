.main-menu-phone-request-container,
.main-menu-email-request-container {
    padding: $defaultMargin * 0.5;

    .form-container {
        margin-top: $defaultMargin;

        .form-flex-row {
            align-items: flex-start;
            flex-wrap: nowrap;
        }

        .form-row {
            &.button-row {
                text-align: center;
            }
        }

        .inner-addon {
            display: flex;
            flex-direction: column;
            position: relative;

            &.left-addon {
                input {
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                    padding-left: 6rem;
                }
            }

            .phone-prefix {
                align-items: center;
                border-right: 1px solid $formInputBorderColor;
                color: $formPlaceholderTextColor;
                display: flex;
                font-weight: $mediumFontWeight;
                height: 39px;
                padding: 0 0.8rem;
                position: absolute;
                top: 1px;

                @include desktop {
                    height: 36px;
                }
            }
        }

        .button-primary {
            min-width: 125px;

            &.main-menu-phone-save {
                border: 0;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                padding: 9.5px $defaultMargin * 0.5;
            }
        }
    }

    p {
        text-align: center;
    }

    .response-message {
        font-size: 2rem;
        font-weight: $mediumFontWeight;
        margin: $smallScreenMargin 0;
        text-align: center;

        &.success {
            color: $yesColor;
        }

        &.error {
            color: $noColor;
        }
    }

    .opening-hours {
        align-items: center;
        display: flex;
        margin-top: $defaultMargin;

        .link {
            font-weight: $mediumFontWeight;
        }

        .hours {
            font-weight: $mediumFontWeight;
        }

        &::before {
            background-color: $defaultBackgroundColor;
            background-image: url("/assets/icon/opening-hours.svg");
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 70%;
            border-radius: 50%;
            content: " ";
            display: inline-block;
            flex: none;
            height: 4rem;
            margin-right: $defaultMargin * 0.5;
            width: 4rem;
            @include own-box-shadow(0, 7px, 0.2);
        }
    }
}

@media (min-width: 540px) {
    .main-menu-phone-request-container,
    .main-menu-email-request-container {
        padding: $defaultMargin;
    }
}

@include desktop {
    #main-menu-email-modal,
    #main-menu-phone-modal {
        .modal-content {
            bottom: auto;
            max-height: 85vh;
        }
    }

    .main-menu-phone-request-container,
    .main-menu-email-request-container {
        border-radius: $defaultBorderRadius;
        margin-bottom: $defaultMargin;
        padding: $defaultMargin * 2;
        @include hover-box-border;

        .form-container {
            align-items: center;
            display: flex;
            justify-content: center;

            &.email-form {
                flex-direction: column;

                .form-row {
                    min-width: 400px;
                }
            }

            .button-primary {
                padding: $defaultMargin * 0.25 $defaultMargin;
            }
        }

        .opening-hours {
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }
}
