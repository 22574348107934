@use "sass:math";

.car-info-container {
    &:not(:last-of-type) {
        margin-bottom: $defaultMargin;
    }

    .car-info-description {
        border: 1px solid $borderLightColor;
        border-radius: $defaultBorderRadius;
        display: flex;
        justify-content: center;
        margin-top: $defaultMargin;
        padding: $defaultMargin;

        &::before {
            background: url("/assets/icon/user/info-admin.svg") 50% 50% no-repeat transparent;
            background-size: 100%;
            content: " ";
            display: block;
            flex: none;
            height: 2rem;
            margin-right: math.div($defaultMargin, 3);
            width: 2rem;
        }
    }

    .hidden-content {
        .car-offer-info {
            &:last-of-type {
                margin-bottom: $defaultMargin;
            }
        }
    }

    .car-offer-info {
        align-items: flex-start;
        border: 1px solid $borderLightColor;
        border-radius: $largeBorderRadius;
        @include own-box-shadow(3px, 3px);
        @include flex-grid;

        &:not(:last-of-type) {
            margin-bottom: $defaultMargin;
        }

        .subtitle {
            display: block;
            font-size: $defaultFontSize;
            font-weight: $defaultFontWeight;
            @include default-font-family;
        }

        .car-offer-image {
            border-radius: $defaultBorderRadius $defaultBorderRadius 0 0;
            overflow: hidden;
            width: 100%;

            .car-image {
                border-radius: inherit;
                height: 100%;
                min-width: 80px;
                object-fit: cover;
                width: 100%;
            }
        }

        .car-info {
            padding: $defaultMargin * 0.5;
            width: 100%;

            .claim-label {
                .tooltip {
                    text-align: right;
                }
            }

            .licence-plate-container {
                color: $darkTextColor;
                font-size: $smallFontSize;
                margin-top: $defaultMargin * 0.5;

                .licence-plate {
                    font-weight: $boldFontWeight;
                    letter-spacing: 1px;
                }
            }

            .inquiry-for-container {
                color: $darkTextColor;
            }
        }

        &.inquired-car {
            .price-box {
                margin-bottom: $defaultMargin * 0.5;

                &:nth-of-type(1) {
                    background-color: $lightGreenBackgroundColor;
                }

                &:nth-of-type(2) {
                    background-color: $accentedBackgroundColor;
                }
            }
        }

        &.subscribed-car {
            background-color: $accentedBackgroundColor;

            .prices-container {
                background-color: $defaultBackgroundColor;
                border-radius: $largeBorderRadius;
            }

            .price-box {
                border-radius: 0;

                &:nth-of-type(1) {
                    border-bottom: 1px dashed $borderLightColor;
                    position: relative;

                    .payment-label,
                    .claim-label {
                        margin-top: $defaultMargin * 0.25;
                    }
                }

                &:nth-of-type(2) {
                    text-align: center;

                    .payment-label,
                    .claim-label {
                        display: none;
                    }
                }
            }
        }
    }

    .prices-container {
        margin-bottom: $defaultMargin * 0.5;
        margin-top: $defaultMargin * 0.5;
    }

    .price-box {
        border-radius: $defaultBorderRadius;
        display: flex;
        flex-direction: column;
        padding: $defaultMargin * 0.5;
        width: 100%;

        &:nth-of-type(1) {
            .price {
                &:nth-of-type(1) {
                    color: $yesColor;

                    .vat-info {
                        font-size: $defaultFontSize;
                    }
                }
            }
        }

        &:nth-of-type(2) {
            .price {
                &:nth-of-type(1) {
                    color: $darkTextColor;
                }
            }
        }

        .price-title {
            color: $darkTextColor;
            margin-bottom: $defaultMargin * 0.25;
        }

        .price-offer-price {
            white-space: nowrap;

            .vat-info {
                color: $defaultTextColor;
                font-size: $defaultFontSize;
                font-weight: $defaultFontWeight;
            }

            .price {
                &:nth-of-type(1) {
                    font-size: $h3SmallScreenFontSize;
                    font-weight: $boldFontWeight;
                }

                &:nth-of-type(2) {
                    font-size: $smallScreenFontSize;

                    .vat-info {
                        font-size: $smallScreenFontSize;
                    }
                }
            }
        }
    }

    .button-container {
        .button {
            width: 100%;

            &:nth-of-type(1) {
                margin-bottom: $defaultMargin * 0.25;
            }
        }
    }

    .car-offer-info-footer {
        border-top: 1px dashed $borderLightColor;
        font-size: $smallFontSize;
        padding-top: $defaultMargin * 0.5;
        text-align: center;
    }
}

@media (min-width: $smallestDeviceBreakpoint) {
    .car-info-container {
        .car-offer-info {
            &.subscribed-car {
                .price-box {
                    &:nth-of-type(1) {
                        .payment-label {
                            margin-top: 0;
                            position: absolute;
                            right: $defaultMargin * 0.5;
                            top: $defaultMargin * 1.5;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 390px) {
    .car-info-container {
        .car-offer-info {
            &.inquired-car {
                .prices-container {
                    display: flex;
                }

                .price-box {
                    margin-bottom: 0;
                    @include boxes-per-row(2, 2%);
                }
            }
        }
    }
}

@include desktop {
    .car-info-container {
        .car-offer-info {
            padding: $defaultMargin;

            .car-info {
                margin-left: 3%;
                padding: 0;
                width: 54%;

                .licence-plate-container {
                    font-size: $defaultFontSize;
                }
            }

            .car-offer-image {
                border-radius: $largeBorderRadius;
                height: 255px;
                width: 43%;
            }

            .services-container {
                order: 4;
                padding: $defaultMargin;

                &.deal-parameters {
                    margin-top: $defaultMargin * 2;
                }

                .services {
                    column-count: 2;
                    font-size: $defaultFontSize;
                }
            }

            &.subscribed-car {
                .prices-container {
                    display: flex;
                }

                .price-box {
                    @include boxes-per-row(2, 0);

                    &:nth-of-type(1) {
                        border-bottom: 0;
                        border-right: 2px dashed $borderLightColor;
                        text-align: left;

                        .payment-label,
                        .claim-label {
                            display: none;
                        }
                    }

                    &:nth-of-type(2) {
                        align-items: flex-end;
                        text-align: right;

                        .payment-label,
                        .claim-label {
                            display: flex;
                        }
                    }
                }
            }
        }

        .price-box {
            padding: $defaultMargin;

            .price-offer-price {
                .price {
                    &:nth-of-type(1) {
                        font-size: $h2LargeScreenFontSize;
                    }

                    &:nth-of-type(2) {
                        font-size: $defaultFontSize;

                        .vat-info {
                            font-size: $defaultFontSize;
                        }
                    }
                }
            }
        }

        .button-container {
            display: flex;

            .button {
                width: auto;

                &:nth-of-type(1) {
                    margin-bottom: 0;
                }

                &:nth-of-type(2) {
                    margin-left: $defaultMargin * 0.5;
                }
            }
        }

        .car-offer-info-footer {
            border-top: 0;
            font-size: $defaultFontSize;
            text-align: left;
        }
    }
}
