#cookie-policy-panel {
    border-radius: $defaultBorderRadius;
    bottom: $defaultMargin * 0.25;
    left: $defaultMargin * 0.25;
    overflow-y: auto;
    padding: $defaultMargin;
    position: fixed;
    right: $defaultMargin * 0.25;
    top: $defaultMargin * 0.25;
    z-index: $modalZIndex + 10;
    @include own-box-shadow(0, 10px);
    @include hover-box-border;

    &:not(.open) {
        .buttons-container {
            .button {
                &:nth-of-type(-n+2) {
                    min-width: 290px;
                }
            }
        }
    }

    .back {
        display: none;
    }

    .logo-container {
        text-align: center;

        .headline {
            margin-top: $defaultMargin;
        }
    }

    .buttons-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: $defaultMargin;

        #confirm-selected-cookies {
            display: none;
        }

        .button {
            font-size: $defaultFontSize;
            margin-top: $defaultMargin * 0.5;
        }
    }

    #cookie-policy-panel-options {
        margin-top: $defaultMargin;

        .gdpr-cookies-policy-options {
            list-style: none outside;
            margin-bottom: 0;

            li {
                label {
                    font-weight: $boldFontWeight;
                    line-height: $defaultLineHeight;
                }
            }
        }

        p {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

@media (min-width: $smallestDeviceBreakpoint) {
    #cookie-policy-panel {
        top: auto;

        &:not(.open) {
            .buttons-container {
                .button {
                    &:nth-of-type(-n+2) {
                        min-width: 325px;
                    }
                }
            }
        }

        .buttons-container {
            .button {
                font-size: initial;
            }
        }
    }
}

@include mobile-tablet {
    #cookie-policy-panel {
        #cookie-policy-panel-options {
            display: none;
        }

        &.open {
            border-radius: 0;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;

            #cookie-policy-panel-options {
                display: block;
            }

            .back {
                cursor: pointer;
                display: inline-block;
                position: absolute;

                &::before {
                    background: url("/assets/icon/right-arrow-gray.svg") 0 50% transparent no-repeat;
                    background-size: 85%;
                    content: " ";
                    display: inline-block;
                    height: 1.4rem;
                    margin-right: $defaultMargin * 0.25;
                    margin-top: -0.3rem;
                    transform: rotate(180deg);
                    vertical-align: middle;
                    width: 1.4rem;
                }
            }
        }
    }
}

@include desktop {
    #cookie-policy-panel {
        align-items: center;
        bottom: $defaultMargin * 0.5;
        display: flex;
        left: 5%;
        padding: $defaultMargin * 3 $defaultMargin * 2;
        right: auto;
        width: 90%;

        .logo-container {
            width: 100%;
        }

        .content-container {
            border-right: 2px solid $borderLightColor;
            margin-right: $defaultMargin;
            padding-right: $defaultMargin;
        }

        #cookie-policy-panel-options {
            max-height: 0;
            overflow: hidden;
            @include own-transition(max-height);
        }

        &.open {
            #cookie-policy-panel-options {
                max-height: 1000px;
            }
        }
    }
}

@include desktop-large {
    #cookie-policy-panel {
        left: 50%;
        max-width: 1280px;
        padding: $defaultMargin * 4.5 $defaultMargin * 3;
        transform: translateX(-50%);

        .content-container {
            margin-right: $defaultMargin * 2;
            padding-right: $defaultMargin * 2;
        }
    }
}
