@charset "UTF-8";
html.no-js .no-js-hidden {
  display: none;
}
html.js .js-hidden {
  display: none;
}
html.mobile-no-scroll {
  overflow: hidden;
}

body.mobile-no-scroll {
  overflow: auto;
}

.hidden {
  display: none;
}

.cf-turnstile {
  min-height: 20.3px;
}

.no-scroll {
  overflow: hidden;
}

.mobile-no-scroll {
  -webkit-overflow-scrolling: touch;
  bottom: 0;
  left: 0;
  position: relative;
  right: 0;
  top: 0;
  touch-action: none;
}

.open-popup-checkbox {
  display: none;
}

.close-popup-arrow-before::before {
  content: "←";
  display: inline-block;
  margin-right: 4px;
  margin-top: -0.2rem;
  vertical-align: top;
}

.text-center {
  text-align: center;
}

.no-wrap {
  white-space: nowrap;
}

.show-more-container .hidden-content {
  display: none;
}
.show-more-container .show-more {
  border-bottom: 1px solid #3c3f44;
  color: #3c3f44;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  margin-top: 12px;
  padding-bottom: 2px;
  text-decoration: none;
  width: fit-content;
}
.show-more-container .show-more::after {
  background-color: #3c3f44;
  content: " ";
  display: inline-block;
  height: 1.2rem;
  margin: -0.2rem 0 0 4px;
  mask: url("/assets/icon/expand-arrow.svg") no-repeat 50% 50%;
  mask-size: contain;
  vertical-align: middle;
  width: 1.2rem;
}
.show-more-container .show-more.active::after {
  transform: rotate(180deg);
}

.svg-sprite {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.img-fluid {
  height: auto;
  width: 100%;
}

img:not([src]) {
  display: none;
}

.video-container {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.video-container::after {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.video-container iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

body::after {
  content: "small-screen";
  display: none;
}

@media (min-width: 940px) {
  body::after {
    content: "medium-screen";
  }
}
@media (min-width: 1200px) {
  body::after {
    content: "large-screen";
  }
}
.button {
  border-radius: 30px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.45;
  padding: 6px 18px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 0.3s ease, border 0.3s ease, box-shadow 0.3s ease, background 0.3s ease, transform 0.3s ease;
  white-space: nowrap;
  border: 2px solid #ededed;
  background: #ffffff;
  color: #ff3f2d;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.15);
}
.button:active, .button:hover, .button:focus {
  background-color: #ff3f2d;
  border-color: #ff3f2d;
  color: #ffffff;
}
@media (min-width: 640px) {
  .button {
    padding: 8px 24px;
  }
}
.button:active, .button:hover, .button:focus {
  outline: 0;
  box-shadow: 0 5px 1px rgba(0, 0, 0, 0.15);
}
.button:not(.mfp-close):active {
  transform: scale(0.98);
}
.button .icon {
  fill: #ff3f2d;
  height: 20px;
  margin-right: 6px;
  width: 20px;
}
.button:active .icon, .button:hover .icon, .button:focus .icon {
  filter: brightness(0) invert(1);
}
.button.button-primary {
  background: #ff3f2d;
  color: #ffffff;
  border: 2px solid #ff3f2d;
}
.button.button-primary:active, .button.button-primary:hover, .button.button-primary:focus {
  background-color: #b52c17;
  border-color: #b52c17;
  color: #ffffff;
}
.button.button-big {
  padding: 12px;
  width: 100%;
}
@media (min-width: 640px) {
  .button.button-big {
    border-radius: 45px;
    font-size: 18px;
    padding: 12px 48px;
    width: auto;
  }
}
.button.button-disabled {
  opacity: 0.8;
  pointer-events: none;
}
.button.header-button {
  font-size: 85%;
  background: #ffffff;
  color: #3c3f44;
  border: 1px solid #ededed;
}
.button.header-button:active, .button.header-button:hover, .button.header-button:focus {
  background-color: #ededee;
  border-color: #ededee;
  color: #000000;
}

.facebook-button {
  background: #4267b2;
  color: #ffffff;
  border: 2px solid #4267b2;
}
.facebook-button:active, .facebook-button:hover, .facebook-button:focus {
  background-color: #274c97;
  border-color: #274c97;
  color: #ffffff;
}

.button-light {
  border-bottom: 1px solid #ff3f2d;
  box-shadow: none;
  color: #ff3f2d;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.45;
  text-decoration: none;
  transition: color 0.3s, border-bottom-color 0.3s, padding 0.3s;
}
.button-light:focus, .button-light:hover {
  border-color: #b52c17;
  color: #b52c17;
}
@media (min-width: 940px) {
  .button-light {
    font-size: 16px;
  }
}

*,
::before,
::after {
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

html {
  box-sizing: border-box;
  cursor: default;
  text-size-adjust: 100%;
}

body {
  margin: 0;
}

dl,
dl > dd {
  margin: 0;
}

audio,
iframe,
img,
svg {
  vertical-align: middle;
}

img {
  border-style: none;
}

svg {
  fill: currentColor;
}
svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  touch-action: manipulation;
}

[hidden] {
  display: none;
}

[aria-busy=true] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-hidden=false][hidden] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}
[aria-hidden=false][hidden]:focus {
  clip: auto;
}

[aria-disabled] {
  cursor: default;
}

.container {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 12px;
  position: relative;
  width: 100%;
}
.container::after {
  clear: both;
  content: "";
  display: table;
}
.container--tight {
  max-width: 940px;
}

.container-content-body {
  margin-bottom: 48px;
}
@media (min-width: 940px) {
  .container-content-body {
    margin-bottom: 72px;
  }
}

@media (min-width: 640px) {
  .container {
    padding: 0;
    width: 90%;
  }
}
.grid-flex-left-sidebar {
  display: flex;
  min-height: 100vh;
}
@media (min-width: 1920px) {
  .grid-flex-left-sidebar {
    max-width: 1920px;
  }
}

.grid-flex {
  margin-bottom: 24px;
}
.grid-flex.listing {
  margin-bottom: 48px;
}

@media (min-width: 940px) {
  .container--tight {
    max-width: 940px;
  }
  .grid-flex {
    display: flex;
  }
  .grid-flex .content-main {
    width: 68%;
  }
  .grid-flex.listing {
    margin-bottom: 72px;
  }
  .grid-flex .content-aside-left {
    margin-right: 2%;
    position: relative;
    width: 30%;
  }
  .grid-flex .content-main-listing {
    margin-left: 1%;
    width: 76%;
  }
  .grid-flex .content-aside-listing {
    min-width: 270px;
    position: relative;
    width: 23%;
  }
  .grid-flex-left-sidebar {
    flex-direction: row;
  }
  .grid-flex-left-sidebar .content-main {
    margin-left: 2%;
    margin-right: 2%;
    width: 68%;
  }
  .grid-flex-left-sidebar .content-aside {
    min-width: 330px;
    width: 30%;
  }
}
html {
  font-size: 62.5%;
}

body {
  -moz-font-feature-settings: "liga" on;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #3c3f44;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  min-height: 100vh;
  overflow-anchor: auto;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
}

.fonts-loaded body {
  font-family: DMSans, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.fonts-loaded h1,
.fonts-loaded h2,
.fonts-loaded h3,
.fonts-loaded h4,
.fonts-loaded h5,
.fonts-loaded h6 {
  font-family: Poppins, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  font-weight: 600;
  line-height: 1.5;
}

h1 {
  font-size: 24px;
  margin: 0 0 12px;
}

h2 {
  font-size: 20px;
  margin: 0 0 12px;
}

h3 {
  font-size: 18px;
  margin: 0 0 6px;
}

h4 {
  font-size: 18px;
  margin: 0 0 6px;
}

h5 {
  font-size: 16px;
  margin: 0 0 6px;
}

h6 {
  font-size: 16px;
  margin: 0 0 6px;
}

@media (min-width: 640px) {
  h1 {
    font-size: 32px;
    margin: 0 0 24px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 18px;
  }
}
p {
  margin-top: 0;
}

small {
  font-size: 85%;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: 700;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

::selection {
  background-color: #00baf2;
  color: #ffffff;
  text-shadow: none;
}

.error-heading {
  text-align: center;
}

.error-page {
  padding-top: 48px;
  text-align: center;
}
.error-page p {
  font-size: 115%;
}
.error-page img {
  display: block;
  height: auto;
  margin: 24px auto;
  max-width: 100%;
}
.error-page .button {
  margin-top: 24px;
}

@font-face {
  font-display: swap;
  font-family: DMSans;
  font-style: normal;
  font-weight: 400;
  src: local("DM-Sans-Regular"), local("DM Sans Regular"), url("/assets/font/dmsans-regular.woff2") format("woff2"), url("/assets/font/dmsans-regular.woff") format("woff"), url("/assets/font/dmsans-regular.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  src: local("DM-Sans-Medium"), local("DM Sans Medium"), url("/assets/font/dmsans-medium.woff2") format("woff2"), url("/assets/font/dmsans-medium.woff") format("woff"), url("/assets/font/dmsans-medium.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: DMSans;
  font-style: normal;
  font-weight: 700;
  src: local("DM-Sans"), local("DM Sans"), local("DM-Sans-Bold"), local("DM Sans Bold"), url("/assets/font/dmsans-bold.woff2") format("woff2"), url("/assets/font/dmsans-bold.woff") format("woff"), url("/assets/font/dmsans-bold.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: local("Poppins-SemiBold"), local("Poppins SemiBold"), url("/assets/font/poppins-semibold.woff2") format("woff2"), url("/assets/font/poppins-semibold.woff") format("woff"), url("/assets/font/poppins-semibold.ttf") format("truetype");
}
input,
textarea,
select,
button {
  font-family: inherit;
  font-size: 14px;
}
@media (max-width: 939px) {
  input,
  textarea,
  select,
  button {
    font-size: 16.1px;
  }
}

button {
  border-radius: 30px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.45;
  padding: 6px 18px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 0.3s ease, border 0.3s ease, box-shadow 0.3s ease, background 0.3s ease, transform 0.3s ease;
  white-space: nowrap;
  border: 2px solid #ededed;
  background: #ffffff;
  color: #ff3f2d;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.15);
}
button:active, button:hover, button:focus {
  background-color: #ff3f2d;
  border-color: #ff3f2d;
  color: #ffffff;
}
@media (min-width: 640px) {
  button {
    padding: 8px 24px;
  }
}
button:active, button:hover, button:focus {
  outline: 0;
  box-shadow: 0 5px 1px rgba(0, 0, 0, 0.15);
}
button:not(.mfp-close):active {
  transform: scale(0.98);
}
button .icon {
  fill: #ff3f2d;
  height: 20px;
  margin-right: 6px;
  width: 20px;
}
button:active .icon, button:hover .icon, button:focus .icon {
  filter: brightness(0) invert(1);
}
button.button-primary {
  background: #ff3f2d;
  color: #ffffff;
  border: 2px solid #ff3f2d;
}
button.button-primary:active, button.button-primary:hover, button.button-primary:focus {
  background-color: #b52c17;
  border-color: #b52c17;
  color: #ffffff;
}
button.button-big {
  padding: 12px;
  width: 100%;
}
@media (min-width: 640px) {
  button.button-big {
    border-radius: 45px;
    font-size: 18px;
    padding: 12px 48px;
    width: auto;
  }
}
button.button-disabled {
  opacity: 0.8;
  pointer-events: none;
}
button.header-button {
  font-size: 85%;
  background: #ffffff;
  color: #3c3f44;
  border: 1px solid #ededed;
}
button.header-button:active, button.header-button:hover, button.header-button:focus {
  background-color: #ededee;
  border-color: #ededee;
  color: #000000;
}

input[type=submit], input[type=reset], input[type=button] {
  border-radius: 30px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.45;
  padding: 6px 18px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 0.3s ease, border 0.3s ease, box-shadow 0.3s ease, background 0.3s ease, transform 0.3s ease;
  white-space: nowrap;
  border: 2px solid #ededed;
  background: #ffffff;
  color: #ff3f2d;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.15);
}
input[type=submit]:active, input[type=submit]:hover, input[type=submit]:focus, input[type=reset]:active, input[type=reset]:hover, input[type=reset]:focus, input[type=button]:active, input[type=button]:hover, input[type=button]:focus {
  background-color: #ff3f2d;
  border-color: #ff3f2d;
  color: #ffffff;
}
@media (min-width: 640px) {
  input[type=submit], input[type=reset], input[type=button] {
    padding: 8px 24px;
  }
}
input[type=submit]:active, input[type=submit]:hover, input[type=submit]:focus, input[type=reset]:active, input[type=reset]:hover, input[type=reset]:focus, input[type=button]:active, input[type=button]:hover, input[type=button]:focus {
  outline: 0;
  box-shadow: 0 5px 1px rgba(0, 0, 0, 0.15);
}
input[type=submit]:not(.mfp-close):active, input[type=reset]:not(.mfp-close):active, input[type=button]:not(.mfp-close):active {
  transform: scale(0.98);
}
input[type=submit] .icon, input[type=reset] .icon, input[type=button] .icon {
  fill: #ff3f2d;
  height: 20px;
  margin-right: 6px;
  width: 20px;
}
input[type=submit]:active .icon, input[type=submit]:hover .icon, input[type=submit]:focus .icon, input[type=reset]:active .icon, input[type=reset]:hover .icon, input[type=reset]:focus .icon, input[type=button]:active .icon, input[type=button]:hover .icon, input[type=button]:focus .icon {
  filter: brightness(0) invert(1);
}
input[type=submit].button-primary, input[type=reset].button-primary, input[type=button].button-primary {
  background: #ff3f2d;
  color: #ffffff;
  border: 2px solid #ff3f2d;
}
input[type=submit].button-primary:active, input[type=submit].button-primary:hover, input[type=submit].button-primary:focus, input[type=reset].button-primary:active, input[type=reset].button-primary:hover, input[type=reset].button-primary:focus, input[type=button].button-primary:active, input[type=button].button-primary:hover, input[type=button].button-primary:focus {
  background-color: #b52c17;
  border-color: #b52c17;
  color: #ffffff;
}
input[type=submit].button-big, input[type=reset].button-big, input[type=button].button-big {
  padding: 12px;
  width: 100%;
}
@media (min-width: 640px) {
  input[type=submit].button-big, input[type=reset].button-big, input[type=button].button-big {
    border-radius: 45px;
    font-size: 18px;
    padding: 12px 48px;
    width: auto;
  }
}
input[type=submit].button-disabled, input[type=reset].button-disabled, input[type=button].button-disabled {
  opacity: 0.8;
  pointer-events: none;
}
input[type=submit].header-button, input[type=reset].header-button, input[type=button].header-button {
  font-size: 85%;
  background: #ffffff;
  color: #3c3f44;
  border: 1px solid #ededed;
}
input[type=submit].header-button:active, input[type=submit].header-button:hover, input[type=submit].header-button:focus, input[type=reset].header-button:active, input[type=reset].header-button:hover, input[type=reset].header-button:focus, input[type=button].header-button:active, input[type=button].header-button:hover, input[type=button].header-button:focus {
  background-color: #ededee;
  border-color: #ededee;
  color: #000000;
}
input[type=email], input[type=tel], input[type=text], input[type=number], input[type=password] {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 8px;
  color: #4d5156;
  font-weight: 500;
  line-height: 1.45;
  margin: 0;
  padding: 8px 12px;
  width: 100%;
  transition: border-color 0.3s ease;
}
input[type=email]:disabled, input[type=tel]:disabled, input[type=text]:disabled, input[type=number]:disabled, input[type=password]:disabled {
  background-color: #ededee;
  pointer-events: none;
}
input[type=email]:active, input[type=email]:focus, input[type=tel]:active, input[type=tel]:focus, input[type=text]:active, input[type=text]:focus, input[type=number]:active, input[type=number]:focus, input[type=password]:active, input[type=password]:focus {
  border-color: #c6cad2;
  outline: none;
}
input[type=email]::placeholder, input[type=tel]::placeholder, input[type=text]::placeholder, input[type=number]::placeholder, input[type=password]::placeholder {
  color: #c6ccd7;
}
input[type=email]:-webkit-autofill, input[type=tel]:-webkit-autofill, input[type=text]:-webkit-autofill, input[type=number]:-webkit-autofill, input[type=password]:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
  -webkit-text-fill-color: #4d5156;
}
input[type=email].error, input[type=tel].error, input[type=text].error, input[type=number].error, input[type=password].error {
  border: 1px solid #ec5340;
}
input[type=email] ~ .error, input[type=tel] ~ .error, input[type=text] ~ .error, input[type=number] ~ .error, input[type=password] ~ .error {
  color: #ec5340;
  font-size: 85%;
  line-height: 1.45;
  padding-top: 6px;
}

select,
textarea {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 8px;
  color: #4d5156;
  font-weight: 500;
  line-height: 1.45;
  margin: 0;
  padding: 8px 12px;
  width: 100%;
  transition: border-color 0.3s ease;
}
select:disabled,
textarea:disabled {
  background-color: #ededee;
  pointer-events: none;
}
select:active, select:focus,
textarea:active,
textarea:focus {
  border-color: #c6cad2;
  outline: none;
}
select::placeholder,
textarea::placeholder {
  color: #c6ccd7;
}
select:-webkit-autofill,
textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
  -webkit-text-fill-color: #4d5156;
}
select.error,
textarea.error {
  border: 1px solid #ec5340;
}
select ~ .error,
textarea ~ .error {
  color: #ec5340;
  font-size: 85%;
  line-height: 1.45;
  padding-top: 6px;
}

select {
  -webkit-appearance: none;
  appearance: none;
  background-image: url("/assets/icon/expand-arrow.svg");
  background-position: 95% 50%;
  background-repeat: no-repeat;
  background-size: 1rem;
  cursor: pointer;
  font-weight: 500;
  padding-right: 24px;
}
select option:checked {
  color: #ff3f2d;
  font-weight: 700;
}
select::-ms-expand {
  display: none;
}

label {
  display: block;
  font-weight: 500;
  line-height: 2;
}
label.required::after {
  color: #ec5340;
  content: "*";
  display: inline-block;
  padding-left: 0.2rem;
}
label.help-title::after {
  background-color: #00baf2;
  border-radius: 50%;
  color: #ffffff;
  content: "?";
  cursor: help;
  display: inline-block;
  font-size: 85%;
  font-weight: 700;
  line-height: 1.25;
  margin-left: 0.3rem;
  padding: 0 0.4rem;
}

.form-row,
.form-group {
  margin-bottom: 8px;
}

.form-row--alternative {
  display: none;
}

.form-submit-row,
.form-agreement {
  text-align: center;
}

.form-flex-row {
  display: flex;
  flex-wrap: wrap;
}

.form-submit-row .button-primary {
  margin-bottom: 12px;
}

.form-half {
  width: 100%;
}
@media (max-width: 639px) {
  .form-half:first-child {
    margin-bottom: 12px;
  }
}
@media (min-width: 640px) {
  .form-half {
    margin-left: 1%;
    width: 49.5%;
    margin-bottom: 0;
  }
  .form-half:nth-child(2n+1) {
    margin-left: 0;
  }
}

form .help-block {
  background-color: #f2dede;
  border-color: #ebccd1;
  border-radius: 10px;
  color: #a94442;
  display: block;
  font-size: 85%;
  line-height: 1.45;
  margin: 8px 0;
  padding: 6px 12px;
}
form .help-block .list-unstyled {
  list-style: none;
  margin: 0;
}
form .help-block .list-unstyled li {
  margin: 0;
}

picture {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
picture.fallback {
  background-color: #ededee;
  background-image: url("/assets/icon/img-placeholder.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

img.fallback {
  height: auto;
  max-width: 80%;
}
img.img-inner {
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  transition: transform 0.3s ease;
  will-change: transform;
}

.server-name-label {
  padding: 12px;
  position: fixed;
  right: 24px;
  text-align: center;
  top: 24px;
  z-index: 1400;
}
.server-name-label.dev {
  background-color: #fedd24;
}
.server-name-label.prod {
  background-color: #da0339;
}
.server-name-label.preprod {
  background-color: #ff573d;
}
.server-name-label.local {
  background-color: #6b6f73;
}
.server-name-label .server-name {
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}

@media (min-width: 940px) {
  .server-name-label {
    padding: 24px;
  }
  .server-name-label .server-name {
    font-size: 3rem;
  }
}
.tooltip.tooltip-active {
  cursor: pointer;
  position: relative;
}
.tooltip.tooltip-active::after {
  background: url("/assets/icon/question-mark-gray.svg") 50% 50% no-repeat;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 1.6rem;
  margin-top: -0.3rem;
  vertical-align: middle;
  width: 1.6rem;
}
.tooltip.tooltip-active::before {
  background-image: url("/assets/icon/close.svg");
  background-position: right 12px top 12px;
  background-repeat: no-repeat;
  background-size: 2rem;
  bottom: 0;
  color: #000000;
  content: attr(data-title);
  cursor: pointer;
  display: block;
  font-weight: 400;
  left: 0;
  line-height: 1.45;
  padding: 48px 24px;
  position: fixed;
  text-decoration: none;
  text-transform: none;
  transform: translateY(100%);
  white-space: pre-wrap;
  width: 100%;
  z-index: 1020;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: transform 0.3s ease;
  will-change: transform;
}
.tooltip.tooltip-active.tooltip-open::before {
  transform: translateY(0);
}

@media (min-width: 640px) {
  .tooltip.tooltip-active::before {
    border-radius: 10px;
    bottom: auto;
    display: none;
    left: 50%;
    max-width: 400px;
    min-width: 200px;
    padding: 36px 24px;
    position: absolute;
    right: auto;
    top: 24px;
    transform: translateX(-50%) translateY(0);
    transition: none;
    width: max-content;
  }
  .tooltip.tooltip-active.tooltip-open::before {
    display: block;
    transform: translateX(-50%);
  }
  .tooltip.tooltip-active.tooltip-open.right::before {
    left: auto;
    right: 0;
    transform: translateX(15%);
  }
  .tooltip.tooltip-active.tooltip-open.left::before {
    left: 0;
    right: auto;
    transform: translateX(-85%);
  }
}
@media (max-width: 639px) {
  body.tooltip-open {
    position: relative;
  }
  body.tooltip-open::before {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 700;
  }
  body.tooltip-open #main-menu::before {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 700;
  }
}
@media (min-width: 940px) {
  .tooltip.tooltip-active::before {
    z-index: 598;
  }
}
input[type=checkbox]:not(.open-popup-checkbox) {
  cursor: pointer;
  display: none;
  height: initial;
  margin-bottom: 0;
  padding: 0;
  width: initial;
}
input[type=checkbox]:not(.open-popup-checkbox) + label {
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 1.45;
  position: relative;
}
input[type=checkbox]:not(.open-popup-checkbox) + label::before {
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 4px;
  content: "";
  display: inline-block;
  margin-right: 6px;
  margin-top: -0.3rem;
  padding: 8px;
  position: relative;
  vertical-align: middle;
}
input[type=checkbox]:not(.open-popup-checkbox):checked + label {
  color: #ff3f2d;
}
input[type=checkbox]:not(.open-popup-checkbox):checked + label::after {
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  content: "";
  display: block;
  height: 12px;
  left: 6px;
  margin-top: -0.2rem;
  position: absolute;
  top: 4px;
  transform: rotate(45deg);
  width: 6px;
}
input[type=checkbox]:not(.open-popup-checkbox):checked + label::before {
  background-color: #ff3f2d;
  border-color: #ff3f2d;
}
input[type=checkbox]:not(.open-popup-checkbox):checked + label.green::before {
  background-color: #2dbd58;
  border-color: #2dbd58;
}
input[type=checkbox]:not(.open-popup-checkbox):disabled + label {
  cursor: auto;
}
input[type=checkbox]:not(.open-popup-checkbox):disabled + label::before {
  opacity: 0.4;
}
input.green[type=checkbox]:not(.open-popup-checkbox):checked + label {
  color: #000000;
}
input.green[type=checkbox]:not(.open-popup-checkbox):checked + label::before {
  background-color: #2dbd58;
  border-color: #2dbd58;
}

.subscription-banner-small-container {
  margin-bottom: 24px;
  overflow: hidden;
  padding: 48px 12px;
  position: relative;
}
.subscription-banner-small-container picture {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.subscription-banner-small-container picture img {
  object-position: center 65%;
}
.subscription-banner-small-container::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 0%, transparent 50%, transparent 100%);
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}
.subscription-banner-small-container .banner-promo-text {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.subscription-banner-small-container .banner-promo-text span {
  background-color: #ff3f2d;
  color: #ffffff;
  display: block;
  font-size: 32px;
  font-weight: 700;
  padding: 3px 18px;
  text-transform: uppercase;
  width: fit-content;
}
.subscription-banner-small-container .banner-promo-text span:first-child {
  transform: rotate(-5deg) translateX(-10px) translateY(-5px);
}
.subscription-banner-small-container .banner-promo-text span:nth-child(2) {
  transform: rotate(3deg);
}
.subscription-banner-small-container .banner-container-column {
  position: relative;
  z-index: 3;
}
.subscription-banner-small-container .banner-container-column:first-child {
  min-height: 140px;
  text-align: center;
}

@media (min-width: 640px) {
  .subscription-banner-small-container {
    border-radius: 20px;
    margin-top: 24px;
    padding: 24px 48px;
  }
  .subscription-banner-small-container::before {
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 0%, transparent 50%, transparent 100%);
  }
}
@media (min-width: 940px) {
  .subscription-banner-small-container .banner-promo-text {
    align-items: flex-start;
    transform: translateY(25px);
  }
  .subscription-banner-small-container .banner-promo-text span {
    font-size: 32px;
  }
  .subscription-banner-small-container .banner-promo-text span:first-child {
    transform: rotate(-5deg) translateX(-10px) translateY(-5px);
  }
  .subscription-banner-small-container .banner-promo-text span:nth-child(2) {
    transform: rotate(3deg);
  }
}
@media (min-width: 1220px) {
  .subscription-banner-small-container .banner-container-columns {
    display: flex;
  }
  .subscription-banner-small-container .banner-container-columns .banner-container-column {
    margin-left: 2%;
    width: 49%;
  }
  .subscription-banner-small-container .banner-container-columns .banner-container-column:nth-child(2n+1) {
    margin-left: 0;
  }
  .subscription-banner-small-container .banner-container-columns .banner-container-column:first-child {
    text-align: left;
  }
  .subscription-banner-small-container .banner-container-columns .banner-container-column:nth-child(2) {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
}
.abandoned-inquiry-car-modal {
  border-radius: 10px;
  display: none;
  max-width: 360px;
  padding: 24px;
  right: 24px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  z-index: 599;
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.abandoned-inquiry-car-modal:hover {
  border-color: #c6cad2;
}
.abandoned-inquiry-car-modal:not(.fixed) {
  position: absolute;
  top: 165px;
}
.abandoned-inquiry-car-modal.fixed {
  position: fixed;
  top: 24px;
  transition: transform 0.3s ease;
  will-change: transform;
}
.abandoned-inquiry-car-modal .content {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
}
.abandoned-inquiry-car-modal .content .car-offer-image {
  border: 1px solid #ededed;
  border-radius: 10px 10px 10px 20px;
  flex: none;
  height: 80px;
  margin-right: 8px;
  overflow: hidden;
  width: 120px;
}
.abandoned-inquiry-car-modal .content .link .no-wrap {
  font-weight: 500;
}
.abandoned-inquiry-car-modal .button-primary {
  width: 100%;
}
.abandoned-inquiry-car-modal .close-modal {
  background-color: transparent;
  background-image: url("/assets/icon/close.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: inline-block;
  height: 2rem;
  padding: 4px;
  position: absolute;
  right: 8px;
  top: 12px;
  width: 2rem;
}

@media (min-width: 940px) {
  .abandoned-inquiry-car-modal {
    display: block;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideDown {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
@keyframes fadeInFromLeft {
  0% {
    left: -50%;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.content-header .container {
  padding-bottom: 12px;
  padding-top: 12px;
}

#cookie-policy-panel {
  border-radius: 10px;
  bottom: 6px;
  left: 6px;
  overflow-y: auto;
  padding: 24px;
  position: fixed;
  right: 6px;
  top: 6px;
  z-index: 1030;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
}
#cookie-policy-panel:hover {
  border-color: #c6cad2;
}
#cookie-policy-panel:not(.open) .buttons-container .button:nth-of-type(-n+2) {
  min-width: 290px;
}
#cookie-policy-panel .back {
  display: none;
}
#cookie-policy-panel .logo-container {
  text-align: center;
}
#cookie-policy-panel .logo-container .headline {
  margin-top: 24px;
}
#cookie-policy-panel .buttons-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
#cookie-policy-panel .buttons-container #confirm-selected-cookies {
  display: none;
}
#cookie-policy-panel .buttons-container .button {
  font-size: 14px;
  margin-top: 12px;
}
#cookie-policy-panel #cookie-policy-panel-options {
  margin-top: 24px;
}
#cookie-policy-panel #cookie-policy-panel-options .gdpr-cookies-policy-options {
  list-style: none outside;
  margin-bottom: 0;
}
#cookie-policy-panel #cookie-policy-panel-options .gdpr-cookies-policy-options li label {
  font-weight: 700;
  line-height: 1.45;
}
#cookie-policy-panel #cookie-policy-panel-options p:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 375px) {
  #cookie-policy-panel {
    top: auto;
  }
  #cookie-policy-panel:not(.open) .buttons-container .button:nth-of-type(-n+2) {
    min-width: 325px;
  }
  #cookie-policy-panel .buttons-container .button {
    font-size: initial;
  }
}
@media (max-width: 939px) {
  #cookie-policy-panel #cookie-policy-panel-options {
    display: none;
  }
  #cookie-policy-panel.open {
    border-radius: 0;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  #cookie-policy-panel.open #cookie-policy-panel-options {
    display: block;
  }
  #cookie-policy-panel.open .back {
    cursor: pointer;
    display: inline-block;
    position: absolute;
  }
  #cookie-policy-panel.open .back::before {
    background: url("/assets/icon/right-arrow-gray.svg") 0 50% transparent no-repeat;
    background-size: 85%;
    content: " ";
    display: inline-block;
    height: 1.4rem;
    margin-right: 6px;
    margin-top: -0.3rem;
    transform: rotate(180deg);
    vertical-align: middle;
    width: 1.4rem;
  }
}
@media (min-width: 940px) {
  #cookie-policy-panel {
    align-items: center;
    bottom: 12px;
    display: flex;
    left: 5%;
    padding: 72px 48px;
    right: auto;
    width: 90%;
  }
  #cookie-policy-panel .logo-container {
    width: 100%;
  }
  #cookie-policy-panel .content-container {
    border-right: 2px solid #ededed;
    margin-right: 24px;
    padding-right: 24px;
  }
  #cookie-policy-panel #cookie-policy-panel-options {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  #cookie-policy-panel.open #cookie-policy-panel-options {
    max-height: 1000px;
  }
}
@media (min-width: 1200px) {
  #cookie-policy-panel {
    left: 50%;
    max-width: 1280px;
    padding: 108px 72px;
    transform: translateX(-50%);
  }
  #cookie-policy-panel .content-container {
    margin-right: 48px;
    padding-right: 48px;
  }
}
.alert {
  border: 1px solid transparent;
  border-radius: 10px;
  margin: 12px 0;
  padding: 12px;
}
.alert.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}
.alert.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}
.alert.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}
.alert.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}
.alert .list-unstyled {
  list-style: none;
  margin: 0;
}
.alert .list-unstyled li {
  margin: 0;
}

footer {
  background-color: #000000;
  color: #4d5156;
  margin-top: auto;
  overflow: auto;
  padding-top: 24px;
}
footer a {
  color: #ffffff;
  text-decoration: none;
}
footer a:hover {
  color: #dee0e2;
  text-decoration: underline;
}
footer .driveto-symbol {
  height: auto;
  max-width: 70%;
  width: 100%;
}
footer .footer-box.subscription .menu {
  font-size: 22px;
  font-weight: 700;
  text-decoration: underline;
}
footer .help-contacts:nth-of-type(1) {
  margin-bottom: 36px;
}
footer .help-contacts .title {
  color: #ffffff;
  display: block;
  margin-bottom: 4px;
}
footer .help-contacts .phone:nth-of-type(1) {
  margin-bottom: 24px;
}
footer .help-contacts a {
  display: block;
  font-size: 22px;
  font-weight: 700;
  padding: 8px 0;
}
footer .help-contacts span {
  display: block;
  margin-top: 4px;
}
footer .section-title {
  color: #4d5156;
  font-size: 22px;
  letter-spacing: 0.15rem;
  margin-bottom: 12px;
}
footer .bar {
  padding-bottom: 24px;
  padding-top: 24px;
}
footer .footer-menu-container .footer-box .section-title {
  font-weight: 700;
}
footer .footer-menu-container .footer-box:first-child {
  display: none;
}
footer .footer-menu-container .menu {
  list-style: none;
  margin-bottom: 24px;
}
footer .footer-menu-container .menu li {
  color: #ffffff;
  padding: 0;
}
footer .copyright {
  color: #ffffff;
  text-align: center;
}

#footer-social .footer-social-links {
  margin-bottom: 24px;
  text-align: left;
}
#footer-social .footer-social-links a {
  display: inline-block;
  margin-bottom: 12px;
  white-space: nowrap;
}
#footer-social .footer-social-links a:hover {
  text-decoration: underline;
}
#footer-social .footer-social-links a:not(:last-of-type) {
  margin-right: 12px;
}
#footer-social .footer-social-links a .icon {
  background-color: #4d5156;
  border-radius: 50%;
  display: inline-block;
  height: 35px;
  margin-right: 6px;
  padding: 6px;
  width: 35px;
}
#footer-social .footer-social-links a svg {
  fill: #000000;
}

@media (min-width: 580px) {
  footer .footer-menu-container {
    display: flex;
    flex-wrap: wrap;
  }
  footer .footer-menu-container .footer-box {
    padding-right: 12px;
  }
}
@media (min-width: 580px) and (max-width: 999px) {
  footer {
    padding-top: 48px;
  }
  footer .footer-menu-container .footer-box {
    margin-left: 0;
    width: 50%;
  }
  footer .footer-menu-container .footer-box:nth-child(2n+1) {
    margin-left: 0;
  }
}
@media (min-width: 940px) {
  footer {
    padding-top: 72px;
  }
  footer .footer-menu-container .footer-box:first-child {
    display: block;
    width: 10%;
  }
  footer .footer-menu-container .footer-box:nth-child(n+2) {
    margin-left: 2%;
    width: 28%;
  }
  footer .copyright {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-left: 12%;
    margin-right: 8%;
  }
  #footer-social .footer-social-links {
    margin-bottom: 0;
  }
  #footer-social .footer-social-links a:not(:last-of-type) {
    margin-right: 48px;
  }
  #footer-social .footer-social-links a .icon {
    margin-right: 12px;
  }
}
#header {
  background-color: #ffffff;
  border-bottom: 1px solid #ededed;
  position: relative;
  z-index: 600;
}
#header #menubar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 65px;
}
#header .customer-menu-back-link {
  border-right: 2px dashed #ededed;
  color: #ff3f2d;
  display: inline-block;
  flex: none;
  margin-left: 48px;
  padding: 24px 12px;
  text-decoration: underline;
}
#header .menu-toggle {
  background-color: #ff3f2d;
  border-radius: 10px;
  cursor: pointer;
  display: block;
  line-height: 1.45;
  margin-left: auto;
  padding: 10px 8px;
  text-transform: uppercase;
}
#header .menu-toggle .bar {
  background-color: #ffffff;
  display: block;
  height: 0.3rem;
  margin-bottom: 0.5rem;
  position: relative;
  top: 0;
  width: 2.5rem;
  transition: transform 0.3s ease;
  will-change: transform;
}
#header .menu-toggle .bar:last-child {
  margin-bottom: 0;
}
#header .open-popup-checkbox:checked + .menu-toggle .bar:nth-child(2) {
  opacity: 0;
}
#header .open-popup-checkbox:checked + .menu-toggle .bar:first-child {
  top: 0.8rem;
  transform: rotate(45deg);
}
#header .open-popup-checkbox:checked + .menu-toggle .bar:last-child {
  top: -0.8rem;
  transform: rotate(-45deg);
}
#header .logo {
  display: block;
  flex: none;
  text-decoration: none;
  width: 108px;
}
#header .logo.large-screen {
  display: none;
}
#header .logo .logo-image {
  height: auto;
  max-width: 108px;
  width: 100%;
}
#header .customer-profile-label {
  background-color: #000000;
  border-radius: 24px;
  color: #ffffff;
  font-size: 85%;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 1;
  margin-top: 4px;
  padding: 2px 12px;
  text-align: center;
  text-transform: uppercase;
}
#header .compare-offers-link,
#header .favorite-offers-link {
  line-height: 1;
  position: relative;
}
#header .compare-offers-link .items-count,
#header .favorite-offers-link .items-count {
  align-items: center;
  background-color: #ff3f2d;
  border: 2px solid #ffffff;
  border-radius: 50%;
  color: #ffffff;
  content: "";
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  height: 2rem;
  justify-content: center;
  line-height: 1;
  position: absolute;
  right: -12px;
  top: -6px;
  width: 2rem;
  z-index: 5;
}
#header .compare-offers-link .tooltip-label,
#header .favorite-offers-link .tooltip-label {
  color: #3c3f44;
  margin-bottom: 12px;
  margin-right: 6px;
}
#header .compare-offers-link .compare-link,
#header .compare-offers-link .favorite-link,
#header .favorite-offers-link .compare-link,
#header .favorite-offers-link .favorite-link {
  display: inline-block;
  position: relative;
  text-decoration: none;
}
#header .compare-offers-link .compare-link.hidden,
#header .compare-offers-link .favorite-link.hidden,
#header .favorite-offers-link .compare-link.hidden,
#header .favorite-offers-link .favorite-link.hidden {
  display: none;
}
#header .compare-offers-link .compare-link::after,
#header .compare-offers-link .favorite-link::after,
#header .favorite-offers-link .compare-link::after,
#header .favorite-offers-link .favorite-link::after {
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 3rem;
  margin-top: -0.3rem;
  vertical-align: middle;
  width: 3rem;
}
#header .favorite-offers-link {
  margin-right: 6px;
}
#header .favorite-offers-link .favorite-link::after {
  background: url("/assets/icon/favorite-add.svg") 50% 50% no-repeat #ffffff;
  background-size: 65%;
}
#header .compare-offers-link .compare-link::after {
  background: url("/assets/icon/compare-add.svg") 50% 50% no-repeat #ffffff;
  background-size: 65%;
}

#contacts-menu-container {
  display: none;
}
#contacts-menu-container .contacts-menu {
  align-items: center;
  display: flex;
  margin-left: auto;
}
#contacts-menu-container .email-contact,
#contacts-menu-container .phone-contact {
  margin-right: 12px;
}
#contacts-menu-container .email-contact a,
#contacts-menu-container .phone-contact a {
  font-size: 12px;
  text-decoration: none;
}
#contacts-menu-container .phone-contact .link {
  color: #3c3f44;
}
#contacts-menu-container .phone-contact .link::before {
  background: url("/assets/icon/headset.svg") 50% 50% no-repeat transparent;
  content: " ";
  display: inline-block;
  height: 2rem;
  vertical-align: middle;
  width: 2rem;
}
#contacts-menu-container .email-contact .link {
  color: #3c3f44;
}
#contacts-menu-container .email-contact .link::before {
  background: url("/assets/icon/envelope.svg") 50% 50% no-repeat transparent;
  content: " ";
  display: inline-block;
  height: 2.3rem;
  vertical-align: middle;
  width: 2.3rem;
}

#main-menu {
  bottom: 0;
  display: none;
  left: 0;
  padding-bottom: 24px;
  position: fixed;
  right: 0;
  top: 65px;
  z-index: 700;
}
#main-menu .homepage-icon {
  display: none;
}
#main-menu .homepage-icon img {
  filter: brightness(0%);
  transition: filter 0.3s ease;
}
#main-menu .homepage-text {
  display: inline-block;
}
#main-menu .main-menu {
  background-color: #ffffff;
  clear: both;
  list-style: none;
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
#main-menu .main-menu > li {
  display: block;
  margin: 0;
}
#main-menu .main-menu > li > a {
  color: #000000;
  display: block;
  line-height: 2.3rem;
  padding: 12px 24px;
  text-decoration: none;
}
#main-menu .main-menu > li.current-menu-item > a {
  color: #ff3f2d;
}
#main-menu .main-menu > li.current-menu-item > a .homepage-icon img {
  filter: none;
}
#main-menu .main-menu > li:first-of-type:hover .homepage-icon img {
  filter: none;
}

@media (max-width: 1024px) {
  #header .main-menu-container {
    align-items: center;
    display: flex;
  }
  #header .customer-menu-back-link.large-screen {
    display: none;
  }
  #header .customer-menu-back-link.small-screen {
    border-right: 0;
    display: inline-block;
    margin-left: 12px;
  }
  #header #main-menu {
    background-color: #ededee;
    text-align: center;
    transition: visibility, opacity 0.3s;
  }
  #header #main-menu .user-menu-container {
    padding: 12px 24px;
  }
  #header .menu-toggle.open {
    border-right: 1px solid #ededed;
    padding: 8px;
  }
  #header #open-menu:checked ~ #main-menu {
    display: block;
    overflow: auto;
  }
  #header .compare-offers-link,
  #header .favorite-offers-link {
    margin: 6px 0;
  }
}
@media (min-width: 640px) {
  #main-menu .main-menu > li > a {
    padding: 12px 48px;
  }
}
@media (min-width: 1025px) {
  #header {
    background: linear-gradient(to bottom, #ededee 0%, #ededee 50%, #ffffff 50%, #ffffff 100%);
  }
  #header .customer-menu-back-link {
    padding: 24px;
  }
  #header .customer-menu-back-link.small-screen {
    display: none;
  }
  #header .logo.small-screen {
    display: none;
  }
  #header .logo.large-screen {
    display: block;
  }
  #header .logo .logo-image {
    max-width: 130px;
  }
  #header .menu-toggle {
    display: none;
  }
  #header .compare-offers-link .tooltip,
  #header .compare-offers-link .compare-link,
  #header .compare-offers-link .favorite-link,
  #header .favorite-offers-link .tooltip,
  #header .favorite-offers-link .compare-link,
  #header .favorite-offers-link .favorite-link {
    background-color: #ffffff;
    border-radius: 15px;
    height: 40px;
    line-height: 1.45;
    padding: 8px 5px;
    width: 40px;
  }
  #header .compare-offers-link .tooltip-label,
  #header .favorite-offers-link .tooltip-label {
    display: none;
  }
  #header .compare-offers-link .items-count,
  #header .favorite-offers-link .items-count {
    right: -6px;
    top: -4px;
  }
  #header .compare-offers-link {
    border-left: 0;
  }
  #main-menu {
    align-items: center;
    border: 0;
    display: flex;
    flex-wrap: wrap;
    min-height: 65px;
    padding: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 500;
  }
  #main-menu .break {
    flex-basis: 100%;
    height: 0;
    order: 3;
    width: 100%;
  }
  #main-menu #contacts-menu-container {
    align-items: center;
    display: flex;
    flex: 1;
    min-height: 71px;
    order: 1;
  }
  #main-menu .logo {
    order: 4;
  }
  #main-menu .homepage-icon {
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }
  #main-menu .homepage-text {
    display: none;
  }
  #main-menu .customer-menu-back-link {
    order: 5;
  }
  #main-menu #price-switch-container {
    margin-left: auto;
    min-width: 225px;
    order: 7;
  }
  #main-menu .user-menu-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    order: 2;
  }
  #main-menu .main-menu {
    align-items: flex-end;
    box-shadow: none;
    display: flex;
    flex: 1;
    margin: 0;
    min-width: 400px;
    order: 6;
    padding-left: 24px;
  }
  #main-menu .main-menu > li {
    position: relative;
    white-space: nowrap;
  }
  #main-menu .main-menu > li > a {
    display: inline-block;
    margin-bottom: 0;
    padding: 24px 12px;
    text-decoration: none;
    transition: color 0.3s ease, background 0.3s ease;
  }
  #main-menu .main-menu > li > a:hover {
    color: #ff3f2d;
  }
  #main-menu .main-menu > li.current-menu-item > a {
    position: relative;
  }
  #main-menu .main-menu > li.current-menu-item > a::after {
    background-color: #ff3f2d;
    border-radius: 2px;
    bottom: -2px;
    content: "";
    display: inline-block;
    height: 3px;
    left: 12px;
    position: absolute;
    right: 12px;
  }
}
@media (min-width: 1180px) {
  #main-menu .main-menu {
    padding-left: 48px;
  }
  #main-menu .main-menu > li {
    position: relative;
    white-space: nowrap;
  }
  #main-menu .main-menu > li > a {
    padding: 24px;
  }
  #main-menu .main-menu > li.current-menu-item > a::after {
    left: 24px;
    right: 24px;
  }
}
@media (min-width: 375px) {
  #header .customer-menu-back-link::before {
    background: url("/assets/icon/how-it-works-right-arrow.svg") 0 50% transparent no-repeat;
    background-size: 100%;
    content: " ";
    display: inline-block;
    height: 1.4rem;
    margin-right: 6px;
    margin-top: -0.3rem;
    transform: rotate(180deg);
    vertical-align: middle;
    width: 1.4rem;
  }
}
a {
  background-color: transparent;
  color: #ff3f2d;
  outline: none;
  transition: color 0.15s ease;
}
a:hover {
  color: #b52c17;
}
a.inverse {
  color: #ffffff;
}
a.inverse:hover {
  color: #dee0e2;
}

ul {
  list-style: circle inside;
}

ol {
  list-style: decimal inside;
}

ol,
ul {
  line-height: 1.7;
  margin-top: 0;
  padding-left: 0;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin: 1.5rem 0 1.5rem 3rem;
}

li {
  margin-bottom: 1rem;
}

#login-info-box {
  margin: 24px 0;
  text-align: center;
}
#login-info-box .login-link {
  box-shadow: none;
  display: inline-block;
  margin-top: 12px;
  max-width: 370px;
  text-decoration: none;
  width: 100%;
}
#login-info-box .login-link::before {
  background-color: transparent;
  background-image: url("/assets/icon/user-white.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  content: " ";
  display: inline-block;
  height: 2rem;
  margin-right: 6px;
  vertical-align: middle;
  width: 2rem;
}
#login-info-box .login-link .user-login-label {
  line-height: 2;
}
#login-info-box > .user-menu-dropdown {
  height: 100%;
  margin: 0 auto;
  max-width: 600px;
  position: relative;
}
#login-info-box > .user-menu-dropdown #user-menu {
  display: none;
}
#login-info-box > .user-menu-dropdown #user-menu-dropdown-label-container {
  display: none;
}
#login-info-box > .user-menu-dropdown #user-menu-dropdown-label-container .logged-in-label {
  display: none;
}
#login-info-box > .user-menu-dropdown #user-menu-dropdown-label-container .logged-in-label::before {
  background-color: transparent;
  background-image: url("/assets/icon/user-white.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 80%;
  content: " ";
  display: inline-block;
  height: 2rem;
  margin-right: 6px;
  margin-top: -0.3rem;
  vertical-align: middle;
  width: 2rem;
}
#login-info-box > .user-menu-dropdown .user-menu {
  border-radius: 10px;
  display: block;
  left: 0;
  list-style: none;
  margin-bottom: 0;
  margin-top: 12px;
  min-width: 270px;
  padding: 12px 12px 4px;
  position: relative;
  right: -1px;
  text-align: left;
  top: 100%;
  z-index: 601;
  background-color: #ffffff;
  border: 1px solid #ededed;
}
#login-info-box > .user-menu-dropdown .user-menu .title {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}
#login-info-box > .user-menu-dropdown .user-menu .username-info {
  color: #000000;
}
#login-info-box > .user-menu-dropdown .user-menu .username-info .username {
  display: block;
  margin-bottom: 8px;
  text-align: center;
  white-space: nowrap;
}
#login-info-box > .user-menu-dropdown .user-menu .username-info .button-primary {
  display: block;
}
#login-info-box > .user-menu-dropdown .user-menu .logout {
  border-top: 1px solid #ededed;
  margin-top: 12px;
  padding-top: 12px;
}
#login-info-box > .user-menu-dropdown .user-menu .logout .button {
  width: 100%;
}
#login-info-box > .user-menu-dropdown .user-menu .deal-list {
  margin-top: 24px;
}
#login-info-box > .user-menu-dropdown input[type=checkbox] + #user-menu-dropdown-label-container::after, #login-info-box > .user-menu-dropdown input[type=checkbox] + #user-menu-dropdown-label-container::before {
  display: none;
}
#login-info-box > .user-menu-dropdown input[type=checkbox]:checked ~ .user-menu {
  display: block;
  margin-top: 10px;
  animation: fadeIn 0.3s ease-out;
  will-change: opacity;
}
#login-info-box > .user-menu-dropdown input[type=checkbox]:checked + #user-menu-dropdown-label-container {
  color: #3c3f44;
}

@media (min-width: 1025px) {
  #login-info-box {
    background-color: #ff3f2d;
    border-radius: 15px;
    margin-bottom: 0;
    margin-left: 12px;
    margin-top: 0;
  }
  #login-info-box #user-menu-dropdown-label-container {
    cursor: pointer;
  }
  #login-info-box .login-link {
    background: none;
    border: 0;
    color: #ff3f2d;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    margin-top: 0;
    min-height: 40px;
    text-transform: none;
    vertical-align: middle;
  }
  #login-info-box .login-link .large-screen-hidden {
    display: none;
  }
  #login-info-box .login-link::before {
    margin-right: 0;
  }
  #login-info-box > .user-menu-dropdown .user-menu {
    display: none;
    left: auto;
    margin-top: 0;
    padding: 24px 24px 12px;
    position: absolute;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
  #login-info-box > .user-menu-dropdown .user-menu .username-info {
    align-items: center;
    display: flex;
  }
  #login-info-box > .user-menu-dropdown .user-menu .username-info .username {
    margin-bottom: 0;
    width: 100%;
  }
  #login-info-box > .user-menu-dropdown .user-menu .username-info .button-primary {
    margin-left: 12px;
    margin-top: 0;
  }
  #login-info-box > .user-menu-dropdown #user-menu-dropdown-label-container {
    display: block;
  }
  #login-info-box > .user-menu-dropdown #user-menu-dropdown-label-container .logged-in-label {
    color: #ffffff;
    display: inline-block;
    padding: 10px 12px;
    white-space: nowrap;
  }
  #login-info-box > .user-menu-dropdown #user-menu-dropdown-label-container .label {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.main-menu-phone-request-container,
.main-menu-email-request-container {
  padding: 12px;
}
.main-menu-phone-request-container .form-container,
.main-menu-email-request-container .form-container {
  margin-top: 24px;
}
.main-menu-phone-request-container .form-container .form-flex-row,
.main-menu-email-request-container .form-container .form-flex-row {
  align-items: flex-start;
  flex-wrap: nowrap;
}
.main-menu-phone-request-container .form-container .form-row.button-row,
.main-menu-email-request-container .form-container .form-row.button-row {
  text-align: center;
}
.main-menu-phone-request-container .form-container .inner-addon,
.main-menu-email-request-container .form-container .inner-addon {
  display: flex;
  flex-direction: column;
  position: relative;
}
.main-menu-phone-request-container .form-container .inner-addon.left-addon input,
.main-menu-email-request-container .form-container .inner-addon.left-addon input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-left: 6rem;
}
.main-menu-phone-request-container .form-container .inner-addon .phone-prefix,
.main-menu-email-request-container .form-container .inner-addon .phone-prefix {
  align-items: center;
  border-right: 1px solid #ededed;
  color: #c6ccd7;
  display: flex;
  font-weight: 500;
  height: 39px;
  padding: 0 0.8rem;
  position: absolute;
  top: 1px;
}
@media (min-width: 940px) {
  .main-menu-phone-request-container .form-container .inner-addon .phone-prefix,
  .main-menu-email-request-container .form-container .inner-addon .phone-prefix {
    height: 36px;
  }
}
.main-menu-phone-request-container .form-container .button-primary,
.main-menu-email-request-container .form-container .button-primary {
  min-width: 125px;
}
.main-menu-phone-request-container .form-container .button-primary.main-menu-phone-save,
.main-menu-email-request-container .form-container .button-primary.main-menu-phone-save {
  border: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 9.5px 12px;
}
.main-menu-phone-request-container p,
.main-menu-email-request-container p {
  text-align: center;
}
.main-menu-phone-request-container .response-message,
.main-menu-email-request-container .response-message {
  font-size: 2rem;
  font-weight: 500;
  margin: 48px 0;
  text-align: center;
}
.main-menu-phone-request-container .response-message.success,
.main-menu-email-request-container .response-message.success {
  color: #2dbd58;
}
.main-menu-phone-request-container .response-message.error,
.main-menu-email-request-container .response-message.error {
  color: #ec5340;
}
.main-menu-phone-request-container .opening-hours,
.main-menu-email-request-container .opening-hours {
  align-items: center;
  display: flex;
  margin-top: 24px;
}
.main-menu-phone-request-container .opening-hours .link,
.main-menu-email-request-container .opening-hours .link {
  font-weight: 500;
}
.main-menu-phone-request-container .opening-hours .hours,
.main-menu-email-request-container .opening-hours .hours {
  font-weight: 500;
}
.main-menu-phone-request-container .opening-hours::before,
.main-menu-email-request-container .opening-hours::before {
  background-color: #ffffff;
  background-image: url("/assets/icon/opening-hours.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 70%;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  flex: none;
  height: 4rem;
  margin-right: 12px;
  width: 4rem;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}

@media (min-width: 540px) {
  .main-menu-phone-request-container,
  .main-menu-email-request-container {
    padding: 24px;
  }
}
@media (min-width: 940px) {
  #main-menu-email-modal .modal-content,
  #main-menu-phone-modal .modal-content {
    bottom: auto;
    max-height: 85vh;
  }
  .main-menu-phone-request-container,
  .main-menu-email-request-container {
    border-radius: 10px;
    margin-bottom: 24px;
    padding: 48px;
    background-color: #ffffff;
    border: 1px solid #ededed;
    transition: border-color 0.3s ease;
  }
  .main-menu-phone-request-container:hover,
  .main-menu-email-request-container:hover {
    border-color: #c6cad2;
  }
  .main-menu-phone-request-container .form-container,
  .main-menu-email-request-container .form-container {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .main-menu-phone-request-container .form-container.email-form,
  .main-menu-email-request-container .form-container.email-form {
    flex-direction: column;
  }
  .main-menu-phone-request-container .form-container.email-form .form-row,
  .main-menu-email-request-container .form-container.email-form .form-row {
    min-width: 400px;
  }
  .main-menu-phone-request-container .form-container .button-primary,
  .main-menu-email-request-container .form-container .button-primary {
    padding: 6px 24px;
  }
  .main-menu-phone-request-container .opening-hours,
  .main-menu-email-request-container .opening-hours {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
.modal {
  -webkit-overflow-scrolling: touch;
  background-color: #ffffff;
  bottom: 0;
  left: -100%;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  will-change: left;
  z-index: 610;
  transition: left 0.3s ease;
  z-index: 1020;
}
.modal.open {
  left: 0;
}

.modal-content {
  background-color: #ffffff;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: hidden;
  padding: 12px;
  position: absolute;
  right: 0;
  top: 0;
}
.modal-content .close-modal {
  display: inline-block;
  margin-bottom: 12px;
}
.modal-content .close-modal img {
  display: none;
}
.modal-content .modal-header {
  flex: none;
  padding-bottom: 12px;
  padding-top: 12px;
}
.modal-content .modal-header .modal-headline {
  text-align: center;
  font-size: 24px;
}
@media (min-width: 640px) {
  .modal-content .modal-header .modal-headline {
    font-size: 32px;
  }
}
.modal-content .modal-body {
  margin-right: -12px;
  overflow-y: auto;
  padding-right: 12px;
}
.modal-content .modal-footer {
  background-color: #ffffff;
  display: block;
  margin-bottom: -18px;
  padding: 12px;
  position: relative;
  text-align: center;
}
.modal-content .modal-footer::before {
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  bottom: 100%;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  right: 4px;
  z-index: 10;
}
.modal-content .modal-footer .modal-footer-scroll {
  color: #ff3f2d;
}
.modal-content .modal-footer .modal-footer-scroll::before {
  animation: jump 1.5s ease 0s infinite normal;
  background: url("/assets/icon/arrow-up.svg") 0 50% no-repeat transparent;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 1.3rem;
  margin-right: 6px;
  vertical-align: middle;
  width: 1.3rem;
}

@media (max-width: 939px) {
  .modal {
    transition: left 0.3s ease;
  }
}
@media (min-width: 940px) {
  .modal {
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
    height: 100%;
    left: 0;
    overflow: auto;
    width: 100%;
    animation: fadeIn 0.3s ease-out;
    will-change: opacity;
  }
  .modal.open {
    display: block;
  }
  .modal-content {
    border-radius: 10px;
    bottom: 48px;
    margin: 0 auto;
    max-width: 940px;
    padding: 48px 42px 36px 48px;
    top: 48px;
    width: 80%;
  }
  .modal-content .close-modal {
    cursor: pointer;
    padding: 24px;
    position: absolute;
    right: 4px;
    top: 0;
  }
  .modal-content .close-modal:hover img {
    transform: rotate(90deg);
  }
  .modal-content .close-modal img {
    display: block;
    width: 24px;
    transition: transform 0.3s ease;
    will-change: transform;
  }
  .modal-content .close-modal span {
    display: none;
  }
  .modal-content .modal-header {
    min-height: 40px;
    padding-top: 0;
  }
  .modal-content .modal-body {
    padding-right: 18px;
  }
}
@keyframes jump {
  0% {
    transform: translateY(0) rotate(180deg);
  }
  25% {
    transform: translateY(-2px) rotate(180deg);
  }
  50% {
    transform: translateY(0) rotate(180deg);
  }
  75% {
    transform: translateY(2px) rotate(180deg);
  }
  100% {
    transform: translateY(0) rotate(180deg);
  }
}
#price-switch-container {
  padding: 24px 0;
}
#price-switch-container .price-switch-label {
  align-items: center;
  color: #3c3f44;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  justify-content: center;
  padding: 3px 0;
}
#price-switch-container .switch {
  cursor: pointer;
  display: inline-block;
  height: 30px;
  margin-right: 6px;
  position: relative;
  width: 55px;
}
#price-switch-container .switch .slider {
  background-color: #c6cad2;
  border-radius: 30px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s;
}
#price-switch-container .switch .slider::before {
  background-color: #ffffff;
  border-radius: 50%;
  bottom: 3px;
  content: "";
  height: 24px;
  left: 5px;
  position: absolute;
  transition: 0.3s;
  width: 24px;
}
#price-switch-container .switch .slider::after {
  background: url("/assets/icon/check-ok-orange.svg") 0 50% no-repeat transparent;
  background-size: 100%;
  bottom: 3px;
  content: "";
  filter: grayscale(1);
  height: 24px;
  left: 5px;
  opacity: 0.3;
  position: absolute;
  transition: 0.3s;
  width: 24px;
}
#price-switch-container .switch input {
  display: none;
}
#price-switch-container .switch input:checked + .slider {
  background-color: #ff3f2d;
}
#price-switch-container .switch input:checked + .slider::before {
  transform: translateX(22px);
}
#price-switch-container .switch input:checked + .slider::after {
  filter: none;
  opacity: 1;
  transform: translateX(22px);
}

@media (min-width: 1025px) {
  #price-switch-container {
    flex: none;
    padding: 0;
  }
  #price-switch-container .switch .slider {
    background-color: #ededee;
  }
}
.small-screen-menu-container {
  background-color: #ffffff;
  border-top: 2px solid #ededed;
  position: relative;
}
.small-screen-menu-container .menu {
  list-style: none;
  margin: 0;
}
.small-screen-menu-container .menu > li {
  display: inline-block;
  margin: 0;
  white-space: nowrap;
}
.small-screen-menu-container .menu > li > a {
  color: #000000;
  display: inline-block;
  padding: 8px 6px;
  position: relative;
  text-decoration: none;
  transition: color 0.3s ease;
}
.small-screen-menu-container .menu > li > a .homepage-icon {
  filter: brightness(0%);
  margin-top: -0.3rem;
}
.small-screen-menu-container .menu > li > a:focus, .small-screen-menu-container .menu > li > a:hover {
  color: #ff3f2d;
}
.small-screen-menu-container .menu > li.current-menu-item > a {
  color: #ff3f2d;
}
.small-screen-menu-container .menu > li.current-menu-item > a::after {
  border-bottom: 3px solid #ff3f2d;
  border-radius: 2px;
  bottom: -2px;
  content: "";
  display: inline-block;
  left: 6px;
  position: absolute;
  right: 6px;
  z-index: 601;
}
.small-screen-menu-container .menu > li.current-menu-item > a .homepage-icon {
  filter: none;
}
.small-screen-menu-container .small-device-hidden {
  display: none;
}

@media (min-width: 375px) {
  .small-screen-menu-container .container {
    padding: 0;
  }
  .small-screen-menu-container .small-device-hidden {
    display: inline;
  }
  .small-screen-menu-container .menu > li > a {
    padding: 8px 12px 6px;
  }
  .small-screen-menu-container .menu > li.current-menu-item > a::after {
    left: 12px;
    right: 12px;
  }
}
@media (min-width: 1025px) {
  .small-screen-menu-container {
    display: none;
  }
}
html.displayWithoutVat .withVat {
  display: none;
}
html.displayWithVat .withoutVat {
  display: none;
}

.car-info-container:not(:last-of-type) {
  margin-bottom: 24px;
}
.car-info-container .car-info-description {
  border: 1px solid #ededed;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  padding: 24px;
}
.car-info-container .car-info-description::before {
  background: url("/assets/icon/user/info-admin.svg") 50% 50% no-repeat transparent;
  background-size: 100%;
  content: " ";
  display: block;
  flex: none;
  height: 2rem;
  margin-right: 8px;
  width: 2rem;
}
.car-info-container .hidden-content .car-offer-info:last-of-type {
  margin-bottom: 24px;
}
.car-info-container .car-offer-info {
  align-items: flex-start;
  border: 1px solid #ededed;
  border-radius: 20px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
}
.car-info-container .car-offer-info:not(:last-of-type) {
  margin-bottom: 24px;
}
.car-info-container .car-offer-info .subtitle {
  display: block;
  font-size: 14px;
  font-weight: 400;
  font-family: DMSans, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.car-info-container .car-offer-info .car-offer-image {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  width: 100%;
}
.car-info-container .car-offer-info .car-offer-image .car-image {
  border-radius: inherit;
  height: 100%;
  min-width: 80px;
  object-fit: cover;
  width: 100%;
}
.car-info-container .car-offer-info .car-info {
  padding: 12px;
  width: 100%;
}
.car-info-container .car-offer-info .car-info .claim-label .tooltip {
  text-align: right;
}
.car-info-container .car-offer-info .car-info .licence-plate-container {
  color: #000000;
  font-size: 85%;
  margin-top: 12px;
}
.car-info-container .car-offer-info .car-info .licence-plate-container .licence-plate {
  font-weight: 700;
  letter-spacing: 1px;
}
.car-info-container .car-offer-info .car-info .inquiry-for-container {
  color: #000000;
}
.car-info-container .car-offer-info.inquired-car .price-box {
  margin-bottom: 12px;
}
.car-info-container .car-offer-info.inquired-car .price-box:nth-of-type(1) {
  background-color: #e0f5e6;
}
.car-info-container .car-offer-info.inquired-car .price-box:nth-of-type(2) {
  background-color: #ededee;
}
.car-info-container .car-offer-info.subscribed-car {
  background-color: #ededee;
}
.car-info-container .car-offer-info.subscribed-car .prices-container {
  background-color: #ffffff;
  border-radius: 20px;
}
.car-info-container .car-offer-info.subscribed-car .price-box {
  border-radius: 0;
}
.car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(1) {
  border-bottom: 1px dashed #ededed;
  position: relative;
}
.car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(1) .payment-label,
.car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(1) .claim-label {
  margin-top: 6px;
}
.car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(2) {
  text-align: center;
}
.car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(2) .payment-label,
.car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(2) .claim-label {
  display: none;
}
.car-info-container .prices-container {
  margin-bottom: 12px;
  margin-top: 12px;
}
.car-info-container .price-box {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
}
.car-info-container .price-box:nth-of-type(1) .price:nth-of-type(1) {
  color: #2dbd58;
}
.car-info-container .price-box:nth-of-type(1) .price:nth-of-type(1) .vat-info {
  font-size: 14px;
}
.car-info-container .price-box:nth-of-type(2) .price:nth-of-type(1) {
  color: #000000;
}
.car-info-container .price-box .price-title {
  color: #000000;
  margin-bottom: 6px;
}
.car-info-container .price-box .price-offer-price {
  white-space: nowrap;
}
.car-info-container .price-box .price-offer-price .vat-info {
  color: #3c3f44;
  font-size: 14px;
  font-weight: 400;
}
.car-info-container .price-box .price-offer-price .price:nth-of-type(1) {
  font-size: 18px;
  font-weight: 700;
}
.car-info-container .price-box .price-offer-price .price:nth-of-type(2) {
  font-size: 12px;
}
.car-info-container .price-box .price-offer-price .price:nth-of-type(2) .vat-info {
  font-size: 12px;
}
.car-info-container .button-container .button {
  width: 100%;
}
.car-info-container .button-container .button:nth-of-type(1) {
  margin-bottom: 6px;
}
.car-info-container .car-offer-info-footer {
  border-top: 1px dashed #ededed;
  font-size: 85%;
  padding-top: 12px;
  text-align: center;
}

@media (min-width: 375px) {
  .car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(1) .payment-label {
    margin-top: 0;
    position: absolute;
    right: 12px;
    top: 36px;
  }
}
@media (min-width: 390px) {
  .car-info-container .car-offer-info.inquired-car .prices-container {
    display: flex;
  }
  .car-info-container .car-offer-info.inquired-car .price-box {
    margin-bottom: 0;
    margin-left: 2%;
    width: 49%;
  }
  .car-info-container .car-offer-info.inquired-car .price-box:nth-child(2n+1) {
    margin-left: 0;
  }
}
@media (min-width: 940px) {
  .car-info-container .car-offer-info {
    padding: 24px;
  }
  .car-info-container .car-offer-info .car-info {
    margin-left: 3%;
    padding: 0;
    width: 54%;
  }
  .car-info-container .car-offer-info .car-info .licence-plate-container {
    font-size: 14px;
  }
  .car-info-container .car-offer-info .car-offer-image {
    border-radius: 20px;
    height: 255px;
    width: 43%;
  }
  .car-info-container .car-offer-info .services-container {
    order: 4;
    padding: 24px;
  }
  .car-info-container .car-offer-info .services-container.deal-parameters {
    margin-top: 48px;
  }
  .car-info-container .car-offer-info .services-container .services {
    column-count: 2;
    font-size: 14px;
  }
  .car-info-container .car-offer-info.subscribed-car .prices-container {
    display: flex;
  }
  .car-info-container .car-offer-info.subscribed-car .price-box {
    margin-left: 0;
    width: 50%;
  }
  .car-info-container .car-offer-info.subscribed-car .price-box:nth-child(2n+1) {
    margin-left: 0;
  }
  .car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(1) {
    border-bottom: 0;
    border-right: 2px dashed #ededed;
    text-align: left;
  }
  .car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(1) .payment-label,
  .car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(1) .claim-label {
    display: none;
  }
  .car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(2) {
    align-items: flex-end;
    text-align: right;
  }
  .car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(2) .payment-label,
  .car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(2) .claim-label {
    display: flex;
  }
  .car-info-container .price-box {
    padding: 24px;
  }
  .car-info-container .price-box .price-offer-price .price:nth-of-type(1) {
    font-size: 24px;
  }
  .car-info-container .price-box .price-offer-price .price:nth-of-type(2) {
    font-size: 14px;
  }
  .car-info-container .price-box .price-offer-price .price:nth-of-type(2) .vat-info {
    font-size: 14px;
  }
  .car-info-container .button-container {
    display: flex;
  }
  .car-info-container .button-container .button {
    width: auto;
  }
  .car-info-container .button-container .button:nth-of-type(1) {
    margin-bottom: 0;
  }
  .car-info-container .button-container .button:nth-of-type(2) {
    margin-left: 12px;
  }
  .car-info-container .car-offer-info-footer {
    border-top: 0;
    font-size: 14px;
    text-align: left;
  }
}
.user-profile-no-content {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 20px;
  margin-bottom: 24px;
  margin-top: 48px;
  padding: 24px;
  position: relative;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
}
.user-profile-no-content .title {
  margin-right: 72px;
}
.user-profile-no-content .button-primary {
  white-space: normal;
}
.user-profile-no-content .icon {
  border-radius: 50%;
  height: 4rem;
  padding: 8px;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 4rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

@media (min-width: 940px) {
  .user-profile-no-content.no-inquiries {
    margin-top: 72px;
  }
}
.header-link-only {
  margin-top: 12px;
}
.header-link-only > a {
  display: none;
  font-size: 14px;
}

ol.breadcrumb {
  color: #3c3f44;
  font-size: 85%;
  list-style: none;
  margin-bottom: 0;
  overflow: auto;
  padding-bottom: 12px;
  position: relative;
  white-space: nowrap;
}
ol.breadcrumb li {
  display: inline-block;
  margin-bottom: 0;
}
ol.breadcrumb li .separator {
  display: inline-block;
  padding: 0 4px;
}
ol.breadcrumb li a {
  color: #3c3f44;
}
ol.breadcrumb li:last-child a {
  pointer-events: none;
  text-decoration: none;
}
@media (max-width: 470px) {
  ol.breadcrumb li:not(:last-child) a {
    display: inline-block;
    max-width: 120px;
    vertical-align: bottom;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.header-breadcrumbs-only {
  padding-bottom: 12px;
  padding-top: 12px;
}

@media (min-width: 940px) {
  .header-link-only > a {
    display: block;
  }
  ol.breadcrumb {
    padding-bottom: 0;
  }
}
.favorite-offer-label {
  background-color: #ededee;
  border-radius: 50%;
  height: 35px;
  margin: 6px 6px 4px;
  padding: 8px 6px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 35px;
}
.favorite-offer-label::after {
  content: url("/assets/icon/favorite-add.svg");
  display: inline-block;
  line-height: 1;
}

.labels {
  left: 12px;
  position: absolute;
  top: 12px;
  z-index: 2;
}
.labels .label {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 15px;
  clear: left;
  color: #000000;
  display: inline-block;
  float: left;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 3px;
  padding: 3px 6px;
  white-space: nowrap;
}
.labels .label--winter-tires {
  align-items: center;
  color: #ff3f2d;
  display: flex;
}
.labels .label--winter-tires::before {
  background-image: url("/assets/icon/tyre.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 13px;
  margin-right: 3px;
  width: 13px;
}
.labels .label--on-stock {
  background-color: #2dbd58;
  border-color: #2dbd58;
  color: #ffffff;
}
.labels .label--available-sooner {
  background-color: #fedd24;
  border-color: #fedd24;
  color: #3c3f44;
}

.extra-labels {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 12px;
}
.extra-labels .label {
  align-items: center;
  background-color: #2dbd58;
  border: 1px solid #2dbd58;
  border-radius: 15px;
  color: #ffffff;
  display: flex;
  font-size: 12px;
  line-height: 1.2;
  margin-bottom: 6px;
  margin-right: 0;
  padding: 3px 6px;
  white-space: nowrap;
}
.extra-labels .label::before {
  background-image: url("/assets/icon/badge.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 13px;
  margin-right: 3px;
  width: 13px;
}
.extra-labels--row {
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 6px;
}
.extra-labels--row .label {
  margin-right: 3px;
}

.tooltip.tooltip-active {
  cursor: pointer;
  position: relative;
}
.tooltip.tooltip-active::after {
  background: url("/assets/icon/question-mark-gray.svg") 50% 50% no-repeat;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 1.6rem;
  margin-top: -0.3rem;
  vertical-align: middle;
  width: 1.6rem;
}
.tooltip.tooltip-active::before {
  background-image: url("/assets/icon/close.svg");
  background-position: right 12px top 12px;
  background-repeat: no-repeat;
  background-size: 2rem;
  bottom: 0;
  color: #000000;
  content: attr(data-title);
  cursor: pointer;
  display: block;
  font-weight: 400;
  left: 0;
  line-height: 1.45;
  padding: 48px 24px;
  position: fixed;
  text-decoration: none;
  text-transform: none;
  transform: translateY(100%);
  white-space: pre-wrap;
  width: 100%;
  z-index: 1020;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: transform 0.3s ease;
  will-change: transform;
}
.tooltip.tooltip-active.tooltip-open::before {
  transform: translateY(0);
}

@media (min-width: 640px) {
  .tooltip.tooltip-active::before {
    border-radius: 10px;
    bottom: auto;
    display: none;
    left: 50%;
    max-width: 400px;
    min-width: 200px;
    padding: 36px 24px;
    position: absolute;
    right: auto;
    top: 24px;
    transform: translateX(-50%) translateY(0);
    transition: none;
    width: max-content;
  }
  .tooltip.tooltip-active.tooltip-open::before {
    display: block;
    transform: translateX(-50%);
  }
  .tooltip.tooltip-active.tooltip-open.right::before {
    left: auto;
    right: 0;
    transform: translateX(15%);
  }
  .tooltip.tooltip-active.tooltip-open.left::before {
    left: 0;
    right: auto;
    transform: translateX(-85%);
  }
}
@media (max-width: 639px) {
  body.tooltip-open {
    position: relative;
  }
  body.tooltip-open::before {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 700;
  }
  body.tooltip-open #main-menu::before {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 700;
  }
}
@media (min-width: 940px) {
  .tooltip.tooltip-active::before {
    z-index: 598;
  }
}
.newsletter-subscription-container {
  background-color: #ededee;
  margin-top: 48px;
}
.newsletter-subscription-container:not(.discount) {
  border-radius: 20px;
  display: block;
  padding: 24px;
  text-align: center;
  width: 100%;
}
.newsletter-subscription-container:not(.discount):not(.active) {
  pointer-events: none;
  position: relative;
}
.newsletter-subscription-container:not(.discount):not(.active)::before {
  background-color: #ffffff;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.newsletter-subscription-container:not(.discount) .icon {
  align-items: center;
  background-color: #dce0e7;
  border-radius: 50%;
  display: flex;
  flex: none;
  height: 130px;
  justify-content: center;
  margin: 0 auto 12px;
  position: relative;
  width: 130px;
}
.newsletter-subscription-container:not(.discount) .icon::before {
  background-color: transparent;
  background-image: url("/assets/icon/newsletter-paper-plane-icon.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 4rem;
  position: absolute;
  right: -6px;
  top: 24px;
  width: 4rem;
}
.newsletter-subscription-container:not(.discount).subscription-listing {
  margin-bottom: 48px;
  margin-top: 0;
}
.newsletter-subscription-container:not(.discount) .form .form-group {
  margin-bottom: 0;
  position: relative;
}
.newsletter-subscription-container:not(.discount) .form .form-group:nth-child(1) {
  width: 100%;
}
.newsletter-subscription-container:not(.discount) .form .form-group .button-primary {
  width: 100%;
}
.newsletter-subscription-container:not(.discount) .form .send-button-label {
  color: #000000;
  margin-bottom: 6px;
  margin-top: 24px;
  min-width: 245px;
  text-align: center;
}
.newsletter-subscription-container:not(.discount) .form .send-button-label::before {
  background-color: transparent;
  background-image: url("/assets/icon/check-ok.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 1.8rem;
  margin-right: 4px;
  margin-top: -0.3rem;
  vertical-align: middle;
  width: 1.8rem;
}
.newsletter-subscription-container:not(.discount) input {
  padding: 12px;
}
.newsletter-subscription-container:not(.discount) .response-icon {
  display: block;
  margin-right: 6px;
  width: 50px;
}
.newsletter-subscription-container:not(.discount) .response-icon .circle {
  animation: dash 1s ease-in-out;
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.newsletter-subscription-container:not(.discount) .response-icon .symbol {
  animation: dash-check 1s 0.5s ease-in-out forwards;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}
.newsletter-subscription-container.discount {
  border-radius: 10px;
  bottom: 12px;
  display: none;
  left: 12px;
  max-width: 420px;
  padding: 24px;
  position: fixed;
  right: 12px;
  text-align: center;
  z-index: 610;
  background-color: #ededee;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.newsletter-subscription-container.discount:hover {
  border-color: #c6cad2;
}
.newsletter-subscription-container.discount .close-popup {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
}
.newsletter-subscription-container.discount .close-popup .close-icon {
  height: auto;
  width: 2.5rem;
}
.newsletter-subscription-container.discount .form .form-group {
  margin-bottom: 0;
  position: relative;
}
.newsletter-subscription-container.discount .form .form-group label {
  line-height: 1.45;
  margin-bottom: 12px;
}
.newsletter-subscription-container.discount .form .form-group .button-primary {
  margin-top: 12px;
}
.newsletter-subscription-container.discount input {
  padding: 12px;
}
.newsletter-subscription-container.discount .headline {
  padding: 0 24px;
}
.newsletter-subscription-container.discount .response-icon {
  display: block;
  margin: 0 auto 6px;
  width: 50px;
}
.newsletter-subscription-container.discount .response-icon .circle {
  animation: dash 1s ease-in-out;
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.newsletter-subscription-container.discount .response-icon .symbol {
  animation: dash-check 1s 0.5s ease-in-out forwards;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}
.newsletter-subscription-container.complete {
  align-items: center;
  display: flex;
  justify-content: center;
}
.newsletter-subscription-container .error-message {
  color: #ec5340;
  margin-top: 12px;
}
.newsletter-subscription-container .error-message::before {
  background: url("/assets/icon/cross-no.svg") 0 50% no-repeat transparent;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 2rem;
  margin-right: 6px;
  margin-top: -0.3rem;
  text-decoration: none;
  vertical-align: middle;
  width: 2rem;
}

@media (min-width: 640px) {
  .newsletter-subscription-container.discount {
    bottom: 24px;
    left: auto;
    right: 24px;
  }
}
@media (min-width: 940px) {
  .newsletter-subscription-container {
    margin-top: 72px;
  }
  .newsletter-subscription-container:not(.discount) {
    align-items: center;
    border-radius: 20px 20px 60px;
    display: flex;
    padding: 72px;
    text-align: left;
  }
  .newsletter-subscription-container:not(.discount) .icon {
    margin-bottom: 0;
  }
  .newsletter-subscription-container:not(.discount) .content {
    margin-left: 36px;
    width: 100%;
  }
  .newsletter-subscription-container:not(.discount) .form {
    align-items: flex-end;
    display: flex;
  }
  .newsletter-subscription-container:not(.discount) .form .send-button-label {
    margin-top: 0;
  }
  .newsletter-subscription-container:not(.discount) .form .form-group:nth-child(2) {
    margin-left: 12px;
  }
  .newsletter-subscription-container:not(.discount) .response-icon {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 12px;
    width: 60px;
  }
  .newsletter-subscription-container:not(.discount).subscription-listing {
    margin-bottom: 72px;
    margin-top: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
:root {
  --slide-spacing: 12px;
  --slide-gap: 2%;
  --slide-small-screen-size: 95%;
  --slide-middle-screen-size: 49%;
  --slide-large-screen-size: 23.5%;
}

.embla {
  position: relative;
}
.embla .embla-viewport {
  overflow: hidden;
}
.embla .embla-viewport:not(.embla .embla-viewport.embla-active) .embla-container .embla-slide {
  overflow: visible;
}
.embla .embla-viewport.embla-active .embla-container .embla-slide {
  overflow: hidden;
}
.embla .embla-container {
  backface-visibility: hidden;
  display: flex;
  gap: var(--slide-gap);
  touch-action: pan-y;
}
.embla .embla-container .embla-slide {
  flex: 0 0 var(--slide-small-screen-size);
  overflow: hidden;
  position: relative;
}
.embla .embla-buttons .embla-button {
  cursor: pointer;
  display: inline-block;
}
.embla .embla-buttons .embla-button.disabled {
  opacity: 0.5;
}
.embla .embla-buttons .embla-button::after {
  background: url("/assets/icon/right-arrow-orange.svg") 50% 50% no-repeat #ffffff;
  background-size: 35%;
  border: 2px solid #ededed;
  border-radius: 15px;
  content: " ";
  display: inline-block;
  height: 4rem;
  width: 4rem;
  transition: transform 0.3s ease;
  will-change: transform;
}
.embla .embla-buttons .embla-button.embla-button-prev::after {
  transform: rotate(180deg);
}
.embla .embla-dots {
  margin: 12px auto 0;
  min-height: 21px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  width: 80px;
}
.embla .embla-dots:empty {
  display: none;
}
.embla .embla-dots .embla-dot {
  background-color: #ededee;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 10px;
  margin: 0 3px;
  position: relative;
  width: 10px;
  transition: left 0.3s ease;
}
.embla .embla-dots .embla-dot.embla-dot-selected {
  background-color: #ff3f2d;
}

@media (max-width: 639px) {
  .embla .embla-container .embla-slide {
    flex: 0 0 var(--slide-small-screen-size);
  }
}
@media (min-width: 640px) and (max-width: 939px) {
  .embla .embla-container .embla-slide {
    flex: 0 0 var(--slide-middle-screen-size);
  }
}
@media (min-width: 940px) {
  .embla .embla-container .embla-slide {
    flex: 0 0 var(--slide-large-screen-size);
  }
}
.top-makes-list-container {
  margin-block: 48px;
}
.top-makes-list-container .buttons-container {
  margin-top: 48px;
  text-align: center;
}
.top-makes-list-container .section-title {
  font-size: 24px;
}
@media (min-width: 640px) {
  .top-makes-list-container .section-title {
    font-size: 32px;
    margin-bottom: 24px;
  }
}
@media (min-width: 940px) {
  .top-makes-list-container {
    margin: 72px auto;
    max-width: 940px;
  }
}

.subscription-make-filter {
  display: grid;
  grid-gap: 6px;
  grid-template-columns: repeat(4, 1fr);
  margin: 12px 0;
}
.subscription-make-filter .filter {
  align-items: center;
  border-radius: 10px;
  color: #3c3f44;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
}
.subscription-make-filter .filter:hover {
  border-color: #c6cad2;
}
.subscription-make-filter .filter.filter-active {
  border-color: #ff3f2d;
  color: #ff3f2d;
}
.subscription-make-filter .filter.filter-disabled {
  color: #848da0;
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}
.subscription-make-filter .filter .filter-label {
  font-size: 12px;
  margin-top: 6px;
  text-align: center;
}

@media (min-width: 1200px) {
  .subscription-make-filter {
    grid-template-columns: repeat(8, 1fr);
  }
}
#listing-item-offers-list {
  scroll-margin-top: 12px;
}
#listing-item-offers-list + .listing-boxes-list-container {
  margin-bottom: -8px;
}

.listing-item-offers-list.linear-listing .listing-item-offer-box {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 24px;
  width: 100%;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box:hover .img-inner {
  transform: scale(1.05);
  transform-origin: center;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box:not(:last-of-type) {
  border-bottom: 1px solid #ededed;
  margin-bottom: 24px;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-mileage {
  color: #000000;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-mileage .value {
  font-weight: 500;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-mileage::before {
  margin-top: -4px;
  vertical-align: middle;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 12px;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .offer-box-specifications-box {
  color: #000000;
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .offer-box-specifications-box .specification:not(:last-of-type) {
  border-right: 1px solid #ededed;
  margin-bottom: 12px;
  margin-right: 8px;
  padding-right: 8px;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .offer-box-specifications-box .specification .label {
  display: block;
  font-size: 12px;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .offer-box-specifications-box .specification .value {
  font-weight: 500;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .top-equipment-labels {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .top-equipment-labels .label {
  background-color: #e0f5e6;
  border-radius: 24px;
  color: #2dbd58;
  font-size: 12px;
  line-height: 1.2;
  margin-bottom: 3px;
  margin-right: 3px;
  padding: 3px 6px;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .top-equipment-labels .label.hidden {
  display: none;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .top-equipment-labels .label.show {
  background-color: #ffffff;
  cursor: pointer;
  text-decoration: underline;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .top-equipment-labels .label.show::before {
  display: none;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors {
  align-items: center;
  display: flex;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors .color {
  border: 1px solid #ededed;
  border-radius: 50%;
  display: inline-block;
  flex: none;
  height: 25px;
  position: relative;
  width: 25px;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors .color.color-black {
  background-color: #000000;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors .color.color-blue {
  background-color: #0000ff;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors .color.color-brown {
  background-color: #800000;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors .color.color-gray {
  background-color: #808080;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors .color.color-green {
  background-color: #008000;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors .color.color-orange {
  background-color: #e67e22;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors .color.color-pink {
  background-color: #ff00ff;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors .color.color-purple {
  background-color: #800080;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors .color.color-red {
  background-color: #ff0000;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors .color.color-silver {
  background-color: #c0c0c0;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors .color.color-white {
  background-color: #ffffff;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors .color.color-yellow {
  background-color: #f4d03f;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors .color.color-multicolor {
  background: linear-gradient(#00ffff, transparent), linear-gradient(-45deg, #ff00ff, transparent), linear-gradient(45deg, #f4d03f, transparent);
  background-blend-mode: multiply;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors .color:not(:first-of-type) {
  margin-left: -6px;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .colors .label {
  color: #000000;
  margin-right: 8px;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .offer-box-car-title {
  font-size: 22px;
  margin-bottom: 6px;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .offer-box-car-title > a {
  color: #000000;
  text-decoration: none;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .trim-list-container {
  color: #000000;
  margin-top: 8px;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .trim-list-container .trim {
  font-weight: 500;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info .trim-list-container .trim:not(:last-of-type)::after {
  content: "·";
  display: inline-block;
  padding: 0 4px;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-price-box {
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
  margin-top: 12px;
  min-width: 200px;
  overflow: visible;
  padding: 12px;
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-price-box:hover {
  border-color: #c6cad2;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-price-box .button-primary {
  min-width: 200px;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-price-box .price-box {
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-price-box .price-box .price {
  color: #000000;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.15;
  white-space: nowrap;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-price-box .price-box .price-from-label {
  font-size: 16px;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-price-box .price-box .price-note {
  clear: left;
  color: #6b6f73;
  display: block;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 6px;
  text-align: center;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-image-content {
  display: flex;
  flex: none;
  flex-flow: column wrap;
  justify-content: flex-start;
  min-width: 295px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-image-content .offer-box-image {
  border-radius: 10px;
  display: block;
  height: 200px;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-image-content .offer-box-image:hover {
  border-color: #c6cad2;
}
.listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-image-content .offer-box-image .favorite-offer-label {
  z-index: 2;
}
.listing-item-offers-list:not(.linear-listing) {
  display: flex;
  flex-wrap: wrap;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box {
  align-items: stretch;
  border: 1px solid #ededed;
  border-radius: 20px;
  flex-direction: column;
  margin-bottom: 24px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  will-change: transform;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box:hover {
  border-color: #c6cad2;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box:hover .img-inner {
  transform: scale(1.05);
  transform-origin: center;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .listing-item-offer-box-link {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-mileage {
  color: #000000;
  font-size: 12px;
  margin-right: 12px;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-mileage:first-of-type {
  margin-bottom: 3px;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-mileage .value {
  display: block;
  font-size: 14px;
  font-weight: 500;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-image-content {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-image {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: block;
  height: 200px;
  overflow: hidden;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-image .favorite-offer-label {
  z-index: 2;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer {
  background-color: #ffffff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  padding: 12px 24px 8px;
  position: relative;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors {
  align-items: center;
  border-top: 1px dashed #ededed;
  display: flex;
  font-size: 12px;
  justify-content: center;
  margin-top: 10px;
  padding-top: 6px;
  width: 100%;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .color {
  border: 1px solid #ededed;
  border-radius: 50%;
  display: inline-block;
  flex: none;
  height: 25px;
  position: relative;
  width: 25px;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .color.color-black {
  background-color: #000000;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .color.color-blue {
  background-color: #0000ff;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .color.color-brown {
  background-color: #800000;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .color.color-gray {
  background-color: #808080;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .color.color-green {
  background-color: #008000;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .color.color-orange {
  background-color: #e67e22;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .color.color-pink {
  background-color: #ff00ff;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .color.color-purple {
  background-color: #800080;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .color.color-red {
  background-color: #ff0000;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .color.color-silver {
  background-color: #c0c0c0;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .color.color-white {
  background-color: #ffffff;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .color.color-yellow {
  background-color: #f4d03f;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .color.color-multicolor {
  background: linear-gradient(#00ffff, transparent), linear-gradient(-45deg, #ff00ff, transparent), linear-gradient(45deg, #f4d03f, transparent);
  background-blend-mode: multiply;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .color:not(:first-of-type) {
  margin-left: -6px;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .color {
  height: 20px;
  width: 20px;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .colors .label {
  color: #3c3f44;
  margin-right: 8px;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .price-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .top-equipment-labels {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  width: 100%;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .top-equipment-labels .label {
  align-items: center;
  background-color: #e0f5e6;
  border-radius: 24px;
  color: #2dbd58;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 4px;
  margin-right: 2px;
  padding: 2px 8px 2px 6px;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .top-equipment-labels .label.hidden {
  display: none;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .top-equipment-labels .label.show {
  background-color: #ffffff;
  cursor: pointer;
  text-decoration: underline;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .top-equipment-labels .label.show::before {
  display: none;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .button-primary {
  display: none;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 100%;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .offer-box-price-box {
  align-items: flex-start;
  border-top: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding-top: 12px;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .price-box {
  flex: none;
  line-height: 1.2;
  text-align: right;
  text-decoration: none;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .price-box .price {
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.15;
  white-space: nowrap;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .price-box .price-from-label {
  font-size: 16px;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .price-box .price-note {
  clear: left;
  color: #6b6f73;
  font-size: 12px;
  font-weight: 400;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .offer-box-specifications-box {
  color: #000000;
  font-size: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .offer-box-specifications-box .specification:not(:last-of-type)::after {
  content: "•";
  display: inline-block;
  padding: 0 4px;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .not-available {
  color: #000000;
  font-weight: 700;
  text-align: center;
  width: 100%;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .offer-box-car-title {
  font-size: 20px;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .offer-box-car-title > a {
  color: #000000;
  text-decoration: none;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .car-offer-target-lessee {
  border-top: 1px dashed #ededed;
  color: #000000;
  font-size: 85%;
  margin-top: 6px;
  padding-top: 6px;
  text-align: center;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .dislike-offer {
  color: #ff3f2d;
  cursor: pointer;
  padding-bottom: 12px;
  padding-top: 12px;
  text-align: center;
  text-decoration: underline;
  vertical-align: middle;
}
.listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .dislike-offer::before {
  background: url("/assets/icon/cross-no.svg") 50% 50% no-repeat transparent;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 1.5rem;
  margin-right: 4px;
  text-decoration: none;
  width: 1.5rem;
}

@media (min-width: 640px) and (max-width: 939px) {
  .listing-item-offers-list:not(.linear-listing) .listing-item-offer-box {
    margin-left: 1%;
    width: 49.5%;
  }
  .listing-item-offers-list:not(.linear-listing) .listing-item-offer-box:nth-of-type(2n+1) {
    margin-left: 0;
  }
}
@media (min-width: 940px) {
  #listing-item-offers-list + .listing-boxes-list-container {
    margin-top: 24px;
  }
  .listing-item-offers-list:not(.linear-listing) .listing-item-offer-box {
    margin-left: 1%;
    width: 32.6666666667%;
  }
  .listing-item-offers-list:not(.linear-listing) .listing-item-offer-box:nth-of-type(3n+1) {
    margin-left: 0;
  }
  .listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .listing-item-offer-box-link {
    display: none;
  }
  .listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .price-box .price {
    font-size: 22px;
  }
  .listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-footer .button-primary {
    display: inline-block;
  }
  .listing-item-offers-list.linear-listing .listing-item-offer-box:not(:last-of-type) {
    margin-bottom: 48px;
    padding-bottom: 48px;
  }
}
@media (min-width: 520px) and (max-width: 1199px) {
  .listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-image-content .offer-box-image {
    height: 300px;
  }
}
@media (min-width: 1200px) {
  .listing-item-offers-list:not(.linear-listing) .listing-item-offer-box .offer-box-image-content .offer-box-image {
    height: 250px;
  }
  .listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-price-box {
    margin-top: 0;
  }
  .listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-info {
    margin-top: 0;
  }
  .listing-item-offers-list.linear-listing .listing-item-offer-box .offer-box-image-content {
    margin-right: 2%;
    width: 30%;
  }
}
.subscription-banner-container {
  margin-bottom: 48px;
  overflow: hidden;
  padding: 48px 12px;
  position: relative;
}
.subscription-banner-container picture {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.subscription-banner-container::before {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 50%, transparent 100%, transparent 100%);
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}
.subscription-banner-container .banner-promo-text {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.subscription-banner-container .banner-promo-text span {
  background-color: #ff3f2d;
  color: #ffffff;
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  padding: 3px 18px;
  text-transform: uppercase;
  white-space: nowrap;
  width: fit-content;
}
.subscription-banner-container .banner-promo-text span:first-child {
  transform: rotate(-5deg) translateX(-10px) translateY(-5px);
}
.subscription-banner-container .banner-promo-text span:nth-child(2) {
  transform: rotate(3deg);
}
.subscription-banner-container.container {
  padding: 48px 12px;
}
.subscription-banner-container:not(.container) {
  border-radius: 20px;
  padding: 48px 24px;
}
.subscription-banner-container .title {
  color: #ffffff;
}
.subscription-banner-container .list-title {
  color: #ffffff;
  font-size: 135%;
  font-weight: 500;
  margin-bottom: 24px;
}
.subscription-banner-container .list {
  color: #ffffff;
  list-style: none;
  margin-bottom: 24px;
}
.subscription-banner-container .list .item {
  font-size: 16px;
  line-height: 1.45;
  margin-left: 3.5rem;
}
.subscription-banner-container .list .item::before {
  background: url("/assets/icon/check-ok-white.svg") 50% 50% no-repeat transparent;
  background-size: 100%;
  color: transparent;
  content: " ";
  display: inline-block;
  height: 2.4rem;
  margin: -0.4rem 0.8rem -0.2rem -3.5rem;
  vertical-align: middle;
  width: 2.4rem;
}
.subscription-banner-container .banner-container-column {
  min-height: 120px;
  position: relative;
  z-index: 3;
}
.subscription-banner-container .banner-container-column .buttons-container {
  display: flex;
}
.subscription-banner-container .banner-container-column .buttons-container .button:nth-of-type(2) {
  margin-left: 12px;
}

@media (min-width: 640px) {
  .subscription-banner-container:not(.authentication) {
    border-radius: 20px 20px 20px 60px;
    padding: 48px;
  }
  .subscription-banner-container:not(.authentication).container {
    margin-top: 24px;
    padding: 48px;
  }
  .subscription-banner-container:not(.authentication) .title {
    font-size: 32px;
    line-height: 1.2;
  }
}
@media (min-width: 640px) and (min-width: 940px) {
  .subscription-banner-container:not(.authentication) .title {
    font-size: 48px;
  }
}
@media (min-width: 640px) {
  .subscription-banner-container:not(.authentication) .list {
    display: flex;
    flex-wrap: wrap;
  }
  .subscription-banner-container:not(.authentication) .list .item {
    padding-left: 36px;
    margin-left: 2%;
    width: 49%;
  }
  .subscription-banner-container:not(.authentication) .list .item:nth-child(2n+1) {
    margin-left: 0;
  }
}
@media (min-width: 940px) {
  .subscription-banner-container:not(.authentication) {
    display: flex;
    margin-bottom: 72px;
    padding: 72px;
  }
  .subscription-banner-container:not(.authentication) .banner-promo-text span {
    font-size: 34px;
  }
  .subscription-banner-container:not(.authentication) .banner-promo-text span:first-child {
    transform: rotate(-5deg) translateX(-10px) translateY(-5px);
  }
  .subscription-banner-container:not(.authentication) .banner-promo-text span:nth-child(2) {
    transform: rotate(3deg);
  }
  .subscription-banner-container:not(.authentication).container {
    padding: 72px;
  }
  .subscription-banner-container:not(.authentication):not(.container) {
    padding: 72px;
  }
}
@media (min-width: 1100px) {
  .subscription-banner-container {
    min-height: 480px;
  }
  .subscription-banner-container:not(.authentication)::before {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 30%, transparent 75%, transparent 100%);
  }
  .subscription-banner-container:not(.authentication) .banner-container-columns {
    display: flex;
    width: 100%;
  }
  .subscription-banner-container:not(.authentication) .banner-container-columns .banner-container-column {
    margin-left: 2%;
    width: 49%;
  }
  .subscription-banner-container:not(.authentication) .banner-container-columns .banner-container-column:nth-child(2n+1) {
    margin-left: 0;
  }
  .subscription-banner-container:not(.authentication) .banner-container-columns .banner-container-column:nth-of-type(1) {
    order: 2;
  }
  .subscription-banner-container:not(.authentication) .banner-container-columns .banner-container-column:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 1;
  }
}
.subscription-contacts-container {
  background-color: #ededee;
  margin-top: 48px;
  padding: 48px 24px;
  position: relative;
}
.subscription-contacts-container .response-message {
  font-size: 2rem;
  font-weight: 500;
  margin: 48px 0;
  text-align: center;
}
.subscription-contacts-container .response-message.success {
  color: #2dbd58;
}
.subscription-contacts-container .response-message.error {
  color: #ec5340;
}
.subscription-contacts-container .section-title {
  text-align: center;
  font-size: 24px;
}
@media (min-width: 640px) {
  .subscription-contacts-container .section-title {
    font-size: 32px;
    margin-bottom: 24px;
  }
}
.subscription-contacts-container .content {
  text-align: center;
}
.subscription-contacts-container .row.opening-hours {
  font-weight: 500;
  margin-bottom: 24px;
}
.subscription-contacts-container .row.contacts {
  margin-bottom: 24px;
}
.subscription-contacts-container .row.contacts .button-light:first-of-type {
  margin-right: 12px;
}
.subscription-contacts-container .row.form-container {
  margin-top: 24px;
}
.subscription-contacts-container .row.form-container .form-flex-row {
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto 24px;
  width: fit-content;
}
.subscription-contacts-container .row.form-container .form-row.button-row {
  text-align: center;
}
.subscription-contacts-container .row.form-container .inner-addon {
  display: flex;
  flex-direction: column;
  position: relative;
}
.subscription-contacts-container .row.form-container .inner-addon.left-addon input {
  border-radius: 20px 0 0 20px;
  letter-spacing: 1px;
  padding: 12px 12px 12px 7.5rem;
  width: 150px;
}
.subscription-contacts-container .row.form-container .inner-addon .phone-prefix {
  align-items: center;
  border-right: 1px solid #ededed;
  color: #c6ccd7;
  display: flex;
  font-weight: 700;
  height: 44px;
  padding: 0 12px;
  position: absolute;
  top: 1px;
}
.subscription-contacts-container .row.form-container .button-primary {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  min-width: 232px;
  padding: 9.5px 24px;
}
.subscription-contacts-container .row.form-container .button-primary::before {
  background: url("/assets/icon/callback.svg") 0 50% no-repeat transparent;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 2rem;
  margin-right: 6px;
  margin-top: -0.3rem;
  vertical-align: middle;
  width: 2rem;
}
.subscription-contacts-container #main-menu-phone-button .button-primary::before {
  background: url("/assets/icon/callback.svg") 0 50% no-repeat transparent;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 2rem;
  margin-right: 6px;
  margin-top: -0.3rem;
  vertical-align: middle;
  width: 2rem;
}
.subscription-contacts-container #main-menu-phone-form {
  display: none;
}

@media (min-width: 640px) {
  .subscription-contacts-container {
    border-radius: 60px 20px 20px;
    margin-bottom: 48px;
  }
}
@media (min-width: 940px) {
  .subscription-contacts-container {
    margin-bottom: 72px;
    margin-top: 72px;
    padding: 48px 72px;
  }
  .subscription-contacts-container #main-menu-phone-form {
    display: block;
  }
  .subscription-contacts-container #main-menu-phone-button {
    display: none;
  }
  .subscription-contacts-container .row.form-container .inner-addon.left-addon input {
    width: 270px;
  }
}
.driveto-highlights-container .highlights-title {
  color: #000000;
  font-size: 18px;
  margin-bottom: 12px;
  text-transform: uppercase;
}
.driveto-highlights-container .driveto-highlights-box {
  text-align: center;
}
.driveto-highlights-container .driveto-highlights-box:not(:last-of-type) {
  border-bottom: 1px solid #ededed;
  margin-bottom: 24px;
  padding-bottom: 24px;
}
.driveto-highlights-container .driveto-highlights-box .title {
  font-weight: 700;
  margin-bottom: 0;
  font-size: 32px;
  line-height: 1.2;
}
@media (min-width: 940px) {
  .driveto-highlights-container .driveto-highlights-box .title {
    font-size: 48px;
  }
}
.driveto-highlights-container .driveto-highlights-box .text {
  font-size: 16px;
}
.driveto-highlights-container .driveto-highlights-box .button-light {
  margin-top: 6px;
}

@media (min-width: 640px) {
  .driveto-highlights-container .driveto-highlights-boxes {
    display: flex;
  }
  .driveto-highlights-container .driveto-highlights-box {
    margin-left: 3%;
    width: 31.3333333333%;
  }
  .driveto-highlights-container .driveto-highlights-box:nth-child(3n+1) {
    margin-left: 0;
  }
  .driveto-highlights-container .driveto-highlights-box:not(:last-of-type) {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
@media (min-width: 940px) {
  .driveto-highlights-container {
    margin: 0 auto;
    max-width: 940px;
  }
  .driveto-highlights-container .driveto-highlights-box .title {
    font-size: 3.8rem;
  }
  .driveto-highlights-container .driveto-highlights-box .text {
    font-size: 18px;
    margin: 0 auto;
    max-width: 70%;
  }
}
.driveto-news-container {
  margin-bottom: 48px;
}
.driveto-news-container .driveto-news-title {
  color: #000000;
  font-size: 18px;
  margin-bottom: 12px;
  text-transform: uppercase;
}
.driveto-news-container .driveto-news-slider-container .driveto-news-content {
  align-items: center;
  background-color: #ededee;
  border-radius: 10px;
  color: #000000;
  display: flex;
  flex: none;
  flex-direction: row-reverse;
  height: 100%;
  justify-content: space-between;
  padding: 12px 18px 18px;
}
.driveto-news-container .driveto-news-slider-container .driveto-news-content .image {
  border-radius: 50%;
  height: 70px;
  image-rendering: -webkit-optimize-contrast;
  width: 70px;
}
.driveto-news-container .driveto-news-slider-container .driveto-news-content .title {
  line-height: 1.45;
}
.driveto-news-container .driveto-news-slider-container .driveto-news-content .subtitle {
  color: #ff3f2d;
  font-size: 18px;
  line-height: 1.45;
  margin-bottom: 6px;
}
.driveto-news-container .driveto-news-slider-container .driveto-news-content .content {
  margin-right: 12px;
}
.driveto-news-container .driveto-news-slider-container .driveto-news-content .content .button-light {
  display: block;
  margin-top: 6px;
  width: fit-content;
}

@media (min-width: 640px) {
  .driveto-news-container .driveto-news-slider-container .driveto-news-content {
    padding: 12px 24px;
  }
}
@media (min-width: 940px) {
  .driveto-news-container {
    margin: 0 auto 72px;
    max-width: 940px;
  }
  .driveto-news-container .embla .embla-container.driveto-news-slider-container {
    box-sizing: border-box;
    display: block;
  }
  .driveto-news-container .embla .embla-container.driveto-news-slider-container .driveto-news-content {
    border-radius: 0;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 3px;
  }
  .driveto-news-container .embla .embla-container.driveto-news-slider-container .driveto-news-content:first-of-type {
    border-radius: 10px 10px 0 0;
  }
  .driveto-news-container .embla .embla-container.driveto-news-slider-container .driveto-news-content:last-of-type {
    border-radius: 0 0 10px 10px;
  }
  .driveto-news-container .embla .embla-container.driveto-news-slider-container .driveto-news-content:only-of-type {
    border-radius: 10px;
  }
  .driveto-news-container .embla .embla-container.driveto-news-slider-container .driveto-news-content .content {
    margin-left: 24px;
  }
  .driveto-news-container .embla .embla-container.driveto-news-slider-container .driveto-news-content .content .subtitle {
    margin-bottom: 0;
  }
  .driveto-news-container .embla .embla-container.driveto-news-slider-container .driveto-news-content .content .title {
    font-size: 16px;
  }
  .driveto-news-container .embla .embla-container.driveto-news-slider-container .driveto-news-content .content .button-light {
    display: inline-block;
    margin-left: 12px;
    margin-top: 0;
  }
}
.subscription-landing-page-how-it-works {
  margin-bottom: 48px;
}
.subscription-landing-page-how-it-works .section-title {
  align-items: flex-end;
  background: linear-gradient(180deg, #ff3f2d 320px, #ffffff 0);
  background-position: 50% 0;
  color: #ffffff;
  display: flex;
  height: 320px;
  padding: 0 12px 24px;
  font-size: 24px;
}
@media (min-width: 640px) {
  .subscription-landing-page-how-it-works .section-title {
    font-size: 32px;
    margin-bottom: 24px;
  }
}
.subscription-landing-page-how-it-works .section-title::before {
  background-image: url("/assets/img/subscription/how-it-works-mobile.png");
  background-position: 50% -48px;
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  height: 350px;
  left: 0;
  position: absolute;
  right: 0;
  top: -48px;
  width: 100%;
  z-index: 1;
}
.subscription-landing-page-how-it-works .container-content {
  margin-top: 24px;
}
.subscription-landing-page-how-it-works .container-content .green {
  color: #2dbd58;
  font-weight: 500;
}
.subscription-landing-page-how-it-works .content {
  background-color: linear-gradient(to right, transparent 10%, #ffffff, transparent 10%);
  margin: 0 auto;
  max-width: 630px;
}
.subscription-landing-page-how-it-works .button-container {
  text-align: center;
}
.subscription-landing-page-how-it-works .list {
  counter-reset: boxes-counter;
  list-style: none;
}
.subscription-landing-page-how-it-works .list .item {
  align-items: center;
  color: #000000;
  display: flex;
  font-size: 135%;
  font-weight: 700;
  line-height: 1.45;
  margin-bottom: 18px;
  padding-bottom: 18px;
}
.subscription-landing-page-how-it-works .list .item:not(:last-of-type) {
  border-bottom: 1px solid #ededed;
}
.subscription-landing-page-how-it-works .list .item::before {
  color: #dee0e2;
  content: counter(boxes-counter) "";
  counter-increment: boxes-counter;
  margin-right: 24px;
  font-family: Poppins, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 32px;
  line-height: 1.2;
}
@media (min-width: 940px) {
  .subscription-landing-page-how-it-works .list .item::before {
    font-size: 48px;
  }
}
.subscription-landing-page-how-it-works .buttons-container {
  text-align: center;
}
.subscription-landing-page-how-it-works .buttons-container .button:first-of-type {
  margin-right: 6px;
}

.webp .subscription-landing-page-how-it-works .section-title::before {
  background-image: url("/assets/img/subscription/how-it-works-mobile.webp");
}

@media (max-width: 639px) {
  .subscription-landing-page-how-it-works {
    margin-top: 72px;
  }
}
@media (min-width: 640px) {
  .subscription-landing-page-how-it-works .section-title {
    background: #ffffff;
    border-radius: 20px;
    color: #000000;
    display: block;
    height: auto;
    padding-bottom: 0;
    text-align: center;
  }
  .subscription-landing-page-how-it-works .section-title::before {
    display: none;
  }
}
@media (min-width: 1200px) {
  .subscription-landing-page-how-it-works {
    margin-bottom: 72px;
  }
  .subscription-landing-page-how-it-works .container-content {
    background-image: url("/assets/img/subscription/how-it-works-left.png"), url("/assets/img/subscription/how-it-works-right.png");
    background-position: 0 10%, 100% 90%;
    background-repeat: no-repeat, no-repeat;
  }
  .webp .subscription-landing-page-how-it-works .container-content {
    background-image: url("/assets/img/subscription/how-it-works-left.webp"), url("/assets/img/subscription/how-it-works-right.webp");
  }
}
.top-offers-list-container {
  margin-bottom: 48px;
}
.top-offers-list-container.similar-offers-container {
  margin-top: 48px;
}
.top-offers-list-container .section-title {
  font-size: 24px;
}
@media (min-width: 640px) {
  .top-offers-list-container .section-title {
    font-size: 32px;
    margin-bottom: 24px;
  }
}
.top-offers-list-container .buttons-container {
  text-align: center;
}

@media (min-width: 940px) {
  .top-offers-list-container {
    margin-bottom: 72px;
  }
  .top-offers-list-container.similar-offers-container {
    margin-top: 72px;
  }
  .top-offers-list-container .buttons-container {
    margin-top: 24px;
  }
}
.subscription-landing-page-references {
  margin-bottom: 48px;
}
.subscription-landing-page-references.authentication {
  margin-bottom: 24px;
  margin-top: 48px;
}
.subscription-landing-page-references .title {
  color: #000000;
  font-size: 18px;
  margin-bottom: 24px;
  text-transform: uppercase;
}
.subscription-landing-page-references .reference-box {
  align-items: center;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 24px;
  text-align: center;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
  transition: box-shadow 0.3s ease;
}
.subscription-landing-page-references .reference-box:hover {
  border-color: #c6cad2;
}
.subscription-landing-page-references .reference-box .text {
  color: #3c3f44;
}
.subscription-landing-page-references .reference-box:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
.subscription-landing-page-references .reference-box:hover .logo {
  scale: 1.05;
}
.subscription-landing-page-references .reference-box .button-light {
  margin-top: 12px;
}
.subscription-landing-page-references .reference-box .logo {
  height: auto;
  margin-bottom: 24px;
  max-height: 35px;
  max-width: 230px;
  width: auto;
  transition: scale 0.3s ease;
}

@media (min-width: 940px) {
  .subscription-landing-page-references {
    margin-bottom: 72px;
  }
  .subscription-landing-page-references.authentication {
    margin-top: 72px;
  }
  .subscription-landing-page-references .embla .embla-container {
    flex-wrap: wrap;
  }
  .subscription-landing-page-references .embla .embla-container .embla-slide {
    flex: 0 0 32%;
    margin-bottom: 24px;
  }
  .subscription-landing-page-references .reference-box {
    margin-bottom: 24px;
  }
  .subscription-landing-page-references .section-title {
    text-align: center;
  }
}
.subscription-how-it-works-benefits {
  border-bottom: 1px solid #ededed;
  border-top: 1px solid #ededed;
  margin-bottom: 48px;
  margin-top: 48px;
  padding-bottom: 48px;
  padding-top: 48px;
}
.subscription-how-it-works-benefits .boxes-content {
  display: none;
}
.subscription-how-it-works-benefits .benefits-box:nth-of-type(1) .benefit {
  display: none;
  margin-top: 24px;
  max-width: 450px;
}
.subscription-how-it-works-benefits .benefits-box:nth-of-type(1) .benefit .counter-container {
  min-width: 30px;
  text-align: center;
}
.subscription-how-it-works-benefits .benefits-box:nth-of-type(1) .benefit .text {
  color: #000000;
  font-size: 16px;
  margin-bottom: 24px;
}
.subscription-how-it-works-benefits .benefits-box:nth-of-type(1) .scroll-control-container {
  align-items: center;
  display: flex;
  margin-bottom: 24px;
}
.subscription-how-it-works-benefits .benefits-box:nth-of-type(1) .scroll-control-container .scroll-control {
  cursor: pointer;
  display: inline-block;
}
.subscription-how-it-works-benefits .benefits-box:nth-of-type(1) .scroll-control-container .scroll-control:hover::after {
  border-color: #c6cad2;
}
.subscription-how-it-works-benefits .benefits-box:nth-of-type(1) .scroll-control-container .scroll-control::after {
  background: url("/assets/icon/right-arrow-orange.svg") 50% 50% no-repeat #ffffff;
  background-size: 35%;
  border: 2px solid #ededed;
  border-radius: 15px;
  content: " ";
  display: inline-block;
  height: 4rem;
  width: 4rem;
  transition: transform 0.3s ease;
  will-change: transform;
}
.subscription-how-it-works-benefits .benefits-box:nth-of-type(1) .scroll-control-container .scroll-control.right {
  margin-left: 12px;
}
.subscription-how-it-works-benefits .benefits-box:nth-of-type(1) .scroll-control-container .scroll-control.left {
  margin-right: 12px;
}
.subscription-how-it-works-benefits .benefits-box:nth-of-type(1) .scroll-control-container .scroll-control.left::after {
  transform: rotate(180deg);
}
.subscription-how-it-works-benefits .benefits-box.most-frequent-questions .question-container {
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 12px;
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
}
.subscription-how-it-works-benefits .benefits-box.most-frequent-questions .question-container:hover {
  border-color: #c6cad2;
}
.subscription-how-it-works-benefits .benefits-box.most-frequent-questions .question-container .question {
  justify-content: flex-start;
}
.subscription-how-it-works-benefits .benefits-box.most-frequent-questions .question-container .question .title {
  font-size: 16px;
  text-align: center;
}
.subscription-how-it-works-benefits .benefits-box.most-frequent-questions .question-container .question .icon {
  flex: none;
  height: auto;
  margin-right: 24px;
  width: 30px;
}
.subscription-how-it-works-benefits .benefits-box.most-frequent-questions .question-container .question .show-answer {
  margin-left: auto;
}
.subscription-how-it-works-benefits .benefits-box.most-frequent-questions .button {
  margin-top: 12px;
}

@media (min-width: 640px) {
  .subscription-how-it-works-benefits .benefits-container .benefits-box.most-frequent-questions .question-container::before {
    display: none;
  }
}
@media (min-width: 940px) {
  .subscription-how-it-works-benefits {
    margin-bottom: 72px;
    margin-top: 72px;
    padding-bottom: 72px;
    padding-top: 72px;
  }
  .subscription-how-it-works-benefits .container {
    max-width: 940px;
  }
  .subscription-how-it-works-benefits .benefits-container {
    align-items: flex-start;
    display: flex;
  }
  .subscription-how-it-works-benefits .benefits-container .section-title {
    font-size: 32px;
  }
  .subscription-how-it-works-benefits .benefits-container .boxes-content {
    display: block;
  }
  .subscription-how-it-works-benefits .benefits-container .benefits-box {
    margin-left: 5%;
    width: 47.5%;
  }
  .subscription-how-it-works-benefits .benefits-container .benefits-box:nth-child(2n+1) {
    margin-left: 0;
  }
  .subscription-how-it-works-benefits .benefits-container .benefits-box:nth-of-type(1) .benefit {
    display: block;
  }
  .subscription-how-it-works-benefits .benefits-container .benefits-box.most-frequent-questions {
    display: flex;
    flex-wrap: wrap;
  }
  .subscription-how-it-works-benefits .benefits-container .benefits-box.most-frequent-questions .button {
    display: none;
  }
  .subscription-how-it-works-benefits .benefits-container .benefits-box.most-frequent-questions .question-container {
    margin-left: 2%;
    width: 49%;
  }
  .subscription-how-it-works-benefits .benefits-container .benefits-box.most-frequent-questions .question-container:nth-child(2n+1) {
    margin-left: 0;
  }
  .subscription-how-it-works-benefits .benefits-container .benefits-box.most-frequent-questions .question-container.active {
    border-color: #ff3f2d;
    outline: 1px solid #ff3f2d;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
  .subscription-how-it-works-benefits .benefits-container .benefits-box.most-frequent-questions .question-container::before,
  .subscription-how-it-works-benefits .benefits-container .benefits-box.most-frequent-questions .question-container .answer,
  .subscription-how-it-works-benefits .benefits-container .benefits-box.most-frequent-questions .question-container .show-answer {
    display: none !important;
  }
  .subscription-how-it-works-benefits .benefits-container .benefits-box.most-frequent-questions .question-container .question {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 120px;
    padding: 12px 36px;
  }
  .subscription-how-it-works-benefits .benefits-container .benefits-box.most-frequent-questions .question-container .question .icon {
    margin-bottom: 6px;
    margin-right: 0;
    width: 45px;
  }
}
.most-frequent-questions {
  background-color: #ffffff;
  counter-reset: boxes-counter;
}
.most-frequent-questions.dashboard {
  margin-bottom: 48px;
}
.most-frequent-questions .show-all {
  margin-top: 24px;
  text-align: center;
}
.most-frequent-questions .question-container {
  border-radius: 10px;
  display: flex;
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
}
.most-frequent-questions .question-container:hover {
  border-color: #c6cad2;
}
.most-frequent-questions .question-container:not(:last-of-type) {
  margin-bottom: 12px;
}
.most-frequent-questions .question-container .content {
  width: 100%;
}
.most-frequent-questions .question-container .content h5 {
  margin-bottom: 0;
  width: 70%;
}
.most-frequent-questions .question-container .content .question {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding: 12px;
  width: 100%;
}
.most-frequent-questions .question-container .content .question .title {
  font-size: 16px;
  font-weight: 700;
}
.most-frequent-questions .question-container .content .answer {
  counter-reset: boxes-counter;
  display: none;
  padding: 0 12px 12px;
}
.most-frequent-questions .question-container .content .answer h6 {
  font-size: 16px;
}
.most-frequent-questions .question-container .content .answer h6::before {
  content: counter(boxes-counter) ".";
  counter-increment: boxes-counter;
  display: inline-block;
  margin-right: 6px;
}
.most-frequent-questions .question-container .show-answer {
  height: 15px;
  min-width: 15px;
  position: relative;
  width: 15px;
}
.most-frequent-questions .question-container .show-answer::before, .most-frequent-questions .question-container .show-answer::after {
  background-color: #ff3f2d;
  content: "";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  top: 50%;
  transition: 0.3s;
  width: 100%;
}
.most-frequent-questions .question-container .show-answer::before {
  transform: translatey(-50%);
}
.most-frequent-questions .question-container .show-answer::after {
  transform: translatey(-50%) rotate(90deg);
}
.most-frequent-questions .question-container.active .show-answer::before {
  opacity: 0;
  transform: translatey(-50%) rotate(-90deg);
}
.most-frequent-questions .question-container.active .show-answer::after {
  transform: translatey(-50%) rotate(0);
}

@media (min-width: 640px) {
  .most-frequent-questions .question-container .show-answer {
    flex: inherit;
    margin-left: 24px;
    white-space: nowrap;
  }
  .most-frequent-questions .question-container .content .question {
    min-height: 80px;
    padding: 24px;
  }
  .most-frequent-questions .question-container .content .answer {
    padding: 0 24px 24px;
  }
  .most-frequent-questions .question-container::before {
    align-items: flex-start;
    border-right: 1px solid #ededed;
    color: #dee0e2;
    content: counter(boxes-counter);
    counter-increment: boxes-counter;
    display: flex;
    font-size: 3rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1;
    padding: 24px 12px;
    text-align: center;
    width: 60px;
  }
  .most-frequent-questions .question-container.active .show-answer::before {
    line-height: 0.6;
  }
}
@media (min-width: 940px) {
  .landing-page-most-frequent-questions {
    padding: 72px 0;
  }
  .most-frequent-questions.dashboard {
    margin-bottom: 72px;
  }
  .most-frequent-questions .show-all {
    margin-top: 48px;
  }
  .most-frequent-questions .container {
    max-width: 800px;
  }
  .most-frequent-questions .question-container {
    display: flex;
  }
  .most-frequent-questions .question-container .show-answer {
    height: 25px;
    min-width: 25px;
    width: 25px;
  }
  .most-frequent-questions .question-container::before {
    padding: 24px;
    width: 80px;
  }
}