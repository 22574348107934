// -- Assets directory --
$assetsDir: "/assets/";

// --  Default colors --
$defaultBackgroundColor: #ffffff;
$accentedBackgroundColor: #ededee;
$darkBackgroundColor: #000000;
$borderLightColor: #ededed;
$borderHoverColor: #c6cad2;
$inquiryLeftColumnBackgroundColor: $darkBackgroundColor;
$lightGreenBackgroundColor: #e0f5e6;
$lightYellowBackgroundColor: #fef7dd;
$lightOrangeBackgroundColor: #feeae8;
$cardFooterGradientColor: #000000;

$brandColor: #ff3f2d;
$defaultTextColor: #3c3f44;
$darkTextColor: #000000;
$lightTextColor: #848da0;
$lighterTextColor: #dee0e2;
$inverseTextColor: #ffffff;
$headlineTextColor: #000000;

$alertDangerColor: #a94442;
$alertDangerBackgroundColor: #f2dede;
$alertDangerBorder: #ebccd1;
$alertWarningColor: #8a6d3b;
$alertWarningBackgroundColor: #fcf8e3;
$alertWarningBorder: #faebcc;
$alertInfoColor: #31708f;
$alertInfoBackgroundColor: #d9edf7;
$alertInfoBorder: #bce8f1;
$alertSuccessColor: #3c763d;
$alertSuccessBackgroundColor: #dff0d8;
$alertSuccessBorder: #d6e9c6;

// -- Supplemental colors --
$brandLighterColor: #ff573d;
$brandDarkerColor: #b52c17;
$accentedTextColor: #6b6f73;
$yesColor: #2dbd58;
$noColor: #ec5340;
$iconBaseColor: #45474c;
$videoBackgroundColor: rgba(0, 0, 0, 0.2);
$videoBackgroundHoverColor: rgba(0, 0, 0, 0.4);
$bannerBackgroundColor: rgba(0, 0, 0, 0.7);
$topLabelBackgroundColor: $lightGreenBackgroundColor;
$topLabelTextColor: $yesColor;

$landingPageHeaderBackgroundColor: rgba(0, 0, 0, 0.4);

$linkColor: $brandColor;
$linkHoverColor: $brandDarkerColor;

$comparatorArrowBackground: rgba(67, 76, 95, 0.5);

// -- Selected text --
$selectedTextBackgroundColor: #00baf2;
$selectedTextColor: #ffffff;

// -- Main menu --
$mainMenuLineHeight: 2.3rem;
$mainMenuMinHeight: 65px;
$mobileMenuBackgroundColor: $accentedBackgroundColor;
$mobileMenuLinksColor: #000000;

// -- Border radius --
$defaultBorderRadius: 10px;
$largeBorderRadius: 20px;

// -- Buttons --
$buttonColor: $inverseTextColor;
$buttonBorderColor: $brandColor;
$buttonTextColor: $brandColor;
$buttonHoverColor: $brandDarkerColor;
$buttonHoverTextColor: $inverseTextColor;
$buttonPrimaryColor: $brandColor;
$buttonPrimaryTextColor: $inverseTextColor;
$buttonPrimaryHoverColor: $brandDarkerColor;
$buttonPrimaryHoverTextColor: $buttonPrimaryTextColor;
$buttonBorderRadius: 30px;
$checklistFontColor: #22ce82;

$buttonSecondaryBackgroundColor: #eff0f1;
$buttonSecondaryBorderColor: #434c5f;
$buttonSecondaryTextColor: #434c5f;

// -- Label colors --
$yellowLabelBackgroundColor: #fedd24;
$orangeLabelBackgroundColor: #ff573d;
$lightGrayLabelBackgroundColor: $borderLightColor;
$redLabelBackgroundColor: #da0339;
$labelBackgroundColor: $defaultBackgroundColor;
$defaultLabelTextColor: $darkTextColor;
$inverseLabelTextColor: $inverseTextColor;
$greenLabelBackgroundColor: $yesColor;
$lightOrangeLabelColor: #ffc800;

$dropdownSelectColor: $brandColor;
$dropdownColor: $lightTextColor;

// -- Offers list --
$offersListPriceColor: $headlineTextColor;
$offersListTitleFontSize: 20px;
$offersListSubtitleFontSize: 13px;
$priceNoteFontSize: 12px;

// -- Forms --
$formTextColor: #4d5156;
$formPlaceholderTextColor: #c6ccd7;
$formInputBorderColor: $borderLightColor;
$formInputFocusBorderColor: $borderHoverColor;
$formInputBackgroundColor: $defaultBackgroundColor;
$formRequiredFieldColor: $noColor;
$formHelpSignColor: #00baf2;
$formInputBorderRadius: 4px;

// -- Footer --
$footerBackgroundColor: #000000;
$footerBorderColor: #4d5156;
$footerTextColor: #4d5156;
$footerLinksColor: #ffffff;
$footerHeadingColor: #4d5156;
$footerContactFontSize: 22px;

// -- Social --
$facebookColor: #4267b2;
$facebookHoverColor: #274c97;
$twitterColor: #20b8ff;

// -- Font dimensions --
$defaultFontWeight: 400;
$mediumFontWeight: 500;
$boldFontWeight: 700;
$headlineFontWeight: 600;

// -- Font dimensions --
$defaultFontSize: 14px;
$buttonFonSize: 16px;
$buttonBigFonSize: 18px;
$smallFontSize: 85%;
$smallScreenFontSize: 12px;
$slightlyBiggerFontSize: 115%;
$slightlyBiggerFontSizePx: 16px;
$biggerFontSize: 135%;
$defaultLineHeight: 1.45;
$priceFromBiggerFontSize: 25px;
$priceFromFontSize: 22px;
$oldPriceFontSize: $defaultFontSize;
$priceFromSubtitleFontSize: 16px;
$buttonSmallerFontSize: 12px;

$extremeBigFontSize: 48px;
$h1LargeScreenFontSize: 32px;
$h2LargeScreenFontSize: 24px;
$h3LargeScreenFontSize: 22px;
$h4LargeScreenFontSize: 20px;
$h1SmallScreenFontSize: 24px;
$h2SmallScreenFontSize: 20px;
$h3SmallScreenFontSize: 18px;
$h4SmallScreenFontSize: 18px;
$h5SmallScreenFontSize: 16px;

$inquiryHeadlineFontSize: 3rem;
$inquiryHeadlineSmallerFontSize: 2.3rem;

// -- Theme dimensions --
$tabletBreakpoint: 640px;
$desktopBreakpoint: 940px;
$desktopLargeBreakpoint: 1200px;
$smallestDeviceBreakpoint: 375px;
$mainMenuBreakpoint: 1025px;
$defaultMargin: 24px;
$smallScreenMargin: $defaultMargin * 2;
$largeScreenMargin: $defaultMargin * 3;
$gridMargin: 1%;
$smallScreenLogoWidth: 108px;
$largeScreenLogoWidth: 130px;
$boxImageHeight: 200px;
$containerPadding: 12px;

// -- User profile --
$userProfileDocumentMissingBackgroundColor: #8c96a9;
$userProfileDocumentSuccessBackgroundColor: #2dbd58;
$userProfileFooterContactBackground: #565e6e;
$userProfileFooterHeadlineColor: #808b9d;
$userProfileProgressColor: #2ebc58;

$userNextPaymentLabelBackgroundColor: #fde78a;
$userNextPaymentBorderColor: #feeb9d;
$userNextPaymentBoxShadowColor: rgba($userNextPaymentBorderColor, 100%);
$userMissingPaymentBorderColor: #fecdc7;
$userMissingPaymentBoxShadowColor: rgba($userMissingPaymentBorderColor, 100%);
$userPaidPaymentBorderColor: #c0e9cc;
$userPaidPaymentBoxShadowColor: rgba($userPaidPaymentBorderColor, 100%);

// a:hover transition
$defaultTransitionTime: 0.3s;

// -- Static pages --
$aboutUsLineHeight: 1.85;

// -- z-index --
$headerZIndex: 600;
$mainMenuZIndex: 700;
$filtersZIndex: 400;
$popupZIndex: 610; // must be higher than $headerZIndex
$modalZIndex: 1020;

// -- Modal --
$modalBorderColor: $borderHoverColor;
$modalFooterStartGradientColor: rgba(255, 255, 255, 1);
$modalFooterEndGradientColor: rgba(255, 255, 255, 0);
$modalBackgroundColor: rgba(0, 0, 0, 0.7);

// -- Car colors --
$carColorBlack: #000000;
$carColorBlue: #0000ff;
$carColorBrown: #800000;
$carColorGray: #808080;
$carColorGreen: #008000;
$carColorOrange: #e67e22;
$carColorPink: #ff00ff;
$carColorPurple: #800080;
$carColorRed: #ff0000;
$carColorSilver: #c0c0c0;
$carColorWhite: #ffffff;
$carColorYellow: #f4d03f;
$carColorCyan: #00ffff;

// -- Box arrow size --
$boxTriangleArrowSize: 7px;

$newsletterSubscriptionIconBackgroundColor: #dce0e7;
