.error-heading {
    text-align: center;
}

.error-page {
    padding-top: $smallScreenMargin;
    text-align: center;

    p {
        font-size: $slightlyBiggerFontSize;
    }

    img {
        display: block;
        height: auto;
        margin: $defaultMargin auto;
        max-width: 100%;
    }

    .button {
        margin-top: $defaultMargin;
    }
}
