@use "sass:math";

footer {
    background-color: $footerBackgroundColor;
    color: $footerTextColor;
    margin-top: auto;
    overflow: auto;
    padding-top: $defaultMargin;

    a {
        color: $inverseTextColor;
        text-decoration: none;

        &:hover {
            color: $lighterTextColor;
            text-decoration: underline;
        }
    }

    .driveto-symbol {
        height: auto;
        max-width: 70%;
        width: 100%;
    }

    .footer-box {
        &.subscription {
            .menu {
                font-size: $footerContactFontSize;
                font-weight: $boldFontWeight;
                text-decoration: underline;
            }
        }
    }

    .help-contacts {
        &:nth-of-type(1) {
            margin-bottom: $defaultMargin * 1.5;
        }

        .title {
            color: $inverseTextColor;
            display: block;
            margin-bottom: math.div($defaultMargin, 6);
        }

        .phone {
            &:nth-of-type(1) {
                margin-bottom: $defaultMargin;
            }
        }

        a {
            display: block;
            font-size: $footerContactFontSize;
            font-weight: $boldFontWeight;
            padding: math.div($defaultMargin, 3) 0;
        }

        span {
            display: block;
            margin-top: math.div($defaultMargin, 6);
        }
    }

    .section-title {
        color: $footerHeadingColor;
        font-size: $footerContactFontSize;
        letter-spacing: 0.15rem;
        margin-bottom: $defaultMargin * 0.5;
    }

    .bar {
        padding-bottom: $defaultMargin;
        padding-top: $defaultMargin;
    }

    .footer-menu-container {
        .footer-box {
            .section-title {
                font-weight: $boldFontWeight;
            }

            &:first-child {
                display: none;
            }
        }

        .menu {
            list-style: none;
            margin-bottom: $defaultMargin;

            li {
                color: $footerLinksColor;
                padding: 0;
            }
        }
    }

    .copyright {
        color: $inverseTextColor;
        text-align: center;
    }
}

#footer-social {
    .footer-social-links {
        margin-bottom: $defaultMargin;
        text-align: left;

        a {
            display: inline-block;
            margin-bottom: $defaultMargin * 0.5;
            white-space: nowrap;

            &:hover {
                text-decoration: underline;
            }

            &:not(:last-of-type) {
                margin-right: $defaultMargin * 0.5;
            }

            .icon {
                background-color: $footerTextColor;
                border-radius: 50%;
                display: inline-block;
                height: 35px;
                margin-right: $defaultMargin * 0.25;
                padding: $defaultMargin * 0.25;
                width: 35px;
            }

            svg {
                fill: $footerBackgroundColor;
            }
        }
    }
}

@media (min-width: 580px) {
    footer {
        .footer-menu-container {
            @include flex-grid;

            .footer-box {
                padding-right: $defaultMargin * 0.5;
            }
        }
    }
}

@media (min-width: 580px) and (max-width: 999px) {
    footer {
        padding-top: $defaultMargin * 2;

        .footer-menu-container {
            .footer-box {
                @include boxes-per-row(2, 0);
            }
        }
    }
}

@include desktop {
    footer {
        padding-top: $defaultMargin * 3;

        .footer-menu-container {
            .footer-box {
                &:first-child {
                    display: block;
                    width: 10%;
                }

                &:nth-child(n+2) {
                    margin-left: 2%;
                    width: 28%;
                }
            }
        }

        .copyright {
            align-items: center;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin-left: 12%;
            margin-right: 8%;
        }
    }

    #footer-social {
        .footer-social-links {
            margin-bottom: 0;

            a {
                &:not(:last-of-type) {
                    margin-right: $smallScreenMargin;
                }

                .icon {
                    margin-right: $defaultMargin * 0.5;
                }
            }
        }
    }
}
