.subscription-banner-container {
    margin-bottom: $smallScreenMargin;
    overflow: hidden;
    padding: $smallScreenMargin $defaultMargin * 0.5;
    position: relative;

    picture {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &::before {
        background:
        linear-gradient(90deg, $bannerBackgroundColor, $bannerBackgroundColor 50%, transparent 100%, transparent 100%);
        bottom: 0;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 2;
    }

    .banner-promo-text {
        align-items: center;
        display: flex;
        flex-direction: column;

        span {
            background-color: $brandColor;
            color: $inverseTextColor;
            display: inline-block;
            font-size: $h2LargeScreenFontSize;
            font-weight: $boldFontWeight;
            padding: $defaultMargin * 0.125 $defaultMargin * 0.75;
            text-transform: uppercase;
            white-space: nowrap;
            width: fit-content;

            &:first-child {
                transform: rotate(-5deg) translateX(-10px) translateY(-5px);
            }

            &:nth-child(2) {
                transform: rotate(3deg);
            }
        }
    }

    &.container {
        padding: $smallScreenMargin $defaultMargin * 0.5;
    }

    &:not(.container) {
        border-radius: $largeBorderRadius;
        padding: $smallScreenMargin $defaultMargin;
    }

    .title {
        color: $inverseTextColor;
    }

    .list-title {
        color: $inverseTextColor;
        font-size: $biggerFontSize;
        font-weight: $mediumFontWeight;
        margin-bottom: $defaultMargin;
    }

    .list {
        color: $inverseTextColor;
        list-style: none;
        margin-bottom: $defaultMargin;

        .item {
            font-size: $slightlyBiggerFontSizePx;
            line-height: $defaultLineHeight;
            margin-left: 3.5rem;

            &::before {
                background: url("/assets/icon/check-ok-white.svg") 50% 50% no-repeat transparent;
                background-size: 100%;
                color: transparent;
                content: " ";
                display: inline-block;
                height: 2.4rem;
                margin: -0.4rem 0.8rem -0.2rem -3.5rem;
                vertical-align: middle;
                width: 2.4rem;
            }
        }
    }

    .banner-container-column {
        min-height: 120px;
        position: relative;
        z-index: 3;

        .buttons-container {
            display: flex;

            .button {
                &:nth-of-type(2) {
                    margin-left: $defaultMargin * 0.5;
                }
            }
        }
    }
}

@include tablet-desktop {
    .subscription-banner-container {
        &:not(.authentication) {
            border-radius: $largeBorderRadius $largeBorderRadius $largeBorderRadius $largeBorderRadius * 3;
            padding: $smallScreenMargin;

            &.container {
                margin-top: $defaultMargin;
                padding: $smallScreenMargin;
            }

            .title {
                @include big-headline-font-size;
            }

            .list {
                display: flex;
                flex-wrap: wrap;

                .item {
                    padding-left: $defaultMargin * 1.5;
                    @include boxes-per-row(2, 2%);
                }
            }
        }
    }
}

@include desktop {
    .subscription-banner-container {
        &:not(.authentication) {
            display: flex;
            margin-bottom: $largeScreenMargin;
            padding: $largeScreenMargin;

            .banner-promo-text {
                span {
                    font-size: 34px;

                    &:first-child {
                        transform: rotate(-5deg) translateX(-10px) translateY(-5px);
                    }

                    &:nth-child(2) {
                        transform: rotate(3deg);
                    }
                }
            }

            &.container {
                padding: $largeScreenMargin;
            }

            &:not(.container) {
                padding: $largeScreenMargin;
            }
        }
    }
}

@media (min-width: 1100px) {
    .subscription-banner-container {
        min-height: 480px;

        &:not(.authentication) {
            &::before {
                background:
                linear-gradient(
                    90deg,
                    $bannerBackgroundColor,
                    $bannerBackgroundColor 30%,
                    transparent 75%,
                    transparent 100%
                );
            }

            .banner-container-columns {
                display: flex;
                width: 100%;

                .banner-container-column {
                    @include boxes-per-row(2, 2%);

                    &:nth-of-type(1) {
                        order: 2;
                    }

                    &:nth-of-type(2) {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        order: 1;
                    }
                }
            }
        }
    }
}
