@use "sass:math";

.modal {
    @include popup-slide-from-left;

    z-index: $modalZIndex;

    &.open {
        left: 0;
    }
}

.modal-content {
    background-color: $defaultBackgroundColor;
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    overflow: hidden;
    padding: $defaultMargin * 0.5;
    position: absolute;
    right: 0;
    top: 0;

    .close-modal {
        display: inline-block;
        margin-bottom: $defaultMargin * 0.5;

        img {
            display: none;
        }
    }

    .modal-header {
        flex: none;
        padding-bottom: $defaultMargin * 0.5;
        padding-top: $defaultMargin * 0.5;

        .modal-headline {
            text-align: center;
            @include bigger-default-headline-font-size(false);
        }
    }

    .modal-body {
        margin-right: -$defaultMargin * 0.5;
        overflow-y: auto;
        padding-right: $defaultMargin * 0.5;
    }

    .modal-footer {
        background-color: $defaultBackgroundColor;
        display: block;
        margin-bottom: -$defaultMargin * 0.75;
        padding: $defaultMargin * 0.5;
        position: relative;
        text-align: center;

        &::before {
            background: linear-gradient(0deg, $modalFooterStartGradientColor 0%, $modalFooterEndGradientColor 100%);
            bottom: 100%;
            content: " ";
            display: block;
            height: 20px;
            left: 0;
            position: absolute;
            right: math.div($defaultMargin, 6);
            z-index: 10;
        }

        .modal-footer-scroll {
            color: $brandColor;

            &::before {
                animation: jump 1.5s ease 0s infinite normal;
                background: url("/assets/icon/arrow-up.svg") 0 50% no-repeat transparent;
                background-size: 100%;
                content: " ";
                display: inline-block;
                height: 1.3rem;
                margin-right: $defaultMargin * 0.25;
                vertical-align: middle;
                width: 1.3rem;
            }
        }
    }
}

@include mobile-tablet {
    .modal {
        @include own-transition(left);
    }
}

@include desktop {
    .modal {
        background-color: $modalBackgroundColor;
        display: none;
        height: 100%;
        left: 0;
        overflow: auto;
        width: 100%;
        @include fade-in-from-none;

        &.open {
            display: block;
        }
    }

    .modal-content {
        border-radius: $defaultBorderRadius;
        bottom: $defaultMargin * 2;
        margin: 0 auto;
        max-width: $desktopBreakpoint;
        padding: $defaultMargin * 2 $defaultMargin * 1.75 $defaultMargin * 1.5 $defaultMargin * 2;
        top: $defaultMargin * 2;
        width: 80%;

        .close-modal {
            cursor: pointer;
            padding: $defaultMargin;
            position: absolute;
            right: math.div($defaultMargin, 6);
            top: 0;

            &:hover {
                img {
                    transform: rotate(90deg);
                }
            }

            img {
                display: block;
                width: $defaultMargin;
                @include own-transition(transform);
            }

            span {
                display: none;
            }
        }

        .modal-header {
            min-height: 40px;
            padding-top: 0;
        }

        .modal-body {
            padding-right: $defaultMargin * 0.75;
        }
    }
}

@keyframes jump {
    0% {
        transform: translateY(0) rotate(180deg);
    }

    25% {
        transform: translateY(-2px) rotate(180deg);
    }

    50% {
        transform: translateY(0) rotate(180deg);
    }

    75% {
        transform: translateY(2px) rotate(180deg);
    }

    100% {
        transform: translateY(0) rotate(180deg);
    }
}
