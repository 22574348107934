.driveto-highlights-container {
    .highlights-title {
        color: $headlineTextColor;
        font-size: $h3SmallScreenFontSize;
        margin-bottom: $defaultMargin * 0.5;
        text-transform: uppercase;
    }

    .driveto-highlights-box {
        text-align: center;

        &:not(:last-of-type) {
            border-bottom: 1px solid $borderLightColor;
            margin-bottom: $defaultMargin;
            padding-bottom: $defaultMargin;
        }

        .title {
            font-weight: $boldFontWeight;
            margin-bottom: 0;
            @include big-headline-font-size;
        }

        .text {
            font-size: $slightlyBiggerFontSizePx;
        }

        .button-light {
            margin-top: $defaultMargin * 0.25;
        }
    }
}

@include tablet-desktop {
    .driveto-highlights-container {
        .driveto-highlights-boxes {
            display: flex;
        }

        .driveto-highlights-box {
            @include boxes-per-row(3, 3%);

            &:not(:last-of-type) {
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}

@include desktop {
    .driveto-highlights-container {
        margin: 0 auto;
        max-width: $desktopBreakpoint;

        .driveto-highlights-box {
            .title {
                font-size: 3.8rem;
            }

            .text {
                font-size: $h3SmallScreenFontSize;
                margin: 0 auto;
                max-width: 70%;
            }
        }
    }
}
