.top-offers-list-container {
    margin-bottom: $smallScreenMargin;

    &.similar-offers-container {
        margin-top: $smallScreenMargin;
    }

    .section-title {
        @include bigger-default-headline-font-size;
    }

    .buttons-container {
        text-align: center;
    }
}

@include desktop {
    .top-offers-list-container {
        margin-bottom: $largeScreenMargin;

        &.similar-offers-container {
            margin-top: $largeScreenMargin;
        }

        .buttons-container {
            margin-top: $defaultMargin;
        }
    }
}
