@use "sass:math";

.abandoned-inquiry-car-modal {
    border-radius: $defaultBorderRadius;
    display: none;
    max-width: 360px;
    padding: $defaultMargin;
    right: $defaultMargin;
    transition: box-shadow $defaultTransitionTime ease, transform $defaultTransitionTime ease;
    z-index: $headerZIndex - 1;
    @include hover-box-border;
    @include own-box-shadow(0, 10px);

    &:not(.fixed) {
        position: absolute;
        top: 165px;
    }

    &.fixed {
        position: fixed;
        top: $defaultMargin;
        @include own-transition(transform);
    }

    .content {
        align-items: center;
        display: flex;
        margin-bottom: $defaultMargin * 0.5;

        .car-offer-image {
            border: 1px solid $borderLightColor;
            border-radius: $defaultBorderRadius $defaultBorderRadius $defaultBorderRadius $defaultBorderRadius * 2;
            flex: none;
            height: 80px;
            margin-right: math.div($defaultMargin, 3);
            overflow: hidden;
            width: 120px;
        }

        .link {
            .no-wrap {
                font-weight: $mediumFontWeight;
            }
        }
    }

    .button-primary {
        width: 100%;
    }

    .close-modal {
        background-color: transparent;
        background-image: url("/assets/icon/close.svg");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 100%;
        cursor: pointer;
        display: inline-block;
        height: 2rem;
        padding: math.div($defaultMargin, 6);
        position: absolute;
        right: math.div($defaultMargin, 3);
        top: $defaultMargin * 0.5;
        width: 2rem;
    }
}

@include desktop {
    .abandoned-inquiry-car-modal {
        display: block;
    }
}
