@use "sass:math";

.small-screen-menu-container {
    background-color: $defaultBackgroundColor;
    border-top: 2px solid $borderLightColor;
    position: relative;

    .menu {
        list-style: none;
        margin: 0;

        & > li {
            display: inline-block;
            margin: 0;
            white-space: nowrap;

            & > a {
                color: $mobileMenuLinksColor;
                display: inline-block;
                padding: math.div($defaultMargin, 3) $defaultMargin * 0.25;
                position: relative;
                text-decoration: none;
                @include own-transition(color);

                .homepage-icon {
                    filter: brightness(0%);
                    margin-top: -0.3rem;
                }

                &:focus,
                &:hover {
                    color: $brandColor;
                }
            }

            &.current-menu-item {
                & > a {
                    color: $brandColor;

                    &::after {
                        border-bottom: 3px solid $brandColor;
                        border-radius: 2px;
                        bottom: -2px;
                        content: "";
                        display: inline-block;
                        left: $defaultMargin * 0.25;
                        position: absolute;
                        right: $defaultMargin * 0.25;
                        z-index: $headerZIndex + 1;
                    }

                    .homepage-icon {
                        filter: none;
                    }
                }
            }
        }
    }

    .small-device-hidden {
        display: none;
    }
}

@media (min-width: $smallestDeviceBreakpoint) {
    .small-screen-menu-container {
        .container {
            padding: 0;
        }

        .small-device-hidden {
            display: inline;
        }

        .menu {
            & > li {
                & > a {
                    padding: math.div($defaultMargin, 3) $defaultMargin * 0.5 $defaultMargin * 0.25;
                }

                &.current-menu-item {
                    & > a {
                        &::after {
                            left: $defaultMargin * 0.5;
                            right: $defaultMargin * 0.5;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $mainMenuBreakpoint) {
    .small-screen-menu-container {
        display: none;
    }
}
