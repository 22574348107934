@mixin select {
    -webkit-appearance: none;
    appearance: none;
    background-image: url("/assets/icon/expand-arrow.svg");
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-size: 1rem;
    cursor: pointer;
    font-weight: $mediumFontWeight;
    padding-right: $defaultMargin;

    option {
        &:checked {
            color: $brandColor;
            font-weight: $boldFontWeight;
        }
    }

    &::-ms-expand {
        display: none;
    }
}
