// Box mixin
@use "sass:math";

@mixin root-prop($prop: null, $value: null) {
    @if ($prop and $value) {
        #{$prop}: $value;
    }
}

@mixin box-border($borderWidth: 2px, $backgroundColor: $defaultBackgroundColor) {
    background-color: $backgroundColor;
    border: $borderWidth solid $borderLightColor;
}

// box visual action on hover
@mixin hover-box-border($borderWidth: 1px, $backgroundColor: $defaultBackgroundColor) {
    @include box-border($borderWidth, $backgroundColor);
    @include own-transition(border-color);

    &:hover {
        border-color: $borderHoverColor;
    }
}

// Box shadow mixin, x-offset forced 0
@mixin own-box-shadow($y-offset: 3px, $blur: 27px, $alpha: 0.1) {
    box-shadow: 0 $y-offset $blur rgba(0, 0, 0, $alpha); // sass-lint:disable-line no-color-literals
}

@mixin hover-box-shadow($y-offset: 3px, $blur: 27px, $alpha: 0.1) {
    @include own-transition(box-shadow);

    &:hover {
        @include own-box-shadow($y-offset, $blur, $alpha);
    }
}

@mixin hover-box-border-with-box-shadow(
    $borderWidth: 2px, $backgroundColor: $defaultBackgroundColor, $y-offset: 3px, $blur: 27px, $alpha: 0.1
) {
    transition: box-shadow $defaultTransitionTime, border-color $defaultTransitionTime;
    @include box-border($borderWidth, $backgroundColor);

    &:hover {
        border-color: $borderHoverColor;
        @include own-box-shadow($y-offset, $blur, $alpha);
    }
}

@mixin box-absolute-gradient-background {
    background: linear-gradient(0deg, $cardFooterGradientColor, transparent);
}

@mixin box-absolute-gradient($padding-bottom: 0) {
    bottom: 0;
    left: 0;
    padding: $defaultMargin * 0.5 $defaultMargin * 0.5 $padding-bottom;
    position: absolute;
    right: 0;

    @include box-absolute-gradient-background;
}

// Self Clearing Goodness
@mixin utility-clearfix {
    clear: both;
    content: "";
    display: table;
}

@mixin list {
    padding-left: 2rem;
    text-indent: -2rem;
}

@mixin list-marks($size: 1.5rem) {
    color: transparent;
    display: inline-block;
    height: $size;
    vertical-align: middle;
    width: $size;
}

@mixin yes-check($margin-left: 0, $margin-right: 0.2rem, $size: 1.5rem) {
    @include list-marks($size);

    background: url("/assets/icon/check-ok.svg") 0 50% no-repeat transparent;
    background-size: 100%;
    content: " ";
    margin: -0.4rem $margin-right -0.2rem $margin-left;
}

@mixin no-cross($margin-left: 0, $margin-right: 0.2rem, $size: 1.5rem) {
    @include list-marks($size);

    background: url("/assets/icon/cross-no.svg") 0 50% no-repeat transparent;
    background-size: 100%;
    content: " ";
    margin: -0.2rem $margin-right 0 $margin-left;
}

@mixin unknown($margin-left: 0, $margin-right: 0.2rem, $size: 1.5rem) {
    @include list-marks($size);

    background: url("/assets/icon/help.svg") 0 50% no-repeat transparent;
    background-size: 100%;
    content: " ";
    margin: -0.2rem $margin-right 0 $margin-left;
}

@mixin check-ok-list($item-margin-bottom: 0) {
    list-style: none;
    margin: 0;

    li {
        margin: 0 0 $item-margin-bottom 2.8rem;

        &::before {
            @include yes-check(-2.4rem, 0.8rem);
        }
    }
}

@mixin check-ok-list-with-border($item-margin-bottom: 0) {
    list-style: none;
    margin: 0;

    li {
        display: flex;
        margin: 0 0 $item-margin-bottom 2.8rem;

        &::before {
            background: url("/assets/icon/check-ok-green.svg") 20% 50% no-repeat transparent;
            background-size: contain;
            border-radius: 50%;
            content: " ";
            display: inline-block;
            flex: none;
            height: 2.4rem;
            margin: 0 8px 0 -24px;
            vertical-align: middle;
            width: 2.4rem;
        }
    }
}

@mixin own-transition($transitionProperty, $timingFunction: ease) {
    transition: $transitionProperty $defaultTransitionTime $timingFunction;

    @if ($transitionProperty == transform) {
        will-change: transform;
    }

    @if ($transitionProperty == opacity) {
        will-change: opacity;
    }
}

@mixin popup-slide-from-left($backgroundColor: $defaultBackgroundColor) {
    -webkit-overflow-scrolling: touch;
    background-color: $backgroundColor;
    bottom: 0;
    left: -100%;
    overflow-y: scroll;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    will-change: left;
    z-index: $popupZIndex;
    @include own-transition(left);
}

@mixin text-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin default-font-family {
    font-family:
    DMSans, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

@mixin default-headline-font-size {
    font-size: $h2SmallScreenFontSize;

    @include tablet-desktop {
        font-size: $h2LargeScreenFontSize;
    }
}

@mixin bigger-default-headline-font-size($withBottomMargin: true) {
    font-size: $h1SmallScreenFontSize;

    @include tablet-desktop {
        font-size: $h1LargeScreenFontSize;
        @if ($withBottomMargin == true) {
            margin-bottom: $defaultMargin;
        }
    }
}

@mixin big-headline-font-size {
    font-size: $h1LargeScreenFontSize;
    line-height: 1.2;

    @include desktop {
        font-size: $extremeBigFontSize;
    }
}

@mixin bcg-placeholder {
    background-color: transparent;
    background-image: url("/assets/icon/img-placeholder.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

html {
    &.no-js {
        .no-js-hidden {
            display: none;
        }
    }

    &.js {
        .js-hidden {
            display: none;
        }
    }

    &.mobile-no-scroll {
        overflow: hidden;
    }
}

body {
    &.mobile-no-scroll {
        overflow: auto;
    }
}

.hidden {
    display: none;
}

.cf-turnstile {
    min-height: 20.3px;
}

// Disables scrolling of element.
// Applied to body when overlay is open.
// height: 100vh and -webkit-overflow-scrolling used because iOS
// ignores overflow:hidden and keeps scrolling body on fast swipe (momentum scrolling).
// Actually works opposite to docs:
// https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-overflow-scrolling
.no-scroll {
    overflow: hidden;
}

.mobile-no-scroll {
    -webkit-overflow-scrolling: touch;
    bottom: 0;
    left: 0;
    position: relative;
    right: 0;
    top: 0;
    touch-action: none;
}

.open-popup-checkbox {
    display: none;
}

.close-popup-arrow-before {
    &::before {
        content: "\2190";
        display: inline-block;
        margin-right: math.div($defaultMargin, 6);
        margin-top: -0.2rem;
        vertical-align: top;
    }
}

.text-center {
    text-align: center;
}

.no-wrap {
    white-space: nowrap;
}

.show-more-container {
    .hidden-content {
        display: none;
    }

    .show-more {
        border-bottom: 1px solid $defaultTextColor;
        color: $defaultTextColor;
        cursor: pointer;
        display: inline-block;
        font-size: $defaultFontSize;
        margin-top: $defaultMargin * 0.5;
        padding-bottom: math.div($defaultMargin, 12);
        text-decoration: none;
        width: fit-content;

        &::after {
            background-color: $defaultTextColor;
            content: " ";
            display: inline-block;
            height: 1.2rem;
            margin: -0.2rem 0 0 math.div($defaultMargin, 6);
            mask: url("/assets/icon/expand-arrow.svg") no-repeat 50% 50%;
            mask-size: contain;
            vertical-align: middle;
            width: 1.2rem;
        }

        &.active {
            &::after {
                transform: rotate(180deg);
            }
        }
    }
}

.svg-sprite {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.img-fluid {
    height: auto;
    width: 100%;
}

img {
    &:not([src]) {
        display: none;
    }
}

.video-container {
    border-radius: $defaultBorderRadius;
    overflow: hidden;
    position: relative;
    width: 100%;

    &::after {
        content: "";
        display: block;
        padding-top: 56.25%;
    }

    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
