.server-name-label {
    padding: $defaultMargin * 0.5;
    position: fixed;
    right: $defaultMargin;
    text-align: center;
    top: $defaultMargin;
    z-index: 1400;

    &.dev {
        background-color: $yellowLabelBackgroundColor;
    }

    &.prod {
        background-color: $redLabelBackgroundColor;
    }

    &.preprod {
        background-color: $orangeLabelBackgroundColor;
    }

    &.local {
        background-color: $accentedTextColor;
    }

    .server-name {
        color: $inverseTextColor;
        font-size: 2rem;
        font-weight: $boldFontWeight;
        text-transform: uppercase;
    }
}

@include desktop {
    .server-name-label {
        padding: $defaultMargin;

        .server-name {
            font-size: 3rem;
        }
    }
}
