@use "sass:math";

.most-frequent-questions {
    background-color: $defaultBackgroundColor;
    counter-reset: boxes-counter;

    &.dashboard {
        margin-bottom: $smallScreenMargin;
    }

    .show-all {
        margin-top: $defaultMargin;
        text-align: center;
    }

    .question-container {
        border-radius: $defaultBorderRadius;
        display: flex;
        @include hover-box-border;

        &:not(:last-of-type) {
            margin-bottom: $defaultMargin * 0.5;
        }

        .content {
            width: 100%;

            h5 {
                margin-bottom: 0;
                width: 70%;
            }

            .question {
                align-items: center;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                min-height: 60px;
                padding: $defaultMargin * 0.5;
                width: 100%;

                .title {
                    font-size: $h5SmallScreenFontSize;
                    font-weight: $boldFontWeight;
                }
            }

            .answer {
                counter-reset: boxes-counter;
                display: none;
                padding: 0 $defaultMargin * 0.5 $defaultMargin * 0.5;

                h6 {
                    font-size: $slightlyBiggerFontSizePx;

                    &::before {
                        content: counter(boxes-counter) ".";
                        counter-increment: boxes-counter;
                        display: inline-block;
                        margin-right: $defaultMargin * 0.25;
                    }
                }
            }
        }

        .show-answer {
            height: 15px;
            min-width: 15px;
            position: relative;
            width: 15px;

            &::before,
            &::after {
                background-color: $brandColor;
                content: "";
                display: block;
                height: 3px;
                left: 0;
                position: absolute;
                top: 50%;
                transition: $defaultTransitionTime;
                width: 100%;
            }

            &::before {
                transform: translatey(-50%);
            }

            &::after {
                transform: translatey(-50%) rotate(90deg);
            }
        }

        &.active {
            .show-answer {
                &::before {
                    opacity: 0;
                    transform: translatey(-50%) rotate(-90deg);
                }

                &::after {
                    transform: translatey(-50%) rotate(0);
                }
            }
        }
    }
}

@include tablet-desktop {
    .most-frequent-questions {
        .question-container {
            .show-answer {
                flex: inherit;
                margin-left: $defaultMargin;
                white-space: nowrap;
            }

            .content {
                .question {
                    min-height: 80px;
                    padding: $defaultMargin;
                }

                .answer {
                    padding: 0 $defaultMargin $defaultMargin;
                }
            }

            &::before {
                align-items: flex-start;
                border-right: 1px solid $borderLightColor;
                color: $lighterTextColor;
                content: counter(boxes-counter);
                counter-increment: boxes-counter;
                display: flex;
                font-size: 3rem;
                font-weight: $boldFontWeight;
                justify-content: center;
                line-height: 1;
                padding: $defaultMargin $defaultMargin * 0.5;
                text-align: center;
                width: 60px;
            }

            &.active {
                .show-answer {
                    &::before {
                        line-height: 0.6;
                    }
                }
            }
        }
    }
}

@include desktop {
    .landing-page-most-frequent-questions {
        padding: $largeScreenMargin 0;
    }

    .most-frequent-questions {
        &.dashboard {
            margin-bottom: $largeScreenMargin;
        }

        .show-all {
            margin-top: $defaultMargin * 2;
        }

        .container {
            max-width: 800px;
        }

        .question-container {
            display: flex;

            .show-answer {
                height: 25px;
                min-width: 25px;
                width: 25px;
            }

            &::before {
                padding: $defaultMargin;
                width: 80px;
            }
        }
    }
}
