@mixin fade-in-from-none($animationTime: $defaultTransitionTime) {
    animation: fadeIn $animationTime ease-out;
    will-change: opacity;
}

@mixin slide-down-from-none($animationTime: $defaultTransitionTime) {
    animation: slideDown $animationTime ease-out;
    transform-origin: left top;
    will-change: transform;
}

@mixin fade-in-animation($duration, $delay) {
    animation-delay: $delay;
    animation-duration: $duration;
    animation-fill-mode: forwards;
    animation-name: fadeIn;
    animation-timing-function: ease-in;
    opacity: 0;
    will-change: opacity;
}

@mixin fade-in-from-left-animation($duration, $delay) {
    animation-delay: $delay;
    animation-duration: $duration;
    animation-fill-mode: forwards;
    animation-name: fadeInFromLeft;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    will-change: left, opacity;
}

@mixin bounce-in-animation($duration, $delay) {
    animation-delay: $delay;
    animation-duration: $duration;
    animation-fill-mode: forwards;
    animation-name: bounceIn;
    animation-timing-function: linear;
    opacity: 0;
    will-change: opacity, transform;
}

@mixin spin-animation($duration) {
    animation-duration: $duration;
    animation-fill-mode: forwards;
    animation-name: spin;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: transform;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideDown {
    0% {
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes fadeInFromLeft {
    0% {
        left: -50%;
        opacity: 0;
    }

    100% {
        left: 0;
        opacity: 1;
    }
}

@keyframes bounceIn {
    0% {
        opacity: 0;
        transform: scale(0.3) translate3d(0, 0, 0);
    }

    50% {
        opacity: 0.9;
        transform: scale(1.1);
    }

    80% {
        opacity: 1;
        transform: scale(0.89);
    }

    100% {
        opacity: 1;
        transform: scale(1) translate3d(0, 0, 0);
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
