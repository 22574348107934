@use "sass:math";

.favorite-offer-label {
    background-color: $accentedBackgroundColor;
    border-radius: 50%;
    height: 35px;
    margin: $defaultMargin * 0.25 $defaultMargin * 0.25 math.div($defaultMargin, 6);
    padding: math.div($defaultMargin, 3) $defaultMargin * 0.25;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 35px;

    &::after {
        content: url("/assets/icon/favorite-add.svg");
        display: inline-block;
        line-height: 1;
    }
}
