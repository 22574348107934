.subscription-how-it-works-benefits {
    border-bottom: 1px solid $borderLightColor;
    border-top: 1px solid $borderLightColor;
    margin-bottom: $smallScreenMargin;
    margin-top: $smallScreenMargin;
    padding-bottom: $smallScreenMargin;
    padding-top: $smallScreenMargin;

    .boxes-content {
        display: none;
    }

    .benefits-box {
        &:nth-of-type(1) {
            .benefit {
                display: none;
                margin-top: $defaultMargin;
                max-width: 450px;

                .counter-container {
                    min-width: 30px;
                    text-align: center;
                }

                .text {
                    color: $darkTextColor;
                    font-size: $slightlyBiggerFontSizePx;
                    margin-bottom: $defaultMargin;
                }
            }

            .scroll-control-container {
                align-items: center;
                display: flex;
                margin-bottom: $defaultMargin;

                .scroll-control {
                    cursor: pointer;
                    display: inline-block;

                    &:hover {
                        &::after {
                            border-color: $borderHoverColor;
                        }
                    }

                    &::after {
                        background:
                        url("/assets/icon/right-arrow-orange.svg") 50% 50% no-repeat $defaultBackgroundColor;
                        background-size: 35%;
                        border: 2px solid $borderLightColor;
                        border-radius: $defaultBorderRadius * 1.5;
                        content: " ";
                        display: inline-block;
                        height: 4rem;
                        width: 4rem;
                        @include own-transition(transform);
                    }

                    &.right {
                        margin-left: $defaultMargin * 0.5;
                    }

                    &.left {
                        margin-right: $defaultMargin * 0.5;

                        &::after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }


        &.most-frequent-questions {
            .question-container {
                align-items: center;
                border-radius: $defaultBorderRadius;
                cursor: pointer;
                margin-bottom: $defaultMargin * 0.5;
                @include hover-box-border(1px);

                .question {
                    justify-content: flex-start;

                    .title {
                        font-size: $slightlyBiggerFontSizePx;
                        text-align: center;
                    }

                    .icon {
                        flex: none;
                        height: auto;
                        margin-right: $defaultMargin;
                        width: 30px;
                    }

                    .show-answer {
                        margin-left: auto;
                    }
                }
            }

            .button {
                margin-top: $defaultMargin * 0.5;
            }
        }
    }
}

@include tablet-desktop {
    .subscription-how-it-works-benefits {
        .benefits-container {
            .benefits-box {
                &.most-frequent-questions {
                    .question-container {
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@include desktop {
    .subscription-how-it-works-benefits {
        margin-bottom: $largeScreenMargin;
        margin-top: $largeScreenMargin;
        padding-bottom: $largeScreenMargin;
        padding-top: $largeScreenMargin;

        .container {
            max-width: $desktopBreakpoint;
        }

        .benefits-container {
            align-items: flex-start;
            display: flex;

            .section-title {
                font-size: $h1LargeScreenFontSize;
            }

            .boxes-content {
                display: block;
            }

            .benefits-box {
                @include boxes-per-row(2, 5%);

                &:nth-of-type(1) {
                    .benefit {
                        display: block;
                    }
                }

                &.most-frequent-questions {
                    display: flex;
                    flex-wrap: wrap;

                    .button {
                        display: none;
                    }

                    .question-container {
                        @include boxes-per-row(2, 2%);

                        &.active {
                            border-color: $brandColor;
                            outline: 1px solid $brandColor;
                            @include own-box-shadow(5px, 10px);
                        }

                        &::before,
                        .answer,
                        .show-answer {
                            display: none !important;
                        }

                        .question {
                            align-items: center;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            min-height: 120px;
                            padding: $defaultMargin * 0.5 $defaultMargin * 1.5;

                            .icon {
                                margin-bottom: $defaultMargin * 0.25;
                                margin-right: 0;
                                width: 45px;
                            }
                        }
                    }
                }
            }
        }
    }
}
