// sanitize.css v4.1.0 | CC0 License | github.com/jonathantneal/sanitize.css

// 2. Add box sizing inheritence in all browsers (opinionated).
*,
::before,
::after {
    box-sizing: inherit;
}

// 1. Add text decoration inheritance in all browsers (opinionated).
// 2. Add vertical alignment inheritence in all browsers (opinionated).
::before,
::after {
    text-decoration: inherit; // 1
    vertical-align: inherit; // 2
}

// 1. Add border box sizing in all browsers (opinionated).
// 2. Add the default cursor in all browsers (opinionated).
// 3. Prevent font size adjustments after orientation changes in IE and iOS.

html {
    box-sizing: border-box; // 1
    cursor: default; // 2
    text-size-adjust: 100%; // 3
}

// Remove the margin in all browsers (opinionated).
body {
    margin: 0;
}

// reset definition list margin
dl,
dl > dd {
    margin: 0;
}

// Change the alignment on media elements in all browers (opinionated).
audio,
iframe,
img,
svg {
    vertical-align: middle;
}

// Remove the border on images inside links in IE 10-.
img {
    border-style: none;
}

// Change the fill color to match the text color in all browsers (opinionated).
svg {
    fill: currentColor;

    // Hide the overflow in IE.
    &:not(:root) {
        overflow: hidden;
    }
}

// Reset table borders
table {
    border-collapse: collapse;
}

// Remove the tapping delay on clickable elements (opinionated).
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
    touch-action: manipulation;
}

// Add the correct display in IE 10-.
[hidden] {
    display: none;
}

// -- ARIA in HTML (https://w3c.github.io/html-aria/) --

// Change the cursor on busy elements (opinionated).
[aria-busy="true"] {
    cursor: progress;
}

// Change the cursor on control elements (opinionated).
[aria-controls] {
    cursor: pointer;
}

// Change the display on visually hidden accessible elements (opinionated).
[aria-hidden="false"] {
    &[hidden] {
        clip: rect(0, 0, 0, 0);
        display: inherit;
        position: absolute;

        &:focus {
            clip: auto;
        }
    }
}

// Change the cursor on disabled, not-editable, or otherwise inoperable elements (opinionated).
[aria-disabled] {
    cursor: default;
}
