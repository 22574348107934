@mixin headline-font-family {
    font-family:
    Poppins, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

html {
    font-size: 62.5%;
}

body {
    -moz-font-feature-settings: "liga" on;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: subpixel-antialiased;
    color: $defaultTextColor;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: $defaultFontSize;
    font-weight: $defaultFontWeight;
    line-height: $defaultLineHeight;
    min-height: 100vh;
    overflow-anchor: auto;
    scroll-behavior: smooth;
    text-rendering: optimizeLegibility;
}

.fonts-loaded {
    body {
        font-family:
        DMSans, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include headline-font-family;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $headlineTextColor;
    font-weight: $headlineFontWeight;
    line-height: 1.5;
}

h1 {
    font-size: $h1SmallScreenFontSize;
    margin: 0 0 $defaultMargin * 0.5;
}

h2 {
    font-size: $h2SmallScreenFontSize;
    margin: 0 0 $defaultMargin * 0.5;
}

h3 {
    font-size: $h3SmallScreenFontSize;
    margin: 0 0 $defaultMargin * 0.25;
}

h4 {
    font-size: $h4SmallScreenFontSize;
    margin: 0 0 $defaultMargin * 0.25;
}

h5 {
    font-size: $h5SmallScreenFontSize;
    margin: 0 0 $defaultMargin * 0.25;
}

h6 {
    font-size: $h5SmallScreenFontSize;
    margin: 0 0 $defaultMargin * 0.25;
}

// Larger than phablet
@include tablet-desktop {
    h1 {
        font-size: $h1LargeScreenFontSize;
        margin: 0 0 $defaultMargin;
    }

    h2 {
        font-size: $h2LargeScreenFontSize;
    }

    h3 {
        font-size: $h3LargeScreenFontSize;
    }

    h4 {
        font-size: $h4LargeScreenFontSize;
    }

    h5 {
        font-size: $h4SmallScreenFontSize;
    }

    h6 {
        font-size: $h4SmallScreenFontSize;
    }
}

p {
    margin-top: 0;
}

small {
    font-size: $smallFontSize;
}

// 1. Correct the inheritance and scaling of font size in all browsers.
// 2. Correct the odd `em` font sizing in all browsers.
pre {
    font-family: monospace; // 1
    font-size: 1em; // 2
}

// 1. Remove the bottom border in Firefox 39-.
// 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
abbr {
    &[title] {
        border-bottom: 0; // 1
        text-decoration: underline dotted; // 2
    }
}

// Add the correct font weight in Chrome, Edge, and Safari.
b,
strong {
    font-weight: $boldFontWeight;
}

// Prevent `sub` and `sup` elements from affecting the line height in all browsers.
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

// Remove the text shadow on text selections (opinionated).
// 1. Restore the coloring undone by defining the text shadow (opinionated).
::selection {
    background-color: $selectedTextBackgroundColor; // 1
    color: $selectedTextColor; // 1
    text-shadow: none;
}
