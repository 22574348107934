@use "sass:math";

.user-profile-no-content {
    background-color: $defaultBackgroundColor;
    border: 1px solid $borderLightColor;
    border-radius: $largeBorderRadius;
    margin-bottom: $defaultMargin;
    margin-top: $smallScreenMargin;
    padding: $defaultMargin;
    position: relative;
    @include own-box-shadow(3px, 3px);

    .title {
        margin-right: $defaultMargin * 3;
    }

    .button-primary {
        white-space: normal;
    }

    .icon {
        border-radius: 50%;
        height: 4rem;
        padding: math.div($defaultMargin, 3);
        position: absolute;
        right: $defaultMargin;
        top: $defaultMargin;
        width: 4rem;
        @include own-box-shadow(3px, 10px);
    }
}

@include desktop {
    .user-profile-no-content {
        &.no-inquiries {
            margin-top: $largeScreenMargin;
        }
    }
}
