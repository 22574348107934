@mixin car-basic-colors {
    &.color-black {
        background-color: $carColorBlack;
    }

    &.color-blue {
        background-color: $carColorBlue;
    }

    &.color-brown {
        background-color: $carColorBrown;
    }

    &.color-gray {
        background-color: $carColorGray;
    }

    &.color-green {
        background-color: $carColorGreen;
    }

    &.color-orange {
        background-color: $carColorOrange;
    }

    &.color-pink {
        background-color: $carColorPink;
    }

    &.color-purple {
        background-color: $carColorPurple;
    }

    &.color-red {
        background-color: $carColorRed;
    }

    &.color-silver {
        background-color: $carColorSilver;
    }

    &.color-white {
        background-color: $carColorWhite;
    }

    &.color-yellow {
        background-color: $carColorYellow;
    }

    &.color-multicolor {
        background:
        linear-gradient($carColorCyan, transparent),
        linear-gradient(-45deg, $carColorPink, transparent),
        linear-gradient(45deg, $carColorYellow, transparent);
        background-blend-mode: multiply;
    }
}
